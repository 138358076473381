import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import StyledInput from "component/shared/StyledInput";
import StyledButton from "component/shared/StyledButton";
import { BASE_URL } from "utils/util";
import Loading from "component/Loading";
import Logo from "component/Logo";

type LoginType = "kakao" | "apple" | "id";

interface FindIdResponse {
  msg: string;
}

interface FindIdVerifyResponse {
  id: string;
  name: string;
  loginType: LoginType;
  hasDualLogin: boolean;
}

const FindId = () => {
  const [sms, setSms] = useState(false);
  const [phone, setPhone] = useState("");
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [number, setNumber] = useState<string>("");
  const [idVerifyResponse, setIdVerifyResponse] = useState<FindIdVerifyResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (time > 0) {
      const timeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      setTimer(timeout);
    }
  }, [time]);

  const sendNumber = () => {
    const params = {
      phone: phone,
    };
    setLoading(true);

    if (phone.length < 10) return;
    if (timer) {
      clearTimeout(timer);
    }
    axios
      .post<FindIdResponse>(`${BASE_URL}/user/findId`, params)
      .then(() => {
        setSms(true);
        setTime(180);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
        setLoading(false);
      });
  };

  const checkNumber = () => {
    const params = {
      number: number,
    };
    axios
      .post<FindIdVerifyResponse>(`${BASE_URL}/user/findIdVerify`, params)
      .then((res) => {
        setIdVerifyResponse(res.data);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  return (
    <div className="container footer-none min-h-screen lg:bg-secondary-100">
      <div className="wrapper">
        <div className="flex justify-center pt-10">
          <Logo />
        </div>
        {loading && <Loading />}
        <div className="bg-white mx-auto max-w-[450px] flex flex-col gap-5 mt-5 lg:border-primary-800 lg:border-[1px] lg:rounded lg:p-5 px-5">
          <div className="text-lg font-semibold text-center">아이디 찾기</div>
          <StyledInput
            type="tel"
            value={phone}
            placeholder="휴대폰 번호를 입력하세요"
            onChange={(e) => setPhone(e.target.value)}
            checkIcon={false}
          />
          <StyledButton onClick={sendNumber}>인증번호 전송</StyledButton>
          {sms && (
            <div className="input-box">
              <div className="tbox with-btn with-timer">
                <input
                  placeholder="인증번호를 입력하세요."
                  onChange={(e) => setNumber(e.target.value)}
                />
                <div className="timer">
                  {Math.floor(time / 60)}:{String(time % 60).padStart(2, "0")}
                </div>
                <button className="tbox-btn" onClick={checkNumber}>
                  확인
                </button>
              </div>
            </div>
          )}
          {idVerifyResponse && (
            <div className="mt-10">
              {idVerifyResponse.loginType === "id" || idVerifyResponse.hasDualLogin ? (
                <>
                  {idVerifyResponse.name}님의 아이디는 <b>[{idVerifyResponse.id}]</b>입니다.
                </>
              ) : idVerifyResponse.loginType === "kakao" ? (
                <>카카오로 가입한 계정입니다.</>
              ) : idVerifyResponse.loginType === "apple" ? (
                <>애플로 가입한 계정입니다.</>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="mt-20 flex flex-col gap-3">
            <StyledButton to="/login">로그인</StyledButton>
            <StyledButton to="/findpw">비밀번호 찾기</StyledButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindId;
