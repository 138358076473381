import RegisterBox from "component/chicruiting/RegisterBox";
import { chicruiting_page } from "../../constants";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerColorState } from "../../state";
import { api } from "../../utils/util";
import Popup from "component/Popup";
import { useNavigate } from "react-router-dom";
import ChannelTalk from "component/ChannelTalk";
import { getStaticImgUrl } from "utils/imageUtil";

function ChiCruitingRegister() {
  const setHeaderColor = useSetRecoilState(headerColorState);
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [popup, setPopup] = useState(false);
  const allPageNum = 10;
  const invalidMsg = isInvalid(pageNum, answers[pageNum - 1]);

  const submit = () =>
    api
      .post(
        "/cheecruits",
        answers.reduce((pre, cur, idx) => {
          pre[chicruiting_page[idx]] = cur || null;
          return { ...pre };
        }, {}),
      )
      .then(() => {
        alert("요청서가 등록되었습니다");
        navigate("/mypage/mychicruiting");
      })
      .catch((err) => alert(err.response.data.msg));

  useEffect(() => {
    setHeaderColor("white");
    return () => setHeaderColor(null);
  }, [setHeaderColor]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-10">
      <div className="curation-detail chicruiting-register">
        {popup && (
          <Popup setPopup={setPopup} setConfirm={submit} confirmBtn="확인">
            <div className="popup-text">
              짝짝짝! 모두 작성하셨네요! 👏 요청서 등록을 완료하시겠습니까?
            </div>
            <div className="popup-text">(작성 완료 후 수정 불가)</div>
          </Popup>
        )}
        {pageNum === 0 ? (
          <div
            className="cover-img"
            style={{
              backgroundImage: `url(${getStaticImgUrl("cheecruit/register_main2.png")}`,
            }}
          ></div>
        ) : (
          <div
            className="cover-img"
            style={{ backgroundImage: `url(${getStaticImgUrl("cheecruit/register_main.png")}` }}
          ></div>
        )}

        <div className="wrapper">
          <div className="inner">
            <div className="curation-box">
              <div className="tit-box">
                <span className="tit-txt">필요한 인재조건을 등록해주세요</span>
              </div>
              <div className="writer-box">
                <span className="writer-txt">
                  빠르게, 확실하게, 딱 맞는 치과위생사를 채용하는 방법
                </span>
              </div>
              <div className="bottom">
                <div className="register-box">
                  <div className="txt">완료 시, 맞춤인재 매칭 가능</div>
                  <div className="progress">
                    <div className="bar">
                      <div
                        className="highLightBar"
                        style={{
                          width: `${pageNum ? Math.floor((pageNum / allPageNum) * 58) + 41 : 0}%`,
                        }}
                      ></div>
                    </div>
                    <div className="percent">
                      {pageNum ? Math.floor((pageNum / allPageNum) * 58) + 41 : 0}%
                    </div>
                  </div>
                  {pageNum === 0 && (
                    <div className="box0">
                      <div className="title" style={{ marginBottom: "20px" }}>
                        서비스 이용에 앞서 비용 지급에 동의해주세요!
                      </div>
                      <button className="first-btn" onClick={() => setPageNum((pre) => pre + 1)}>
                        동의하고 시작하기
                      </button>
                    </div>
                  )}
                  <RegisterBox pageNum={pageNum} answers={answers} setAnswers={setAnswers} />
                  {pageNum > 0 && (
                    <>
                      {invalidMsg && <div className="error-msg">{invalidMsg}</div>}
                      <div
                        className={
                          invalidMsg || !answers[pageNum - 1]
                            ? "button__container invalid"
                            : "button__container"
                        }
                      >
                        <button onClick={() => setPageNum((pre) => pre - 1)}>이전</button>
                        {(pageNum === 7 || pageNum === 8 || pageNum === 9) && (
                          <button
                            onClick={() =>
                              setPageNum((pre) => {
                                setAnswers((preAnswer) => {
                                  preAnswer[pageNum - 1] = undefined;
                                  return [...preAnswer];
                                });
                                return pre + 1;
                              })
                            }
                          >
                            건너뛰기
                          </button>
                        )}
                        {pageNum === 10 ? (
                          <button
                            disabled={invalidMsg || !answers[pageNum - 1]}
                            onClick={() => setPopup(true)}
                          >
                            완료
                          </button>
                        ) : (
                          <button
                            disabled={invalidMsg || !answers[pageNum - 1]}
                            onClick={() => setPageNum((pre) => pre + 1)}
                          >
                            다음
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <img
                  className="register-img"
                  src={getStaticImgUrl(`cheecruit/register_${pageNum}.png`)}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChannelTalk />
    </div>
  );
}

export default ChiCruitingRegister;

const isInvalid = (page, answer) => {
  var isEmail = (text) =>
    text.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i);
  const isPhoneNumber = (text) => text.match(/^\d{2,4}-\d{3,4}-\d{4}$/);
  switch (page) {
    case 3: {
      if (!answer) return false; // 초기에는 에러 메세지 띄우지 않음
      if (!answer?.type) return "입력하지 않은 정보가 있습니다.";
      if (answer.type === "senior" && (!answer.forYears?.min || !answer.forYears?.max))
        return "최소, 최대 경력을 입력해주세요.";
      if (answer.type === "senior" && answer.forYears.min * 1 >= answer.forYears.max * 1)
        return "경력 범위를 다시 확인해주세요.";
      return false;
    }
    case 4: {
      if (!answer) return false;
      if (!answer?.salaryWithoutTax?.length && !answer?.salaryMemo?.length)
        return "입력하지 않은 정보가 있습니다.";
      return false;
    }
    case 7:
    case 8:
    case 9: {
      return false;
    }
    case 10: {
      if (!answer) return false;
      if (!answer?.name) return "담당자명을 입력해주세요.";
      if (!answer?.role) return "직책을 입력해주세요.";
      if (!answer?.phone || !isPhoneNumber(answer.phone))
        return "올바른 휴대폰번호를 입력해주세요. (-포함)";
      if (!answer?.email || !isEmail(answer.email)) return "올바른 이메일을 입력해주세요.";
      return false;
    }
    default:
      if (!answer) return false;
      if (!answer?.length) return "입력하지 않은 정보가 있습니다.";
      return false;
  }
};
