import { Link } from "react-router-dom";
import Footer from "../../component/footer/Footer";
import StyledSelect from "../../component/shared/StyledSelect";
import { useState } from "react";
import { getStaticImgUrl } from "utils/imageUtil";

function Privacy() {
  const [target, setTarget] = useState("2024-08");
  const option = [
    { label: "2024. 08. 20 (현재)", value: "2024-08" },
    { label: "2024. 03. 19", value: "2024" },
    { label: "2023. 03. 09", value: "2023" },
    { label: "2021. 03. 15", value: "2021" },
  ];

  return (
    <div className="container full">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <Link to="/">
              <img
                src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`}
                alt="로고"
              />
            </Link>
          </div>

          <div className="member__con">
            <div className="member__tit">
              <div className="tit-txt">개인정보처리방침</div>
            </div>
            <div className="agree-form">
              <div className="agree-list">
                <div className="agree-box">
                  <div className="agree-service-box">
                    {target === "2021" && <PrivacyContent2021 />}
                    {target === "2023" && <PrivacyContent2023 />}
                    {target === "2024" && <PrivacyContent2024 />}
                    {target === "2024-08" && <PrivacyContent202408 />}
                  </div>
                </div>
              </div>
            </div>
            <StyledSelect
              onChange={(e) => setTarget(e.target.value)}
              value={target}
              className="w-[230px] h-[40px]"
              option={option}
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Privacy;

const PrivacyContent202408 = () => {
  return (
    <iframe
      className="w-full h-full"
      src={getStaticImgUrl("html/privacy_policy_2024_08.html")}
    ></iframe>
  );
};

const PrivacyContent2024 = () => {
  return (
    <iframe
      className="w-full h-full"
      src={getStaticImgUrl("html/privacy_policy_2024.html")}
    ></iframe>
  );
};

const PrivacyContent2023 = () => {
  return (
    <>
      (주)데니어(이하 “회사”)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와
      관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
      두고 있습니다.
      <br />
      <br />
      <b>1 개인정보의 수집 및 이용 목적</b>
      <br />
      회사는 아래와 같은 목적으로 개인정보를 수집하여 활용하고 있습니다.
      <br />- 회원가입 의사의 확인, 연령 확인, 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등
      회원관리
      <br />- 서비스의 개발, 제공, 향상 및 원활한 운영 및 이용자가 안심하고 이용할 수 있는 서비스
      이용환경 구축
      <br />- 모어덴 이용약관 및 개인정보처리방침의 개정 등의 고지사항 전달
      <br />- 모어덴 신규 서비스/이벤트 안내 및 마케팅 광고
      <br />- 법령 및 모어덴 이용약관을 위반하는 회원에 대한 이용 제한 조치, 서비스의 원활한 운영에
      지장을 미치는 행위 및 서비스 부정이용 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지,
      불만처리를 위한 기록 보존
      <br />
      <br />
      <b>다만, 아래의 경우에는 개인정보 수집에 대한 회원의 사전 동의를 구하지 않을 수 있습니다.</b>
      <br />- 약관에 근거한 서비스의 제공을 위하여 필요한 경우
      <br />- 유료 서비스의 요금정산을 위하여 필요한 경우
      <br />- 다른 법령에 특별한 규정이 있는 경우
      <br />
      <br />
      <b>2 개인정보의 처리 및 보유기간</b>
      <br />
      1) 회원의 개인정보는 회사가 회원에게 서비스를 제공하는 기간(이용기간) 또는 분쟁처리
      기간(보유기간) 동안에 한하여 보유하고 이를 활용합니다.
      <br />
      Ⅰ. 이용기간: 서비스 가입일 ~ 해지일 까지
      <br />
      Ⅱ. 보유기간: 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해 이용계약
      해지일로부터 3년간 보유
      <br />
      2) 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가
      있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. 이 경우 회사는
      보관하는 정보를 그 보관의 목적으로만 이용하며 보존기관은 아래와 같습니다.
      <br />
      Ⅰ. 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
      <br />
      Ⅱ. 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
      <br />
      Ⅲ. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의 소비자보호에 관한 법률)
      <br />
      Ⅳ. 로그인 기록: 3개월(통신비밀보호법)
      <br />
      <br />
      <b>3 처리하는 개인정보 항목</b>
      <br />
      1) "회사"는 서비스 제공을 위하여 필요한 최소한의 개인정보를 당사의 서비스를 통한 회원가입과
      고객님이 동의하시는 경우에 한하여 수집, 이용합니다.
      <br />
      2) 수집방법은 서비스를 통한 회원가입, 이메일 고객문의를 통해 고객이 제시하는 정보를
      수집합니다. 서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가
      생성되어 수집될 수 있습니다.
      <br />
      3) 회원가입 시 수집 정보
      <br />
      Ⅰ. 치과의사 회원
      <br />- 이름, 생년월일, 성별, 휴대폰 번호, 아이디, 비밀번호, 이메일, 의사면허번호, 면허증
      촬영본, 이동통신사 정보(선택), 아이핀 정보(선택), 본인확인값
      <br />
      Ⅱ. 치대생 회원
      <br />- 이름, 생년월일, 성별, 휴대폰 번호, 아이디, 비밀번호, 이메일, 학번, 소속대학, 학생증
      촬영본, 이동통신사 정보(선택), 아이핀 정보(선택), 본인확인값
      <br />
      4) 유료 서비스 이용시
      <br />
      Ⅰ. 재화, 온라인 강의 구매 및 판매에 따른 결제 상품 및 서비스의 제공 및 배송
      <br />
      성명, 휴대폰 번호, 배송지 주소(선택)
      <br />
      Ⅱ. 현금영수증 발행
      <br />- 현금영수증 카드번호, 휴대폰 번호, 사업자 번호, 이메일주소(선택)
      <br />
      Ⅲ. 취소/환불 처리
      <br /> - 계좌정보(은행명, 계좌번호, 예금주 성명)
      <br />
      Ⅳ. 간편결제 서비스
      <br />
      -신용카드 정보(카드번호,유효기간, 카드 소유자 생년월일, 사업자 번호, 카드비밀번호 앞 2자리)
      <br />
      -전자지급 결제대행 서비스 제공사에 전달할 목적으로 개인정보를 수집하나, “회사”는 별도로
      저장하시는 않습니다.
      <br />
      5) 서비스 이용과정이나 사업처리 과정
      <br />
      IP Address, 방문일시, 쿠키, MAC주소, 서비스 이용기록, 불량이용 기록 등이 인터넷 서비스
      이용과정에서 통계처리를 위해 수집됩니다.
      <br />
      <br />
      <b>4 개인정보의 파기</b>
      <br />
      1) 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는
      지체없이 해당 개인정보를 파기합니다.
      <br />
      2) 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고
      다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
      데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 별도 DB에 옮겨진 개인정보는 법률에
      의해 이용되는 경우가 아니고서는 이외의 다른 목적으로 이용되지 않습니다.
      <br />
      3) 개인정보 파기의 절차 및 방법은 다음과 같습니다.
      <br />
      Ⅰ. 파기절차
      <br />
      회원이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
      <br />
      Ⅱ. 파기방법
      <br />
      전자적 파일형태로 저장된 개인정보는 파일 형태의 경우 기록을 재생할 수 없는 기술적 방법으로
      삭제하며. 출력물 등은 분쇄하거나 소각하는 방식으로 파기
      <br />
      <br />
      <b>5 정보주체와 법정대리인의 권리, 의무 행사방법</b>
      <br />
      1) 정보주체는 회사에 대해 언제든지 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수
      있습니다.
      <br />
      2) 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면,
      전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
      <br />
      3) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실
      수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
      합니다.
      <br />
      4) 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여
      정보주체의 권리가 제한 될 수 있습니다.
      <br />
      5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
      경우에는 그 삭제를 요구할 수 없습니다.
      <br />
      6) 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등
      요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
      <br />
      <br />
      <b>6 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항</b>
      <br />
      회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(Cookie)' 등을 사용합니다. 쿠키란 회사을
      운영하는데 이용되는 서버가 귀하의 웹 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 웹
      브라우저에서 사용하는 공간에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용하며,
      익명화된 이용자의 사이트 이용 통계 분석을 위한 구글 분석(Google Analytics)과 웹 사이트 광고
      출력을 위해 구글 애드센스(Google Adsense)에 의해 이용될 수 있습니다.
      <br />
      1) 쿠키 등 사용 목적
      <br />
      쿠키는 회원 및 비회원의 접속 빈도나 방문 시간 등을 분석하고 자동 로그인을 선택한 회원의 로그인
      정보를 사용자의 웹 브라우저에 보존하기 위하여 이용됩니다.
      <br />
      이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을
      설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
      저장을 거부할 수도 있습니다.
      <br />
      2) 쿠키 설정 거부 방법
      <br />
      쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든
      쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      단, 쿠키 이용을 거부하였을 경우 로그인을 비롯한 서비스 제공에 어려움이 있을 수 있습니다.
      <br />
      <br />
      <br />
      <b>7 개인정보 보호책임자</b>
      <br />
      1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
      불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다
      <br />
      <br />
      개인정보 보호책임자
      <br />
      성명 : 홍찬희
      <br />
      직책 : 최고운영책임자
      <br />
      연락처 : 010-7708-3650, chanhee@deneer.co.kr
      <br />
      <br />
      2) 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
      불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
      회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
      <br />
      <br />
      <b>8 개인정보의 기술적, 관리적 보호대책</b>
      <br />
      회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지
      않도록 안정성 확보를 위해 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다.
      <br />
      1) 개인정보의 암호화
      <br />
      이용자의 개인정보를 비밀번호에 의해 보호되며, 비밀번호는 파일 및 전송데이터를 복호화할 수
      없도록 단방향 암호화하여 보호합니다.
      <br />
      2) 해킹 등에 대비한 기술적 대책
      <br />
      회사는 웹 사이트 서비스가 호스트되고 있는 호스팅 전문 기업의 보안 서비스를 이용하고 있습니다.
      <br />
      3) 개인 아이디와 비밀번호의 관리
      <br />
      이용자가 사용하는 ID와 비밀번호는 원칙적으로 이용자만이 사용할 수 있도록 되어 있습니다. 회사는
      이용자의 개인적인 부주의로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의
      위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 비밀번호에 대한 보안의식을 가지고
      비밀번호를 자주 변경하며, 타인이 알기 쉬운 비밀번호를 사용하거나, 공용 PC 등에서의 (특히 자동
      로그인의 경우) 로그인 시 개인정보가 누출되지 않도록 각별한 주의를 기울여 주시기 바랍니다.
      <br />
      <br />
      <b>9 개인정보의 제3자 제공 및 처리위탁</b>
      <br />
      회사는 관련법 및 회원의 동의가 없는 한, 회원의 개인정보를 제3자에게 절대 제공하지 않습니다.
      단, 회사는 보안성 높은 서비스 제공을 위하여, 신뢰도가 검증된 아래 회사에 개인정보 관련 업무
      처리를 위탁할 수 있습니다. 이 경우 회사는 회원에게 위탁을 받는 자와 업무의 내용을 사전에
      알리고 동의를 받습니다. 위탁을 받는 자 또는 업무의 내용이 변경 될 경우에도 같습니다.
      <br />
      회사는 정보통신서비스의 제공에 관한 계약을 이행하고 회원의 편의 증진 등을 위하여 추가적인 처리
      위탁이 필요한 경우에는 고지 및 동의 절차를 거치지 않을 수 있습니다.
      <br />
      1. Amazon Web Service : 서비스 시스템 제공, 데이터 관리 및 보관
      <br />
      2. Google Cloud Platform : 회원 관리, 운영 시스템 지원
      <br />
      3. ㈜아임포트 : 휴대폰 본인 인증 서비스, 전자지급 결제대행 서비스
      <br />
      4. ㈜케이티엠하우스 : 모바일 쿠폰 발행 서비스
      <br />
      5. ㈜알리는사람들 : SMS 발송/카카오톡 알림톡 발송 서비스
      <br />
      6. 나이스페이먼츠(주) : 전자지급 결제대행 서비스, 현금영수증 발행
      <br />
      7. (주)토스페이먼츠 : 전자지급 결제대행 서비스
      <br />
      8. (주)다날 : 휴대폰 본인인증 서비스
      <br />
      <br />
      <b>10 권익침해 구제방법</b>
      <br />
      정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
      아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
      만족하지 못하거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
      <br />
      <br />
      <b>개인정보 침해신고센터 (한국인터넷진흥원 운영)</b>
      <br />- 소관업무 : 개인정보 침해사실 신고, 상담 신청
      <br />- 홈페이지 : privacy.kisa.or.kr
      <br />- 전화 : (국번없이) 118
      <br />- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
      <br />
      <br />
      <b>개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</b>
      <br />- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
      <br />- 홈페이지 : www.kopico.go.kr
      <br />- 전화 : (국번없이) 1833-6972
      <br />- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
      <br />
      <br />
      대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
      <br />
      경찰청 사이버안전국 : 182 (cyberbureau.police.go.kr)
      <br />
      <br />
      <b>[부칙]</b>
      <br />
      1. (시행일) 본 개인정보 처리방침은 2023년 3월 9일부터 시행합니다.
    </>
  );
};

const PrivacyContent2021 = () => {
  return (
    <>
      (주)데니어(이하 “회사”)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와
      관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
      두고 있습니다.
      <br />
      <br />
      <b>1 개인정보의 수집 및 이용 목적</b>
      <br />
      회사는 아래와 같은 목적으로 개인정보를 수집하여 활용하고 있습니다.
      <br />
      - 회원가입 의사의 확인, 연령 확인, 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리
      <br />
      - 서비스의 개발, 제공, 향상 및 원활한 운영 및 이용자가 안심하고 이용할 수 있는 서비스 이용환경
      구축
      <br />
      - 모어덴 이용약관 및 개인정보처리방침의 개정 등의 고지사항 전달
      <br />
      - 모어덴 신규 서비스/이벤트 안내 및 마케팅 광고
      <br />
      - 법령 및 모어덴 이용약관을 위반하는 회원에 대한 이용 제한 조치, 서비스의 원활한 운영에 지장을
      미치는 행위 및 서비스 부정이용 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 불만처리를
      위한 기록 보존
      <br />
      <br />
      <b>다만, 아래의 경우에는 개인정보 수집에 대한 회원의 사전 동의를 구하지 않을 수 있습니다.</b>
      <br />
      - 약관에 근거한 서비스의 제공을 위하여 필요한 경우
      <br />
      - 유료 서비스의 요금정산을 위하여 필요한 경우
      <br />
      - 다른 법령에 특별한 규정이 있는 경우
      <br />
      <br />
      <b>2 개인정보의 처리 및 보유기간</b>
      <br />
      1) 회원의 개인정보는 회사가 회원에게 서비스를 제공하는 기간(이용기간) 또는 분쟁처리
      기간(보유기간) 동안에 한하여 보유하고 이를 활용합니다.
      <br />
      Ⅰ. 이용기간: 서비스 가입일 ~ 해지일 까지
      <br />
      Ⅱ. 보유기간: 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해 이용계약
      해지일로부터 3년간 보유
      <br />
      2) 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가
      있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. 이 경우 회사는
      보관하는 정보를 그 보관의 목적으로만 이용하며 보존기관은 아래와 같습니다.
      <br />
      Ⅰ. 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
      <br />
      Ⅱ. 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
      <br />
      Ⅲ. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의 소비자보호에 관한 법률)
      <br />
      Ⅳ. 로그인 기록: 3개월(통신비밀보호법)
      <br />
      <br />
      <b>3 처리하는 개인정보 항목</b>
      <br />
      1) "회사"는 서비스 제공을 위하여 필요한 최소한의 개인정보를 당사의 서비스를 통한 회원가입과
      고객님이 동의하시는 경우에 한하여 수집, 이용합니다.
      <br />
      2) 수집방법은 서비스를 통한 회원가입, 이메일 고객문의를 통해 고객이 제시하는 정보를
      수집합니다. 서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가
      생성되어 수집될 수 있습니다.
      <br />
      3) 회원가입 시 수집 정보
      <br />
      Ⅰ. 치과의사 회원
      <br />
      - 이름, 생년월일, 성별, 휴대폰 번호, 아이디, 비밀번호, 이메일, 의사면허번호, 면허증 촬영본,
      이동통신사 정보(선택), 아이핀 정보(선택), 본인확인값
      <br />
      Ⅱ. 치대생 회원
      <br />
      - 이름, 생년월일, 성별, 휴대폰 번호, 아이디, 비밀번호, 이메일, 학번, 소속대학, 학생증 촬영본,
      이동통신사 정보(선택), 아이핀 정보(선택), 본인확인값
      <br />
      4) 유료 서비스 이용시
      <br />
      결제 등을 위해 또는 계산서 발행을 위해 예금주명, 사업자번호 등의 정보가 수집될 수 있습니다.
      <br />
      5) 서비스 이용과정이나 사업처리 과정
      <br />
      IP Address, 방문일시, 쿠키, MAC주소, 서비스 이용기록, 불량이용 기록 등이 인터넷 서비스
      이용과정에서 통계처리를 위해 수집됩니다.
      <br />
      <br />
      <b>4 개인정보의 파기</b>
      <br />
      1) 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는
      지체없이 해당 개인정보를 파기합니다.
      <br />
      2) 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고
      다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
      데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 별도 DB에 옮겨진 개인정보는 법률에
      의해 이용되는 경우가 아니고서는 이외의 다른 목적으로 이용되지 않습니다.
      <br />
      3) 개인정보 파기의 절차 및 방법은 다음과 같습니다.
      <br />
      Ⅰ. 파기절차
      <br />
      회원이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
      <br />
      Ⅱ. 파기방법
      <br />
      전자적 파일형태로 저장된 개인정보는 파일 형태의 경우 기록을 재생할 수 없는 기술적 방법으로
      삭제하며. 출력물 등은 분쇄하거나 소각하는 방식으로 파기
      <br />
      <br />
      <b>5 정보주체와 법정대리인의 권리, 의무 행사방법</b>
      <br />
      1) 정보주체는 회사에 대해 언제든지 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수
      있습니다.
      <br />
      2) 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면,
      전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
      <br />
      3) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실
      수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
      합니다.
      <br />
      4) 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여
      정보주체의 권리가 제한 될 수 있습니다.
      <br />
      5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
      경우에는 그 삭제를 요구할 수 없습니다.
      <br />
      6) 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등
      요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
      <br />
      <br />
      <b>6 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항</b>
      <br />
      회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(Cookie)' 등을 사용합니다. 쿠키란 회사을
      운영하는데 이용되는 서버가 귀하의 웹 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 웹
      브라우저에서 사용하는 공간에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용하며,
      익명화된 이용자의 사이트 이용 통계 분석을 위한 구글 분석(Google Analytics)과 웹 사이트 광고
      출력을 위해 구글 애드센스(Google Adsense)에 의해 이용될 수 있습니다.
      <br />
      1) 쿠키 등 사용 목적
      <br />
      쿠키는 회원 및 비회원의 접속 빈도나 방문 시간 등을 분석하고 자동 로그인을 선택한 회원의 로그인
      정보를 사용자의 웹 브라우저에 보존하기 위하여 이용됩니다.
      <br />
      이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을
      설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
      저장을 거부할 수도 있습니다.
      <br />
      2) 쿠키 설정 거부 방법
      <br />
      쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든
      쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      단, 쿠키 이용을 거부하였을 경우 로그인을 비롯한 서비스 제공에 어려움이 있을 수 있습니다.
      <br />
      <br />
      <br />
      <b>7 개인정보 보호책임자</b>
      <br />
      1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
      불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다
      <br />
      <br />
      개인정보 보호책임자
      <br />
      성명 : 홍찬희
      <br />
      직책 : 최고운영책임자
      <br />
      연락처 : 010-7708-3650, chanhee@deneer.co.kr
      <br />
      <br />
      2) 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
      불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
      회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
      <br />
      <br />
      <b>8 개인정보의 기술적, 관리적 보호대책</b>
      <br />
      회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지
      않도록 안정성 확보를 위해 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다.
      <br />
      1) 개인정보의 암호화
      <br />
      이용자의 개인정보를 비밀번호에 의해 보호되며, 비밀번호는 파일 및 전송데이터를 복호화할 수
      없도록 단방향 암호화하여 보호합니다.
      <br />
      2) 해킹 등에 대비한 기술적 대책
      <br />
      회사는 웹 사이트 서비스가 호스트되고 있는 호스팅 전문 기업의 보안 서비스를 이용하고 있습니다.
      <br />
      3) 개인 아이디와 비밀번호의 관리
      <br />
      이용자가 사용하는 ID와 비밀번호는 원칙적으로 이용자만이 사용할 수 있도록 되어 있습니다. 회사는
      이용자의 개인적인 부주의로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의
      위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 비밀번호에 대한 보안의식을 가지고
      비밀번호를 자주 변경하며, 타인이 알기 쉬운 비밀번호를 사용하거나, 공용 PC 등에서의 (특히 자동
      로그인의 경우) 로그인 시 개인정보가 누출되지 않도록 각별한 주의를 기울여 주시기 바랍니다.
      <br />
      <br />
      <b>9 개인정보의 제3자 제공 및 처리위탁</b>
      <br />
      회사는 관련법 및 회원의 동의가 없는 한, 회원의 개인정보를 제3자에게 절대 제공하지 않습니다.
      단, 회사는 보안성 높은 서비스 제공을 위하여, 신뢰도가 검증된 아래 회사에 개인정보 관련 업무
      처리를 위탁할 수 있습니다. 이 경우 회사는 회원에게 위탁을 받는 자와 업무의 내용을 사전에
      알리고 동의를 받습니다. 위탁을 받는 자 또는 업무의 내용이 변경 될 경우에도 같습니다.
      <br />
      회사는 정보통신서비스의 제공에 관한 계약을 이행하고 회원의 편의 증진 등을 위하여 추가적인 처리
      위탁이 필요한 경우에는 고지 및 동의 절차를 거치지 않을 수 있습니다.
      <br />
      1. Amazon Web Service : 서비스 시스템 제공, 데이터 관리 및 보관
      <br />
      2. Google Cloud Platform : 회원 관리, 운영 시스템 지원
      <br />
      3. ㈜아임포트 : 휴대폰 본인 인증 서비스
      <br />
      4. ㈜케이티엠하우스 : 모바일 쿠폰 발행 서비스
      <br />
      5. ㈜알리는사람들 : SMS 발송/카카오톡 알림톡 발송 서비스
      <br />
      <br />
      <b>10 권익침해 구제방법</b>
      <br />
      정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
      아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
      만족하지 못하거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
      <br />
      <br />
      <b>개인정보 침해신고센터 (한국인터넷진흥원 운영)</b>
      <br />
      - 소관업무 : 개인정보 침해사실 신고, 상담 신청
      <br />
      - 홈페이지 : privacy.kisa.or.kr
      <br />
      - 전화 : (국번없이) 118
      <br />
      - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
      <br />
      <br />
      <b>개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</b>
      <br />
      - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
      <br />
      - 홈페이지 : www.kopico.go.kr
      <br />
      - 전화 : (국번없이) 1833-6972
      <br />
      - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
      <br />
      <br />
      대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
      <br />
      경찰청 사이버안전국 : 182 (cyberbureau.police.go.kr)
      <br />
      <br />
      <b>[부칙]</b>
      <br />
      1. (시행일) 본 개인정보 처리방침은 2021년 3월 15일부터 시행합니다.
    </>
  );
};
