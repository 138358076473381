import { useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { getAuthToken } from "api/auth/api";
import useLogin from "hooks/useLogin";
import Loading from "component/Loading";
import { toast } from "react-toastify";

const Auth = () => {
  const location = useLocation();
  const param = new URLSearchParams(location && location.search);
  const code = param.get("code");
  const login = useLogin();

  const getToken = useCallback(async () => {
    if (!code) {
      toast.error("잘못된 경로로 접근했습니다.");
      return;
    }
    const tokenResponse = await getAuthToken({ code: code });
    tokenResponse ? login(tokenResponse) : toast.error("로그인에 실패했습니다.");
  }, [code, login]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return (
    <>
      <Loading />
    </>
  );
};

export default Auth;
