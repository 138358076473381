import { uidEncode } from "../../utils/util";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { getStaticImgUrl } from "utils/imageUtil";

// TODO : SwiperCore 재확인하기
// SwiperCore.use([Pagination, Navigation]);

const ForumRanking = ({ pageInfo }) => {
  const myInfo = pageInfo && pageInfo.user_rank;
  const navigate = useNavigate();

  return (
    <div className="layout__box__3">
      <article className="section__tit__box__3">
        <div className="tit-box">
          <span className="tit-txt">명의 전당</span>
          <div className="swiper__button__box">
            <div className="swiper-button-prev1">
              <i class="ii ii-caret-left text-white"></i>
            </div>
            <div className="swiper-button-next1">
              <i className="ii ii-caret-right text-white"></i>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
          spaceBetween={10}
          slidesPerView={1}
          slidesPerGroup={1}
          setWrapperSize={true}
          slideToClickedSlide={false}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1",
          }}
        >
          {pageInfo &&
            pageInfo.forum_ranking
              .reduce((prev, curr, idx, arr) => {
                const i3 = parseInt(idx / 3);
                if (!prev[i3]) prev[i3] = [];
                prev[i3].push({ ...curr, rank: idx + 1 });
                return prev;
              }, [])
              .map((rankerChunk, index) => (
                <SwiperSlide key={index}>
                  <div className="rank__box">
                    {rankerChunk.map((ranker) => (
                      <div
                        className="ranker cursor-pointer"
                        key={ranker.rank}
                        onClick={() =>
                          navigate(`/profile/${uidEncode(ranker.userUid, ranker.unick)}`)
                        }
                      >
                        <div className="nick">
                          <img
                            className="rank_img"
                            src={getStaticImgUrl(`rank/rank_${ranker.rank}.svg`)}
                            alt=""
                          ></img>
                          <div>{ranker.unick}</div>
                        </div>
                        <div className="point">{ranker.forum_point}</div>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
        {pageInfo && myInfo && (
          <div className="info__box">
            <div className="nick">
              <span>{myInfo.nickname}님</span>
              <span className="rank">{myInfo.forum_rank}위</span>
            </div>
            <div className="mb-1">
              <span className="selected">
                모어덴 선정 <b>{myInfo.forum_admin_selected}번</b>
              </span>
            </div>
            <Link className="width-max" to="/mypage/point/forum">
              <div className="point__box">
                <b>임상포럼 포인트</b>
                <div className="flex items-center">
                  <span className="point">{myInfo.forum_point}점</span>
                  <div className="bg-primary-100 rounded-full w-[15px] h-[15px] flex justify-center items-center ml-1">
                    <i className="ii ii-chevron-right text-[9px] relative left-[1px] text-primary-600"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )}
      </article>
    </div>
  );
};

export default ForumRanking;
