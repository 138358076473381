/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StyledCheckBox from "component/shared/StyledCheckBox";
import StyledInput from "component/shared/StyledInput";
import { toast } from "react-toastify";
import { SignupResponse } from "types/auth";
import useLogin from "hooks/useLogin";
import { isEmailValid } from "utils/validation";
import { checkDuplicateEmail, checkDuplicateNickname } from "api/auth/api";
import { toastOptions } from "component/shared/StyledToastContainer";
import { BASE_URL } from "utils/util";
import Loading from "component/Loading";
import StyledButton from "component/shared/StyledButton";
import ChannelTalk from "component/ChannelTalk";
import Logo from "component/Logo";

const NICKNAME_PLACEHOLDER = "닉네임을 입력해주세요";
const NICKNAME_LENGTH_ERROR_MSG = "닉네임은 2글자 이상 입력해주세요.";
const NICKNAME_DUPLICATE_ERROR_MSG = "중복된 닉네임입니다";
const EMAIL_PLACEHOLDER = "이메일을 입력해주세요";
const EMAIL_INVALID_ERROR_MSG = "이메일 형식이 잘못되었습니다";
const EMAIL_DUPLICATE_ERROR_MSG = "중복된 이메일입니다";
const CODE_PLACEHOLDER = "추천인 코드를 입력해주세요(선택)";
const MARKETING_INVITE_INVALID_MSG = "단톡방 초대 확인이 필요합니다";

const Recommender = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const rcode = localStorage.getItem("rcode");
  const nickInput = useRef<HTMLInputElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const login = useLogin();

  const [nickname, setNickname] = useState(state.nickname || "");
  const [isNicknameDuplicate, setIsNicknameDuplicate] = useState<boolean | null>(null);
  const [email, setEmail] = useState(state.email || "");
  const [isEmailDuplicate, setIsEmailDuplicate] = useState<boolean | null>(null);
  const [code, setCode] = useState("");
  const [inviteAgreement, setInviteAgreement] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const alert = (msg: string) => {
    console.log(msg);
    toast.error(msg, { ...toastOptions, position: "bottom-left" });
  };

  const checkFormValid = () => {
    if (nickname.length < 2) {
      alert(NICKNAME_LENGTH_ERROR_MSG);
      nickInput.current?.focus();
      return Promise.reject();
    }

    if (isNicknameDuplicate === null) {
      checkNickname();
      return Promise.reject();
    }

    if (isNicknameDuplicate) {
      alert(NICKNAME_DUPLICATE_ERROR_MSG);
      nickInput.current?.focus();
      return Promise.reject();
    }

    if (!isEmailValid(email)) {
      alert(EMAIL_INVALID_ERROR_MSG);
      emailInput.current?.focus();
      return Promise.reject();
    }

    if (isEmailDuplicate === null) {
      checkEmail();
      return Promise.reject();
    }

    if (isEmailDuplicate) {
      alert(EMAIL_DUPLICATE_ERROR_MSG);
      emailInput.current?.focus();
      return Promise.reject();
    }

    if (!inviteAgreement) {
      alert(MARKETING_INVITE_INVALID_MSG);
      return Promise.reject();
    }
  };

  const join = async () => {
    await checkFormValid();

    setLoading(true);

    const signup = {
      ...state,
      recommender: rcode || code,
      nickname: nickname,
      email: email,
    };

    const { kakaoToken, ...params } = signup;

    axios
      .post<SignupResponse>(`${BASE_URL}/user/signup`, params)
      .then((res) => {
        login(res.data.token);
      })
      .catch((err) => {
        setLoading(false);
        alert(err.response.data.msg);
      });
  };

  const checkNickname = async () => {
    const res = await checkDuplicateNickname({ nickname: nickname });
    setIsNicknameDuplicate(res.data.duplicate);
  };

  const checkEmail = async () => {
    const res = await checkDuplicateEmail({ email: email });
    setIsEmailDuplicate(res.data.duplicate);
  };

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
    setIsNicknameDuplicate(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsEmailDuplicate(false);
  };

  useEffect(() => {
    if (!state || !state.id) {
      navigate("/signup", { replace: true });
    } else if (!state.phone) {
      navigate("/phoneVerify", { replace: true });
    } else {
      if (isNicknameDuplicate === null && state.nickname) checkNickname();
      if (isEmailDuplicate === null && state.email) checkEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="container footer-none min-h-screen lg:bg-secondary-100">
      <div className="wrapper">
        <div className="flex justify-center pt-10">
          <Logo />
        </div>
        <div className="bg-white mx-auto max-w-[450px] flex flex-col gap-5 mt-5 lg:border-primary-800 lg:border-[1px] lg:rounded lg:p-5 px-5">
          <div className="text-center">
            <div className="text-lg font-semibold">마지막 단계!</div>
            <div className="text-sm text-secondary-600">
              가입 후 마이페이지 {">"} 나의 정보에서 <br className="lg:hidden" />
              닉네임변경이 가능합니다.
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <StyledInput
              label="닉네임"
              required
              name="nickname"
              maxLength={30}
              disabled={loading}
              placeholder={NICKNAME_PLACEHOLDER}
              ref={nickInput}
              value={nickname}
              onChange={handleNicknameChange}
              isInvalid={isNicknameDuplicate === true}
              invalidMsg={(() => {
                if (nickname.length < 2) return NICKNAME_LENGTH_ERROR_MSG;
                if (isNicknameDuplicate) return NICKNAME_DUPLICATE_ERROR_MSG;
              })()}
              onBlur={checkNickname}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  emailInput.current?.focus();
                }
              }}
            />
            <StyledInput
              label="이메일"
              required
              name="email"
              placeholder={EMAIL_PLACEHOLDER}
              disabled={loading}
              value={email}
              onChange={handleEmailChange}
              ref={emailInput}
              isInvalid={!isEmailValid(email) || isEmailDuplicate === true}
              invalidMsg={(() => {
                if (isEmailDuplicate) return EMAIL_DUPLICATE_ERROR_MSG;
                if (!isEmailValid(email)) return EMAIL_INVALID_ERROR_MSG;
              })()}
              onBlur={checkEmail}
            />
            {!rcode && (
              <div className="input-box">
                <StyledInput
                  label="추천인 코드"
                  name="code"
                  disabled={loading}
                  placeholder={CODE_PLACEHOLDER}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={10}
                />
              </div>
            )}
          </div>
          <section>
            <div className="text-lg flex items-center gap-2">
              <b>콘텐츠 알림 단톡방 초대</b>
            </div>
            <div className="border-[1px] border-primary-800 rounded bg-primary-50 p-2 text-sm my-2">
              향후 콘텐츠 알림 단톡방으로 초대해드리겠습니다.
              <br />
              단톡방 초대안내를 위해 <b>1:1 카카오톡</b>으로 연락드릴 예정입니다. 감사합니다.
            </div>
            <StyledCheckBox
              checked={inviteAgreement}
              onChange={() => {
                setInviteAgreement(!inviteAgreement);
              }}
            >
              <div className="text-sm ml-2">네, 확인하였습니다.</div>
            </StyledCheckBox>
          </section>
          <StyledButton onClick={() => join()}>완료</StyledButton>
        </div>
      </div>
      <ChannelTalk />
    </div>
  );
};

export default Recommender;
