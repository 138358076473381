import { Link } from "react-router-dom";
import { Grid, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImgUrl, getResizedImgUrl } from "utils/imageUtil";

const HotContents = ({ hotContents }) => {
  return (
    <div className="w-full p-4 pr-0 lg:p-0 lg:h-[370px]">
      <div className="flex justify-between items-center">
        <div className="text-xl lg:ml-6 mb-3 lg:mb-5 tracking-tighter font-semibold animate-fadedown whitespace-nowrap">
          인기 콘텐츠 🔥
        </div>
        <div className="hidden lg:flex lg:relative lg:-top-3 button__box mr-6">
          <div className="swiper-button-prev1">{"←"}</div>
          <div className="swiper-button-next1">{"→"}</div>
        </div>
      </div>
      {hotContents && (
        <Swiper
          className="w-full h-full"
          slidesPerView={1.5}
          spaceBetween={20}
          grid={{
            rows: 1,
          }}
          loop={false}
          breakpoints={{
            768: {
              slidesPerView: 2.3,
            },
            1024: {
              grid: { rows: 3 },
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          navigation={{
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1",
          }}
          modules={[Grid, Navigation]}
        >
          {hotContents.slice(0, 6).map((item, idx) => {
            return (
              <SwiperSlide
                style={{
                  height: window.innerWidth > 1024 ? "33.3%" : "100%",
                  paddingBottom: window.innerWidth > 1024 ? "12px" : "0",
                }}
                key={item.bid}
              >
                <Link
                  className="w-full h-full relative flex flex-col lg:flex-row lg:items-center group"
                  to={`/contents/article/${item.bid}`}
                  key={item.title}
                >
                  <div className="hidden lg:block text-2xl !text-primary-800 font-bold mr-3">
                    {idx + 1}
                  </div>
                  <div className="lg:h-full rounded-md aspect-contents overflow-hidden mr-3">
                    <img
                      className="w-full h-full group-hover:scale-105 transition-all duration-300"
                      src={getResizedImgUrl(getImgUrl(item.thumbnail), 500)}
                      alt=""
                    />
                  </div>
                  <div
                    style={{ width: window.innerWidth > 1024 ? "250px" : "100%" }}
                    className="mt-3 lg:m-0 flex flex-col justify-between w-full lg:w-[250px]"
                  >
                    <div className="text-black font-semibold truncate text-md group-hover:text-primary-800 ">
                      {item.title}
                    </div>
                    <div className="hidden lg:flex mt-3">
                      {item.tag
                        .split(",")
                        .slice(0, 3)
                        .map((keyword) => {
                          return (
                            <div
                              className="truncate lg:text-tiny bg-gray-100 !text-gray-600 rounded-sm p-1 mr-3 lg:mt-auto"
                              key={keyword}
                            >
                              {keyword}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};

export default HotContents;
