import React, { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { htmlTextContent } from "../../../utils/util";
import { getImgUrl } from "utils/imageUtil";

function CardNews({ content }) {
  const images = content.images;
  const [cardToggle, setCardToggle] = useState(false);

  useEffect(() => {
    setCardToggle(false);
  }, [content]);

  return (
    <div className="mb50">
      {cardToggle ? (
        <article className="card__news">
          <div className="card__news__wrap">
            <Swiper
              modules={[Autoplay, Keyboard, Navigation, Pagination]}
              className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
              parallax={true}
              slideToClickedSlide={true}
              slidesPerView="auto"
              autoplay={true}
              wrapperTag="ul"
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              keyboard={{ enabled: true, onlyInViewport: false }}
            >
              {images.map((image) => {
                return (
                  <SwiperSlide key={image.link} tag="li">
                    <div className="img-box">
                      <img src={getImgUrl(image.link)} alt="이미지" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </article>
      ) : (
        <div className="card-link-box">
          <div
            className="img-box"
            onClick={() => {
              setCardToggle(true);
            }}
          >
            {content.images.length > 0 ? (
              <img src={getImgUrl(content.images[0].link)} alt="이미지" />
            ) : (
              <div></div>
            )}
          </div>
          <div className="txt-info-box">
            <i className="ic ic-click"></i>
            <span className="txt">카드 이미지를 클릭해보세요!</span>
          </div>
        </div>
      )}
      <div className="txt-box" style={{ textAlign: "center" }}>
        {htmlTextContent(content.board.content)}
      </div>
    </div>
  );
}

export default CardNews;
