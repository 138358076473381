import { useNavigate, useParams } from "react-router-dom";
import { cls, discountedRate } from "../../utils/util";
import { CLASS_ROUTE } from "App";
import { CollectionCourses } from "types/klass";
import { getResizedImgUrl } from "utils/imageUtil";

const Package = ({
  studentCourses,
  item,
  isPackage,
}: {
  studentCourses?: string[];
  item: CollectionCourses;
  isPackage?: boolean;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div
      className={cls(
        id === item.courseId
          ? "bg-primary-100 border-primary-800 text-primary-800"
          : "border-transparent bg-none",
        "rounded-md lg:hover:bg-gray-50 group lg:flex lg:relative lg:items-center lg:justify-between shadow-custom m-[1px] px-3 py-3 text-gray-900 text-[15px] cursor-pointer font-semibold border-[1px] border-solid bg-white",
      )}
      onClick={() => navigate(`/${CLASS_ROUTE}/course/${item.courseId}`)}
    >
      <div className="flex items-center lg:space-x-3 lg:w-4/5">
        <div className="w-[80px] h-[45px] hidden lg:block">
          <img
            src={getResizedImgUrl(
              item.thumbnailImage.baseUrl + "/" + item.thumbnailImage.path,
              "160",
            )}
            alt=""
            className="rounded-sm w-full h-full object-cover"
          />
        </div>

        {!isPackage && (
          <div className="lg:hidden">
            <div className="bg-teal-50 text-teal-600 text-sm font-semibold rounded-md w-[25px] h-[22px] flex items-center justify-center mr-[10px]">
              패
            </div>
          </div>
        )}

        {studentCourses?.join(",").includes(item.courseId) && (
          <div className="lg:absolute lg:right-3">
            <div className="bg-blue-50 rounded-sm flex items-center justify-center space-x-1 lg:w-[128px] lg:h-[38px] w-[75px] h-[22px] mr-2 lg:mr-0">
              <div className="text-blue-500 font-semibold text-sm lg:text-base">수강가능</div>
              <i className="ii-play text-blue-500 text-sm"></i>
            </div>
          </div>
        )}

        <div
          className={cls(
            id === item.courseId ? "text-primary-800" : "text-gray-900",
            "break-all overflow-hidden text-ellipsis line-clamp-2 lg:line-clamp-1 lg:w-1/2",
          )}
        >
          {item.title}
        </div>
      </div>

      {!studentCourses?.join(",").includes(item.courseId) && item.product && (
        <div className="lg:flex items-center space-x-2 hidden">
          <div className="text-alert font-semibold text-xl">
            {discountedRate(item.product[0].regularPrice, item.product[0].salePrice)}%
          </div>
          <div className="text-gray-900 font-semibold text-lg ml-[9px] mr-[25px]">
            {item.product[0].salePrice.toLocaleString()}원
          </div>
        </div>
      )}
    </div>
  );
};

export default Package;
