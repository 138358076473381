import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { api, listDttm } from "../../../utils/util";
import Loading from "../../Loading";
import { getImgUrl } from "utils/imageUtil";

function MagazineList({ current, current_cate, searchAble = true }) {
  const navigate = useNavigate();
  const location = useLocation();
  const board = parseInt(useParams().board || current_cate);
  const article = parseInt(useParams().bid || 0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q");
  const cate = current_cate;
  const params = { page, q };

  const large_cate = cate === 1 ? "magazines" : "board";

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["boardList", params, current_cate],
    () => api.get(`/board/list/${current_cate}`, { params }).then((res) => res.data),
  );

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    param.append("page", 1);
    cate && param.append("cate", cate);
    searchKeyword && param.append("q", searchKeyword);
    navigate(current === "magazine_page" ? `/post?${param}` : `/post/article/${article}?${param}`);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    cate && param.append("cate", cate);
    q && param.append("q", q);
    navigate(`?${param}`);
    window.scrollTo(0, 0);
  };

  const openArticle = (bid, e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    pageInfo && pageInfo.page && param.append("page", pageInfo.page);
    board && param.append("cate", board);
    searchKeyword && param.append("q", searchKeyword);
    navigate(`/post/article/${bid}?${param}`);
    window.scrollTo(0, 0);
  };

  if (pageInfoLoading) return <Loading />;

  return (
    <>
      <article className="list__table2">
        <div className="list">
          <div className="list-head">
            <div className="image">이미지</div>
            <div className="topic left">토픽</div>
            <div className="title left">제목</div>
            <div className="writer left">글쓴이</div>
            <div className="detail">작성일</div>
            <div className="detail">조회</div>
            <div className="detail">추천</div>
          </div>
          {pageInfo &&
            pageInfo[large_cate] &&
            pageInfo[large_cate].map((item) => {
              return (
                <div key={item.bid} onClick={(e) => openArticle(item.bid, e)} className="content">
                  <div className="image">
                    <div className="img-box">
                      <img src={getImgUrl(item.thumbnail)} alt="이미지" />
                    </div>
                  </div>
                  <div className="topic left">
                    <span className="accent">
                      {current_cate === 33 ? "기후행동 캠페인" : item.label}
                    </span>
                  </div>
                  <div className="title left">
                    <span>
                      {item.title} [{item.comment}]
                    </span>
                    {item.has_img && <i className="ic ic-albums"></i>}
                  </div>
                  <div className="writer left">{item.unick}</div>
                  <div className="detail">{listDttm(item.reg_dttm)}</div>
                  <div className="detail">{item.view}</div>
                  <div className="detail">
                    <span className="accent">{item.like}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </article>
      <article className="paging">
        {pageInfo.page && (
          <ReactPaginate
            previousLabel="←"
            previousLinkClassName="before"
            nextLabel="→"
            nextLinkClassName="next"
            breakLabel="..."
            pageCount={pageInfo.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            pageLinkClassName="num"
            activeClassName=""
            activeLinkClassName="current"
            onPageChange={onPageClick}
            style={{ cursor: "pointer" }}
            forcePage={pageInfo.page - 1}
          />
        )}
      </article>

      {searchAble && (
        <article className="list__search__form" style={{ marginTop: "47px" }}>
          <form className="search__form__box">
            <input
              type="search"
              placeholder="검색어를 입력해주세요."
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <button
              className="search-btn"
              onClick={(e) => search(e)}
              style={{ border: "none", outline: "none", cursor: "pointer" }}
            ></button>
          </form>
        </article>
      )}
    </>
  );
}

export default MagazineList;
