import AttachImage from "component/shared/AttachImage";
import { React, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../state";
import { getImgUrl } from "utils/imageUtil";

function CommentTextArea({ newComment, setNewComment, changeImage, submit, replyUnick }) {
  const [commentAreaOnFocus, setCommentAreaOnFocus] = useState(false);
  const token = useRecoilValue(tokenState);
  return (
    <>
      <div className="reg-input-box">
        {replyUnick && <span className="reply__user">@{replyUnick}</span>}
        <TextareaAutosize
          onChange={(e) => setNewComment({ ...newComment, content: e.target.value })}
          value={newComment.content || ""}
          placeholder={
            newComment.cid
              ? ""
              : !commentAreaOnFocus
              ? "✏️" + token?.user_nickname + "님, 댓글을 남겨보세요"
              : ""
          }
          minRows={3}
          onFocus={() => {
            setCommentAreaOnFocus(true);
          }}
        ></TextareaAutosize>
        <div className="reg-menu">
          <div className="left-menu-box">
            <AttachImage changeImage={changeImage} />
          </div>
          <div className="right-menu-box">
            <div className="reg-btn-box">
              <button onClick={() => submit(newComment, setNewComment)} className="reg-btn">
                {newComment.uuid ? "수정" : "등록"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {newComment.image && (
        <div className="preview-list">
          <div className="preview-box">
            <img src={getImgUrl(newComment.image)} alt="이미지" />
            <button
              className="remove-btn"
              onClick={() => {
                setNewComment({ ...newComment, image: null });
              }}
            ></button>
          </div>
        </div>
      )}
    </>
  );
}

export default CommentTextArea;
