import { Dispatch, SetStateAction, useState } from "react";
import { focusManager } from "react-query";
import { useQuery } from "react-query";
import { publicApi } from "../../utils/util";
import { VodPlay } from "./Curriculum";
import Loading from "component/Loading";
import { getStaticImgUrl } from "utils/imageUtil";

const LectureVideo = ({
  courseId,
  chapterId,
  lectureId,
  setPopup,
  bookmark,
  progressPercent,
}: {
  courseId: string;
  chapterId: string;
  lectureId: string;
  setPopup: Dispatch<SetStateAction<VodPlay>>;
  bookmark: number;
  progressPercent?: number;
}) => {
  const [kollusLink, setKollusLink] = useState<string>("");
  focusManager.setFocused(false);
  const { data, isLoading } = useQuery(
    ["classCoursesChaptersLectures"],
    () =>
      publicApi
        ?.get(
          !bookmark && progressPercent && progressPercent > 0
            ? `/class/courses/${courseId}/chapters/${chapterId}/lectures/${lectureId}/video?useIntroOutro=true`
            : `/class/courses/${courseId}/chapters/${chapterId}/lectures/${lectureId}/video`,
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        let link = data.url.replace("http", "https");
        link = [link, "pc_player_version=v3e", "autoplay", bookmark ? `s=${bookmark}` : ""].join(
          "&",
        );

        setKollusLink(link);
      },
      enabled: !!(chapterId && lectureId),
    },
  );

  if (!data && isLoading) return <Loading small />;

  return (
    <>
      <div className="relative">
        <div
          className="fixed left-0 right-0 top-0 bottom-0 z-[100] bg-[rgba(0,0,0,0.4)]"
          onClick={() => setPopup({ popup: false, bookmark: 0, lectureId: "" })}
        ></div>
        <div className="fixed z-[110] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-[#fff] lg:w-[585px] lg:h-[424px] w-5/6 h-auto rounded-lg">
          <img src={getStaticImgUrl("klass/player-frame-mo.png")} alt="" className="lg:hidden" />
          <img
            src={getStaticImgUrl("klass/player-frame-pc.png")}
            alt=""
            className="hidden lg:block"
          />
          <div className="absolute top-[40%] lg:top-[25%] left-[10%] lg:left-[18%] w-[80%] h-[40%] lg:w-[64%] lg:h-[60%] overflow-hidden flex items-center justify-center">
            <iframe
              src={kollusLink}
              id="lecture_video"
              title="lecture"
              frameBorder="0"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
          </div>

          <div
            className="absolute top-3 right-3"
            onClick={() => setPopup({ popup: false, bookmark: 0, lectureId: "" })}
          >
            <i className="ii-remove ii-weight-600 text-2xl text-secondary-800 cursor-pointer"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default LectureVideo;
