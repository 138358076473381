import { jwtDecode } from "jwt-decode";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { tokenState } from "state";
import { IdToken, TokenResponse } from "types/auth";
import { getTokenWithPayload } from "utils/authUtil";
import { api, tokenUpdateInApiOption } from "utils/util";

interface Options {
  replace: boolean;
  state?: { syncType: string };
}

export default function useLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  const setToken = useSetRecoilState(tokenState);
  const queryClient = useQueryClient();
  const param = new URLSearchParams(location && location.search);
  const pathname = param.get("pathname");
  const syncType = param.get("syncType");

  return useCallback(
    async (tokenData: TokenResponse) => {
      if (!tokenData) {
        navigate("/", { replace: true });
        return;
      }

      const decodedData = getTokenWithPayload(tokenData);

      const { id_token, syncableUser } = tokenData;
      if (!decodedData.uid && syncableUser) {
        // 연동가능한 정보가 있을 경우 연동 확인
        navigate("/syncAccount", {
          state: {
            loginType: decodedData.idp,
            accessToken: decodedData.access_token,
            idToken: id_token,
            syncableUser,
          },
          replace: true,
        });
        return;
      }

      // 로그인 처리
      localStorage.clear();
      localStorage.setItem("token", JSON.stringify(decodedData));
      queryClient.clear();
      tokenUpdateInApiOption(tokenData.access_token);
      setToken(decodedData);

      await api?.get("/user/searchKey").then((res) => {
        if (res.data.searchKey && res.data.indexes) {
          localStorage.setItem("searchKey", res.data.searchKey);
          localStorage.setItem("searchIndexes", JSON.stringify(res.data.indexes));
        }
      });

      if (decodedData && !decodedData?.uid) {
        const idToken: string = decodedData.id_token;
        const decodedIdToken = jwtDecode(idToken) as IdToken;

        if (decodedIdToken.identities?.kakao) {
          const kakaoData = decodedIdToken.identities.kakao;
          const { ci, birthyear, birthday, gender, phone_number, name, id, email, profile } =
            kakaoData;
          if (
            ci &&
            birthyear &&
            birthday &&
            gender &&
            phone_number &&
            name &&
            id &&
            email &&
            profile
          ) {
            navigate("/signup", {
              state: {
                ...location.state,
                idToken,
                loginType: "kakao",
                birthday: `${birthyear}-${birthday.slice(0, 2)}-${birthday.slice(2, 4)}`,
                phone: `0${phone_number.slice(4).replaceAll("-", "")}`,
                genderCd: gender === "male" ? "M" : "F",
                name: name,
                ci: ci,
                email: email,
                nickname: profile?.nickname,
              },
            });
            return;
          } else {
            navigate("/phoneVerify", {
              state: {
                ...location.state,
                idToken,
                loginType: "kakao",
                email: email,
              },
              replace: true,
            });
            return;
          }
        }

        if (decodedIdToken.identities?.apple) {
          navigate("/phoneVerify", {
            state: {
              ...location.state,
              idToken,
              loginType: "apple",
            },
            replace: true,
          });
        }

        navigate("/phoneVerify", {
          state: true,
          replace: true,
        });
        return;
      }

      if (!decodedData.user_type) {
        navigate("/verifyPending", { state: true, replace: true });
        return;
      }

      if (pathname) {
        navigate(pathname, { replace: true });
      } else {
        const options: Options = { replace: true };
        if (syncType) {
          options.state = { syncType };
        }
        navigate("/", options);
      }
    },

    [navigate, pathname, queryClient, setToken, syncType],
  );
}
