import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { api, selectStyles } from "../../utils/util";
import { default as Select } from "react-select";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import { getStaticImgUrl } from "utils/imageUtil";

const TongtestExam = () => {
  const [select, setSelect] = useState(null);
  const [problemSet, setProblemSet] = useState(null);

  const token = useRecoilValue(tokenState);
  const isLogin = token && token.user_type;

  useEffect(() => {
    api.get("/tongtest/set_list").then((res) => {
      setProblemSet(res.data.list);
    });
  }, []);
  return (
    <>
      <section className="form__layout1 section-first-t">
        <div className="inner">
          <div className="row">
            <div className="subject-t">시험 과목을 선택하여 주십시오.</div>
            {problemSet && (
              <div className="con">
                <Select
                  value={select}
                  onChange={(option) => {
                    setSelect(option);
                  }}
                  styles={selectStyles}
                  options={problemSet.map((item) => {
                    return { label: item.name, value: item.sid };
                  })}
                  isSearchable={false}
                  placeholder="모의고사를 선택해주세요"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="event">
        <div className="inner">
          <article className="tong__box">
            {select && (
              <section className="tong_test_go">
                <article className="tongtest__box">
                  <Link
                    to={isLogin ? `/tongtest/note/${select.value}` : "/tongtest"}
                    className="box-wrap exam__box-wrap"
                    onClick={() => !isLogin && alert("로그인 후 이용하실 수 있는 서비스입니다.")}
                  >
                    <div className="info-box">
                      <img src={getStaticImgUrl("tongtest/tong_ox.svg")} alt="이미지" />
                      <div className="mid-box">
                        <span className="txt accent">오답 노트</span>
                      </div>
                    </div>
                  </Link>
                </article>
                <article className="tongtest__box">
                  <Link
                    to={`/tongtest/question/${select.value}`}
                    className="box-wrap exam__box-wrap bg-color__main"
                  >
                    <div className="info-box">
                      <img src={getStaticImgUrl("tongtest/tong_clock.svg")} alt="이미지" />
                      <div className="mid-box">
                        <span className="txt text-color__white">시험 시작</span>
                      </div>
                    </div>
                  </Link>
                </article>
              </section>
            )}
          </article>
        </div>
      </section>
    </>
  );
};

export default TongtestExam;
