import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BoardReportPopup from "../../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../../component/board/BoardTrashPopup";
import Loading from "../../../component/Loading";
import ManageContent from "../../../component/management/ManageContent";
import { api, dttm, htmlTextContent, priceText, seminarDttm } from "../../../utils/util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Nickname from "component/user/nickname";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { getImgUrl } from "utils/imageUtil";

function SeminarDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const bid = useParams().bid;
  const token = useRecoilValue(tokenState);
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";
  const [reportPopup, setReportPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const params = { page, q };

  useEffect(() => window.scrollTo(0, 0), [bid]);

  const scrapMutation = useMutation(
    ({ bid, data }) => api.post(`/management/seminar/scrap/${bid}`, data),
    { onSuccess: () => queryClient.invalidateQueries(["managementSeminarArticle", bid]) },
  );

  const scrapClicked = async () => {
    const data = {
      scraped: !content.scraped,
    };
    scrapMutation.mutate({ bid, data });
  };

  const modifyFunc = () => {
    navigate(`/management/seminar/modify/${bid}`, {
      state: { ...content },
    });
  };

  const directDeleteArticle = () => {
    api.delete(`/management/seminar/delete/${bid}`).then(() => {
      navigate(`/management/seminar`, { replace: true });
    });
  };

  const { data: content, isLoading: contentLoading } = useQuery(
    ["managementSeminarArticle", bid],
    () => api.get(`/management/seminar/article/${bid}`).then((res) => res.data),
    {
      onError: (err) => {
        alert(err.response.data.msg);
        navigate(-1);
      },
    },
  );

  const { data: allPageInfo, isLoading: allPageInfoLoading } = useQuery(
    ["managementSeminarList", params],
    () =>
      api
        .get(`/management/seminar/list`, {
          params,
        })
        .then((res) => res.data),
  );

  if (contentLoading || !content) return <Loading />;

  return (
    <div>
      <div className="wrapper">
        <section className="magazine__detail" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">세미나</span>
                {content.article && content.ismine ? (
                  <>
                    <div className="menu-box">
                      <div className="report-box">
                        <button onClick={modifyFunc} className="report-btn">
                          수정
                        </button>
                      </div>
                      <div className="report-box">
                        <button onClick={() => setDeletePopup(true)} className="report-btn">
                          삭제
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="menu-box"></div>
                )}
              </div>
            </article>
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}

            <div className="detail__group">
              <div className="tit-box">
                <div className="subject-box-topic">{content.article.topic}</div>
                <div className="subject-box">{content.article.title}</div>
                <div className="info-box">
                  <span className="txt">
                    <div className="flex">
                      <Nickname
                        isDetail
                        uid={content.article.userUid}
                        unick={content.article.unick}
                        level={content.article.level}
                        isOwner={content.article.is_owner}
                      />
                    </div>
                  </span>
                  <span className="txt">{dttm(content.article.reg_dttm)}</span>
                  {token?.user_id === "admin" && (
                    <span className="txt">조회수 {content.article.view}</span>
                  )}
                </div>
              </div>

              <div className="etc-box">
                <div className="row">
                  <div className="subject">기간:</div>
                  <div className="con">
                    {seminarDttm(content.article.start_date)} ~{" "}
                    {seminarDttm(content.article.end_date)}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">시간:</div>
                  <div className="con word-break-all">{content.article.time_desc}</div>
                </div>
                <div className="row">
                  <div className="subject">장소:</div>
                  <div className="con">
                    {content.article.location} |{" "}
                    {content.article.location1 && content.article.location1}{" "}
                    {content.article.location2 && content.article.location2}{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">등록비:</div>
                  <div className="con">{priceText(content.article.reg_fee)}</div>
                </div>
                <div className="row">
                  <div className="subject">주최:</div>
                  <div className="con">{content.article.host}</div>
                </div>
                <div className="row">
                  <div className="subject">보수교육점수:</div>
                  <div className="con">
                    {content.article.point === 0 ? "없음" : content.article.point + "점"}
                  </div>
                </div>
                {content.article.website && (
                  <div className="row">
                    <div className="subject">홈페이지:</div>
                    <a
                      href={
                        content.article.website.trim().startsWith("http")
                          ? content.article.website
                          : `http://${content.article.website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="con"
                    >
                      {content.article.website}
                    </a>
                  </div>
                )}
                {content.article.contact && (
                  <div className="row">
                    <div className="subject">문의:</div>
                    <div className="con">{content.article.contact}</div>
                  </div>
                )}
              </div>

              <div className="con-box">
                <div className="txt-box" style={{}}>
                  {htmlTextContent(content.article.content)}
                </div>
                <div className="img-box">
                  {content.images &&
                    content.images.map((item, idx) => {
                      return <img key={idx} src={getImgUrl(item.link)} alt="이미지" />;
                    })}
                </div>
              </div>
              <div className="menu-box-list">
                {content.article && !content.ismine && (
                  <>
                    <button
                      className={content.scraped ? "menu-box on" : "menu-box"}
                      onClick={scrapClicked}
                    >
                      <div className={content.scraped ? "scrap-btn-n on" : "scrap-btn-n"}></div>
                      <span className="menu-txt">스크랩</span>
                    </button>
                    <button className="menu-box" onClick={() => setReportPopup(true)}>
                      <div className="police-btn-n on"></div>
                      <span className="menu-txt">신고</span>
                    </button>
                  </>
                )}
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: content.article.title,
                        text: `[모어덴 - 세미나 게시판]\n${content.article.title}`,
                        url: location.pathname,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="magazine__list" style={{ marginBottom: "136.5px", marginTop: "50px" }}>
          <div className="inner">
            {!allPageInfoLoading && allPageInfo && (
              <ManageContent pageInfo={allPageInfo} current="seminar" pages={allPageInfo.pages} />
            )}
          </div>
        </section>
      </div>
      {reportPopup && (
        <BoardReportPopup category="management_seminar" setReportPopup={setReportPopup} bid={bid} />
      )}
    </div>
  );
}

export default SeminarDetailPage;
