import React from "react";
import { useLocation } from "react-router-dom";
import { htmlTextContent } from "../../utils/util";
import { getImgUrl } from "utils/imageUtil";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CardNews from "./magazine/CardNews";
import ZoomImg from "../ZoomImg";

function BoardDetailContent({ content, likeClicked, scrapClicked, setReportPopup }) {
  const location = useLocation();
  const getLikeInfo = (cid) => {
    const info = content.likes && content.likes.find((like_info) => like_info.cid === cid);
    return info && info.liked;
  };

  return (
    <>
      {content.board.cate_cd === 1 ? (
        <CardNews content={content} />
      ) : (
        <>
          {!content.board.reported ? (
            <div className="txt-box">
              {htmlTextContent(content.board.content)}
              <br />
              <br />
              <br />
              <ZoomImg>
                {content.images.map((item, idx) => {
                  return (
                    <div key={`${item.link}+${idx}`} className="img-box">
                      <img src={getImgUrl(item.link)} alt="이미지" />
                      <br />
                    </div>
                  );
                })}
              </ZoomImg>
            </div>
          ) : (
            <div className="txt-box">
              {content.board.report_count >= 10 && (
                <div className="reported">
                  신고가 10회 이상 누적되어 블라인드 처리 고려 중인 글입니다.
                </div>
              )}
              다수의 신고로 블라인드 처리된 게시글입니다.
            </div>
          )}
          <div className="menu-box-list like">
            <button
              className={getLikeInfo(null) ? "like-box on" : "like-box"}
              onClick={() => likeClicked(null, getLikeInfo(null))}
            >
              <div className={getLikeInfo(null) ? "like-btn-n on" : "like-btn-n"}></div>
              <span className="menu-txt">추천</span>
              <span className="count_n">{content.board.like}</span>
            </button>
          </div>
          <div className="menu-box-list">
            {!content.ismine && (
              <>
                <button
                  className={content.scraped ? "menu-box on" : "menu-box"}
                  onClick={scrapClicked}
                >
                  <div className={content.scraped ? "scrap-btn-n on" : "scrap-btn-n"}></div>

                  <span className="menu-txt">스크랩</span>
                </button>
                <button
                  className="menu-box"
                  onClick={() => setReportPopup({ category: "board", toggle: true })}
                >
                  <div className="police-btn-n on"></div>
                  <span className="menu-txt">신고</span>
                </button>
              </>
            )}

            {navigator.share ? (
              <button
                className="menu-box"
                onClick={() =>
                  navigator.share({
                    title: content.board.title,
                    text: `[모어덴 - ${content.cate_name} 게시판]\n${content.board.title}`,
                    url: location.pathname,
                  })
                }
              >
                <i className="ii ii-export"></i>
                <span className="menu-txt">공유</span>
              </button>
            ) : (
              <CopyToClipboard
                text={`https://www.moreden.co.kr${location.pathname}`}
                onCopy={() => alert("링크를 복사하였습니다")}
              >
                <button className="menu-box">
                  <i className="ii ii-export"></i>
                  <span className="menu-txt">공유</span>
                </button>
              </CopyToClipboard>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default BoardDetailContent;
