import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../component/footer/Footer";
import Header from "../component/header/Header";
import { useRecoilValue } from "recoil";
import { tokenState } from "../state";
import Ranking from "../component/home/Ranking";
import HotForum from "../component/home/HotForum";
import RecommendSeminar from "../component/home/RecommendSeminar";
import LiveArticle from "../component/home/LiveArticle";
import IssueArticle from "../component/home/IssueArticle";
import SyncPopup from "./user/SyncPopup";
import HomePopup from "../component/home/HomePopup";
import HotQna from "../component/home/HotQna";
import HotSeminar from "../component/home/HotSeminar";
import HotRecruit from "../component/home/HotRecruit";
import ClassCuration from "../component/home/ClassCuration";
import RecommendContents from "../component/home/RecommendContents";
import UserBox from "../component/home/UserBox";
import RecommendForum from "component/home/RecommendForum";
import Banner from "component/advertisment/banner";
import MarketCuration from "component/home/MarketCuration";
import GroupPurchaseBox from "component/home/GroupPurchaseBox";
import { useGroupPurchaseList } from "api/market/groupPurchase/hooks";
import { isMarketOnlyUser } from "utils/marketUtil";
import MarketRedirectPopup from "component/market/MarketRedirectPopup";

const Home = () => {
  const location = useLocation();
  const syncType = location.state && location.state.syncType;
  const token = useRecoilValue(tokenState);
  const user = (token && token.user_type && !isMarketOnlyUser(token.user_type)) || null;
  const [marketRedirectPopup, setMarketRedirectPopup] = useState(true);

  const { data: groupPurchase } = useGroupPurchaseList({ enabled: !!user });

  const getSurveyCookie = () => {
    const cookie = document.cookie;
    if (cookie.indexOf("surveycookie=done") < 0) {
      return true;
    } else return false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="relative pb-[240px]">
      {isMarketOnlyUser(token?.user_type) && marketRedirectPopup && (
        <MarketRedirectPopup closePopup={() => setMarketRedirectPopup(false)} />
      )}
      <Header />
      <article className="w-[1200px] mx-auto flex flex-wrap justify-between">
        {/* <MainTopBanner /> */}
        <Banner name="pc_home_top" page="home" />
        <div className="mx-auto flex flex-wrap justify-between mt-[20px] gap-y-[50px]">
          <div className="w-full grid grid-cols-3 gap-6">
            <RecommendForum />
            <div className="relative">
              <RecommendContents />
              <div className="z-10 absolute top-0 ">
                <Banner name="pc_home_top_promotion" page="home" />
              </div>
            </div>
            {user && groupPurchase?.length > 0 ? (
              <GroupPurchaseBox groupPurchase={groupPurchase} />
            ) : (
              <UserBox />
            )}
          </div>
          <div className="w-full">
            <div className="absolute bg-[#F8F6FF] w-[100vw] h-[177px] left-0"></div>
            <div className="w-full relative grid grid-cols-2 gap-6 z-10 pt-5">
              <MarketCuration />
              <ClassCuration />
            </div>
          </div>
          <HotQna user={user} />
          <div className="w-full grid grid-cols-3 gap-6">
            <RecommendSeminar />
            <IssueArticle />
            <LiveArticle />
          </div>
          <div className="w-full grid grid-cols-3 gap-6">
            <HotSeminar />
            {/* <TeeFitBanner /> */}
            <div className="mt-[16px]">
              <Banner name="pc_home_seminar_r_side" page="home" />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-6">
            <HotForum />
            <Ranking user={user} />
          </div>
          <div className="w-full grid grid-cols-3 gap-6">
            <Banner name="pc_home_middle_left" page="home" />
            <Banner name="pc_home_middle_center" page="home" />
            <Banner name="pc_home_middle_right" page="home" />
          </div>
          <div className="w-full grid grid-cols-3 gap-6">
            <HotRecruit />
            {/* <MainBottomBanner /> */}
            <Banner name="pc_home_sub_bottom_half" page="home" height="full" />
          </div>
        </div>
      </article>
      <Footer />
      {syncType && <SyncPopup syncType={syncType} />}
      {user && getSurveyCookie() && <HomePopup />}
    </section>
  );
};

export default Home;
