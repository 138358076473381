import { useState, useEffect } from "react";
import { default as CreatableSelect } from "react-select";
import { headerColorState } from "../../state";
import { useSetRecoilState } from "recoil";
import { api, selectStyles } from "../../utils/util";
import dayjs from "dayjs";
import EditorAdmin from "../../component/editor_admin";
import { useLocation, useNavigate } from "react-router-dom";
import { EDU_ROUTE } from "App";
import AttachImage from "component/shared/AttachImage";
import { getImgUrl, getStaticImgUrl } from "utils/imageUtil";

const ClassWrite = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const modify = location.state || null;
  const [content, setContent] = useState(modify ? modify.content : "");
  const [pcThumbnail, setPcThumbnail] = useState(modify ? modify.course.thumbnail_pc : null);
  const [moThumbnail, setMoThumbnail] = useState(modify ? modify.course.thumbnail_mo : null);
  const [title, setTitle] = useState(modify ? modify.course.title : "");
  const [subTitle, setSubTitle] = useState(modify ? modify.course.sub_title : "");
  const [desc, setDesc] = useState(modify ? modify.course.desc : "");
  const [lecturer, setLecturer] = useState(modify ? modify.course.lecturer : "");
  const [homepage, setHomepage] = useState(modify ? modify.course.homepage : "");
  const [publishAt, setPublishAt] = useState(
    modify ? dayjs(modify.course.publish_at).format("YYYY-MM-DDTHH:mm") : "",
  );
  const [releaseAt, setReleaseAt] = useState(
    modify ? dayjs(modify.course.release_at).format("YYYY-MM-DDTHH:mm") : "",
  );
  const [concealAt, setConcealAt] = useState(
    modify ? dayjs(modify.course.conceal_at).format("YYYY-MM-DDTHH:mm") : "",
  );
  const [closeAt, setCloseAt] = useState(
    modify ? dayjs(modify.course.close_at).format("YYYY-MM-DDTHH:mm") : "",
  );
  const [attachment, setAttachment] = useState(modify ? "" : "");
  const [tag, setTag] = useState({
    inputValue: "",
    value: modify
      ? modify.course.tag.split(",").reduce((newTag, tag) => {
          return [...newTag, { label: tag, value: tag }];
        }, [])
      : [],
  });
  const [pcCoverImg, setPcCoverImg] = useState(modify ? modify.course.header_pc : null);
  const [moCoverImg, setMoCoverImg] = useState(modify ? modify.course.header_mo : null);
  const [coverColor, setCoverColor] = useState(modify ? modify.course.header_color : "#333");
  const setHeaderColor = useSetRecoilState(headerColorState);
  const handleChange = (value) => {
    setTag({ ...tag, value });
  };

  const handleInputChange = (inputValue, action) => {
    if (action.action === "input-blur" || action.action === "menu-close") return;
    setTag({ ...tag, inputValue });
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    const { inputValue, value } = tag;
    if (!inputValue) return;
    if (event.key === "Enter" || event.key === ",") {
      setTag({
        inputValue: "",
        value: [...value, createOption(inputValue)],
      });
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    const { inputValue, value } = tag;
    if (!inputValue) return;
    const parseInput = inputValue
      .split(",")
      .filter((item) => item)
      .map((item) => createOption(item));
    setTag({
      inputValue: "",
      value: [...value, ...parseInput],
    });
  };

  const submit = async (e) => {
    e.preventDefault();

    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해주세요");
      return;
    }

    const data = new FormData();
    data.append("title", title);
    data.append("sub_title", subTitle);
    data.append("desc", desc);
    data.append("lecturer", lecturer);
    data.append("homepage", homepage);
    data.append("publish_at", dayjs(publishAt).format());
    data.append("close_at", dayjs(closeAt).format());
    data.append("release_at", dayjs(releaseAt).format());
    data.append("conceal_at", dayjs(concealAt).format());
    data.append("attachment", attachment);
    content && data.append("content", content);
    content &&
      data.append(
        "text_content",
        new DOMParser().parseFromString(content, "text/html").body.textContent,
      );
    data.append("header_color", coverColor);
    data.append("tag", tag.value.map((item) => item.value).join());
    data.append("thumbnail_pc", [pcThumbnail]);
    data.append("thumbnail_mo", [moThumbnail]);
    pcCoverImg && data.append("header_pc", [pcCoverImg]);
    moCoverImg && data.append("header_mo", [moCoverImg]);

    try {
      if (!modify) {
        const res = await api.post("/class/course/write", data);
        navigate(`/${EDU_ROUTE}`, { replace: true });
        alert(res.data.msg);
      } else {
        const res = await api.post(`/class/course/${modify.course.cid}/modify`, data);
        navigate(`/${EDU_ROUTE}`, { replace: true });
        alert(res.data.msg);
      }
    } catch (e) {
      alert(e.response.data.msg);
    }
  };

  useEffect(() => {
    setHeaderColor("white");
    return () => setHeaderColor(null);
  }, [navigate, setHeaderColor]);

  return (
    <div className="class-write">
      <div className="">
        <div className="curation-write">
          <div
            className="cover-img class"
            style={coverColor ? { background: coverColor, marginTop: 0 } : {}}
          >
            <div className="title-box detail">
              <div className="preview">
                {pcCoverImg ? (
                  <img src={getImgUrl(pcCoverImg)} alt="이미지" />
                ) : (
                  <img src={getStaticImgUrl("dummy/no_img.png")} alt="이미지" />
                )}
              </div>
              <div className="detail-info flex column">
                <input
                  className="class-title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  type="text"
                  placeholder="5글자 이상의 제목을 입력해주세요."
                />
                <input
                  className="class-sub-title"
                  onChange={(e) => setSubTitle(e.target.value)}
                  value={subTitle}
                  type="text"
                  placeholder="부제목을 입력해주세요."
                />
                <div className="flex">
                  <input
                    className="more-info"
                    onChange={(e) => setHomepage(e.target.value)}
                    value={homepage}
                    type="text"
                    placeholder="홈페이지 URL을 입력해주세요."
                  />
                </div>
                <div className="flex mt10">
                  <input
                    className="more-info"
                    onChange={(e) => setLecturer(e.target.value)}
                    value={lecturer}
                    type="text"
                    placeholder="강연자를 입력해주세요."
                  />
                </div>
                <div className="flex mt10">
                  <input
                    className="more-info"
                    onChange={(e) => setDesc(e.target.value)}
                    value={desc}
                    type="text"
                    placeholder="설명(등록비)를 입력해주세요."
                  />
                  <input
                    type="color"
                    value={coverColor}
                    onChange={(e) => setCoverColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <section className="board__reg">
              <div className="inner" style={{ marginTop: "270px", paddingBottom: "100px" }}>
                <div className="reg__top__box">
                  <div className="tag-box mb70">
                    <div className="flex">
                      <div className="flex column">
                        <span className="title">발행 기간</span>
                        <div className="datetime__box flex">
                          <div className="datetime__input">
                            <input
                              type="datetime-local"
                              onChange={(e) => setPublishAt(e.target.value)}
                              value={publishAt}
                            />
                          </div>
                          <div className="datetime__input">
                            <input
                              type="datetime-local"
                              onChange={(e) => setCloseAt(e.target.value)}
                              value={closeAt}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex column ml20">
                        <span className="title">강의 기간</span>
                        <div className="datetime__box flex">
                          <div className="datetime__input">
                            <input
                              type="datetime-local"
                              onChange={(e) => setReleaseAt(e.target.value)}
                              value={releaseAt}
                            />
                          </div>
                          <div className="datetime__input">
                            <input
                              type="datetime-local"
                              onChange={(e) => setConcealAt(e.target.value)}
                              value={concealAt}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="reg-menu">
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setMoThumbnail(file)}
                          text="모바일 썸네일 첨부"
                        />
                      </div>
                      {moThumbnail && (
                        <div className="preview-list">
                          <div className="preview-box">
                            <img src={getImgUrl(moThumbnail)} alt="이미지" />
                          </div>
                        </div>
                      )}
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setPcThumbnail(file)}
                          text="PC 썸네일 첨부"
                        />
                      </div>
                      {pcThumbnail && (
                        <div className="preview-list">
                          <div className="preview-box">
                            <img src={getImgUrl(pcThumbnail)} alt="이미지" />
                          </div>
                        </div>
                      )}
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setPcCoverImg(file)}
                          text="PC 커버이미지"
                        />
                      </div>
                      {pcCoverImg && (
                        <div className="preview-list">
                          <div className="preview-box">
                            <img src={getImgUrl(pcCoverImg)} alt="이미지" />
                          </div>
                        </div>
                      )}
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setMoCoverImg(file)}
                          text="모바일 커버이미지"
                        />
                      </div>
                      {moCoverImg && (
                        <div className="preview-list">
                          <div className="preview-box">
                            <img src={getImgUrl(moCoverImg)} alt="이미지" />
                          </div>
                        </div>
                      )}
                      <div className="left-menu-box">
                        <AttachImage changeImage={(file) => setAttachment(file)} text="첨부파일" />
                      </div>
                      {attachment && <i className="ic ic_mark"></i>}
                    </div>

                    <div className="tag-select">
                      <CreatableSelect
                        value={tag.value}
                        inputValue={tag.inputValue}
                        styles={selectStyles}
                        placeholder="태그를 선택해주세요."
                        isClearable
                        isMulti
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="short-inner curation-box">
                  <div className="reg__mid__box curation-editor">
                    <EditorAdmin content={content} setContent={setContent} />
                  </div>

                  <div className="reg__menu__box">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/contents", { replace: true });
                      }}
                      className="menu-btn type1"
                      style={{ border: "none", outline: "none" }}
                    >
                      취소
                    </button>
                    <button
                      onClick={submit}
                      className="menu-btn type2"
                      style={{ border: "none", outline: "none" }}
                    >
                      {modify ? "수정" : "저장"}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassWrite;
