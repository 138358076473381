import { Link } from "react-router-dom";
import { money } from "utils/marketUtil";
import { marketUrl } from "utils/util";
import { getResizedImgUrl } from "utils/imageUtil";
import { checkIsRequestDeal } from "utils/marketUtil";
import ContentLoader from "react-content-loader";
import { useSearchSegemnet } from "hooks/useSearchSegment";

export default function ProductUnit({
  id,
  name,
  regularPrice,
  salePrice,
  discountPercent,
  thumbnail,
  categoryIds = [],
  productCode,
  brandName,
  isSoldOut = false,
  isLoadingUnit = true,
  isSearchResult = false,
}) {
  const { handleSearchItemClick } = useSearchSegemnet();
  const isRequestDeal = checkIsRequestDeal(categoryIds);

  return (
    <>
      {isLoadingUnit ? (
        <div className="w-full h-auto max-w-[224px]">
          <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox="0 0 224 320"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="10" ry="10" width="224" height="224" />
            <rect x="10" y="234" rx="4" ry="4" width="180" height="20" />
            <rect x="10" y="264" rx="4" ry="4" width="120" height="15" />
            <rect x="10" y="289" rx="4" ry="4" width="90" height="15" />
          </ContentLoader>
        </div>
      ) : (
        <Link
          to={marketUrl(`/market/product/${id}`)}
          onClick={() => {
            if (!isSearchResult) {
              return;
            }
            handleSearchItemClick({
              name,
              id,
              productCode,
            });
          }}
          className="block w-full group"
        >
          <div className="w-full">
            <div className="w-[160px] h-[160px] relative overflow-hidden bg-white rounded-lg aspect-square object-cover">
              <img
                src={getResizedImgUrl(thumbnail, 480, 480)}
                alt="thumbnail"
                className="w-full h-full object-cover group-hover:scale-105 duration-300"
              />
              <div className="absolute top-0 left-0 w-full h-full rounded-[10px] shadow-[0_0_0_1px_rgba(0,0,0,0.1)_inset]"></div>
              {isSoldOut && (
                <div className="absolute top-0 w-full h-full flex items-center justify-center rounded-[10px] backdrop-blur-[2px] bg-pureblack bg-opacity-40">
                  <span className="text-[32px] lg:text-[20px] text-white font-semibold">
                    일시품절
                  </span>
                </div>
              )}
            </div>
            <div className="px-2">
              <div className="h-[98px] pt-[10px]">
                <div className="flex flex-col gap-[2px]">
                  {productCode && (
                    <div className="flex mb-1.5 h-[24px] w-fit items-center justify-center gap-1 rounded-[6px] border-[1px] border-secondary-200 px-1.5 text-[12px]">
                      <span className="text-secondary-500">상품 코드</span>{" "}
                      <span className="font-medium">{productCode}</span>
                    </div>
                  )}
                  {brandName && (
                    <b className="text-[12px] font-semibold text-secondary-500">{brandName}</b>
                  )}
                  <div className="h-[35px]">
                    <h3 className="line-clamp-2 w-full leading-[17px] tracking-[-0.27px] text-pureblack text-[15px]">
                      {name}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="h-[34px] flex flex-col justify-end">
                {isRequestDeal ? (
                  <p className="flex items-center gap-1.5">
                    <b className="text-[16px] font-semibold leading-[19px] text-secondary-800">
                      상담하기
                    </b>
                  </p>
                ) : (
                  <>
                    {salePrice < regularPrice && (
                      <p className="stroke-secondary-300 text-[13px] font-medium leading-[16px] text-secondary-300 line-through">
                        {money(regularPrice)}원
                      </p>
                    )}
                    <p className="flex h-[19px] items-center gap-[6px]">
                      {discountPercent > 0 && (
                        <strong className="flex items-center text-[16px] font-semibold leading-[100%] text-red-200">
                          {discountPercent}%
                        </strong>
                      )}
                      <b className="font-semibold text-[16px] leading-[100%] text-secondary-800">
                        {money(salePrice)}원
                      </b>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
}
