import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../component/Loading";
import LoanPopup from "../../../component/management/LoanPopup";
import { api, writeDayYtoS, scrollTopList } from "../../../utils/util";
import ReactPaginate from "react-paginate";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../state";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getStaticImgUrl } from "utils/imageUtil";

const ConsultingEum = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const [popup, setPopup] = useState(false);
  const param = new URLSearchParams(location.search);
  const page = param.get("page") || 1;
  const scrollRef = useRef(null);

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["boardList41", page],
    () => api.get(`/board/list/41`, { params: { page: page } }).then((res) => res.data),
    {},
  );

  const handleClick = () => {
    if (token.user_type !== "doctor") {
      window.alert("의사회원만 작성 가능합니다");
    }
    scrollRef.current && scrollTopList(scrollRef);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    param.append("page", selected);
    navigate(`/management/consulting/mediground?${param}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (pageInfoLoading) return <Loading />;

  return (
    <section>
      {popup && <LoanPopup setPopup={setPopup} txt="1600-0493 (대표상담번호)" />}
      <div className="">
        <div className="inner">
          <div className="top-article"></div>
          <article className="section__tit__box">
            <div className="tit-box">
              <span className="tit-txt">이앤디코퍼레이션 (E:UM MEDICAL SOLUTION)</span>
            </div>
          </article>
          <div className="theater__video-wrap">
            <iframe
              className="theater__video__osstem"
              style={{ width: "100%" }}
              src="https://www.youtube.com/embed/kr9HaJmt654"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-info">
            <div className="video-info__right">
              <span>성공적인 개원을 위한 세미나, 이음</span>
            </div>
          </div>
          <div className="video-detail">
            <a
              className="theater-publisher"
              href="http://www.iieum.co.kr/index.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={getStaticImgUrl("logos/companies/logo_eum.svg")} alt="게시자" />
              <span>이앤디코퍼레이션</span>
            </a>
            <div className="detail__group">
              <div className="menu-box-list theater__menu-box-list">
                <a
                  href="http://www.iieum.co.kr/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="menu-box home__btn">
                    <div className="home"></div>
                    <span className="menu-txt">홈페이지</span>
                  </button>
                </a>
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: "[모어덴 - 이앤디코퍼레이션 컨설팅]",
                        // text: adsProductState[param].title,
                        url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
          <div className="top-article" style={{ margin: "45px 0" }}>
            <div className="btn-flex">
              <button className="btn-inner-flex" onClick={() => setPopup(true)}>
                <div className="icon-phone"></div>
                <div className="btn-txt">전화 상담하기</div>
              </button>
              <button className="btn-inner-flex">
                <div className="icon-pen"></div>
                <div className="btn-txt" onClick={handleClick}>
                  문의글 남기기
                </div>
              </button>
            </div>
          </div>

          <div className="bottom-article">
            <img src={getStaticImgUrl("consulting/221122_eum.png")} alt="" />
          </div>
          {pageInfo && (
            <>
              <div className="loan-page-title-txt">문의하기</div>
              <article className="loan-paging">
                {token.user_type === "doctor" && (
                  <Link
                    to={`/management/consulting/write/${pageInfo.cate_cd}`}
                    className="write-btn"
                    style={{ bottom: "30px" }}
                  >
                    <i className="ic ic-write"></i>
                    <span className="txt">글쓰기</span>
                  </Link>
                )}
              </article>

              <article className="list__table2" ref={scrollRef}>
                <div className="list">
                  <div className="list-head">
                    <div className="w10p left">구분</div>
                    <div className="w50p left">제목</div>
                    <div className="w10p left">작성자</div>
                    <div className="w15p">작성일시</div>
                    <div className="w5p">조회</div>
                  </div>
                  {pageInfo.board?.map((item, i) => {
                    return (
                      <Link
                        className="width-max"
                        key={item.bid}
                        to={`/management/consulting/article/${item.bid}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          window.scrollTo(0, 0);
                        }}
                      >
                        <div className="slim content">
                          <div className="w10p left">
                            <span className="left-hospital">문의</span>
                          </div>
                          <div className="w50p left">
                            {item.title}[{item.comment}]
                          </div>
                          <div className="w10p left">
                            <span>{token.user_nickname === item.unick ? item.unick : "익명"}</span>
                          </div>
                          <div className="w15p">
                            <span>{writeDayYtoS(item.reg_dttm)}</span>
                          </div>
                          <div className="w5p">
                            <span>{item.view}</span>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </article>

              {pageInfo.page && (
                <article className="paging">
                  <ReactPaginate
                    previousLabel="←"
                    previousLinkClassName="before"
                    nextLabel="→"
                    nextLinkClassName="next"
                    breakLabel="..."
                    pageCount={pageInfo.pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    pageLinkClassName="num"
                    activeClassName=""
                    activeLinkClassName="current"
                    onPageChange={onPageClick}
                    forcePage={pageInfo.page - 1}
                  />
                </article>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ConsultingEum;
