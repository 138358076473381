import { Agreement } from "types/agreement";

export const isUsernameValid = (username: string) => {
  const usernamePattern = /^[a-zA-Z0-9_]{4,20}$/;
  return usernamePattern.test(username);
};

export const isEmailValid = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

export const isPasswordValid = (password: string) => {
  const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;
  return passwordPattern.test(password);
};

export const isRequiredAgreementValid = (agreement: Agreement): boolean => {
  return Object.values(agreement).every((item) => !item.isRequired || item.isAgreed);
};
