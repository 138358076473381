import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { tokenState } from "../../state";
import { getStorage, tokenUpdateInApiOption } from "../../utils/util";

const kakaoLogout = () => {
  try {
    window.Kakao.Auth && window.Kakao.Auth.logout();
  } catch {
    alert("로그아웃을 다시 시도해주세요");
  }
};

const Logout = () => {
  const setToken = useSetRecoilState(tokenState);
  const queryClient = useQueryClient();

  useEffect(() => {
    kakaoLogout();
    getStorage().clear();
    setToken({});
    queryClient.clear();
    tokenUpdateInApiOption("");

    const url = new URL(`${import.meta.env.VITE_APP_AUTH_HOST}/logout`);
    url.searchParams.append("redirect_uri", import.meta.env.VITE_APP_MARKET_URL + "/auth/signout");
    window.location.replace(url.href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Logout;
