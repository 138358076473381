import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Loading from "../../component/Loading";
import { tokenState } from "../../state";
import { getStorage, api } from "../../utils/util";
import ChannelTalk from "../../component/ChannelTalk";
import axios from "axios";
import { getStaticImgUrl } from "utils/imageUtil";

export const uploadLicenseImg = async (e, setLoading, setImgSrc) => {
  // TODO : pdf 첨부도 허용할 것인가?
  if (
    !e ||
    !e.target ||
    !e.target.files ||
    !e.target.files.length ||
    !e.target.files[0].type.match(/image\//)
  )
    return;

  try {
    setLoading(true);
    const file = e.target.files[0];
    const res = await api.post("/util/presigned/verify", {
      filename: file.name,
    });
    const signedUrl = res.data;
    const url = new URL(signedUrl);
    url.search = ""; // 쿼리 스트링 제거
    await axios.put(signedUrl, file);
    setImgSrc(url.toString());
    await api.post("/user/verify", {
      licenseImageFilePath: url.toString(),
    });
  } catch (err) {
    console.log(err);
    alert("이미지 업로드에 실패했습니다.");
  }
  setLoading(false);
};

function LicenseVerify() {
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState(null);
  const [token, setToken] = useRecoilState(tokenState);
  const [loading, setLoading] = useState(false);
  const state = location?.state;

  const upload = async () => {
    if (!imgSrc) {
      alert("이미지를 첨부해주세요");
      return;
    }

    getStorage().setItem("token", JSON.stringify({ ...token, user_status: "pending" }));
    setToken({ ...token, user_status: "pending" });
  };

  return (
    <div className="container full absolute z-[500] top-0">
      <section className="member">
        <div className="member__wrap">
          {loading && <Loading />}
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>

          <div className="member__con">
            <div className="member__tit">
              <div className="tit-txt">신분 인증</div>
            </div>

            <div className="certify-info">
              <div className="top-txt">모어덴 입장 전, 당신의 신분을 확인시켜주세요.</div>
              <div className="bottom-txt">의사 면허증 또는 학생증을 인증해주세요.</div>
            </div>

            <div className="certify-how text-center">
              <div className="top-txt">[인증하기]</div>
              <div className="bottom-txt">
                하단 &lt;이미지 첨부&gt; 버튼을 눌러, 의사 면허증을 업로드 해주세요.
              </div>
              {imgSrc ? (
                <div className="certificate">
                  <div className="photo-upload-img2">
                    <div className="img-box">
                      <button
                        className="photo-remove-btn"
                        onClick={() => {
                          setImgSrc(null);
                        }}
                        style={{
                          border: "none",
                          outline: "none",
                          cursor: "pointer",
                        }}
                      ></button>
                      <img
                        className="inline-block"
                        style={{
                          width: "auto",
                          maxWidth: "120px",
                          height: "auto",
                          maxHeight: "200px",
                        }}
                        src={imgSrc}
                        alt="이미지"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="certificate">
                  <img
                    className="inline-block"
                    src={getStaticImgUrl("login/certificate.png")}
                    alt=""
                  />
                </div>
              )}
              <div className="certify-form">
                <div className="photo-upload-btn-box">
                  <label className="photo-upload-btn">
                    <i className="ic ic-photo"></i>
                    <input
                      type="file"
                      onChange={(e) => uploadLicenseImg(e, setLoading, setImgSrc)}
                      accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                      style={{ display: "none", cursor: "pointer" }}
                    />
                    <span className="txt" style={{ cursor: "pointer" }}>
                      이미지 첨부
                    </span>
                  </label>
                </div>
                <div className="photo-certify-btn-box">
                  <button
                    className="certify-btn"
                    onClick={upload}
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                    }}
                  >
                    면허 등록하기
                  </button>
                </div>
              </div>
            </div>
            <div className="certify-form">
              <div className="other-login-btn-box">
                <Link to="/" className="other-login-btn home">
                  <i className="ii ii-home-outline mr-1"></i>
                  홈으로 돌아가기
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ChannelTalk />
    </div>
  );
}

export default LicenseVerify;
