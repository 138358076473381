import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "../../state";
import { getStorage, getRefreshToken } from "../../utils/util";
import { getAuthToken } from "api/auth/api";
import { getTokenWithPayload } from "utils/authUtil";

function VerifyPending() {
  // TODO : 로그인 토큰 정보에 대한 memberType 갱신시도를 매번 해야함
  // TODO : token_status 에서 pending 인지 verify 인지 구분
  // TODO : 중간 중간 로딩해서 넘어가게 해야 함
  const [, setToken] = useRecoilState(tokenState);

  const setTokenByRefreshToken = async () => {
    const storage = getStorage();
    const refreshToken = getRefreshToken(storage);
    const tokenData = await getAuthToken({ refresh_token: refreshToken });
    if (!tokenData) return;

    const decodedData = getTokenWithPayload(tokenData);

    storage.setItem("token", JSON.stringify(decodedData));
    setToken(decodedData);
  };

  useEffect(() => {
    setTokenByRefreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container full absolute z-[500] top-0">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>

          <div className="member__con">
            <div className="login__tit">
              <div className="tit-txt">회원가입 신청완료</div>
            </div>

            <div className="join-form">
              <div className="success-box">
                <div className="logo">
                  <img
                    className="inline-block"
                    src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_certify_success.png`}
                    alt="이미지"
                  />
                </div>
                <div className="main-txt">회원가입 신청이 완료되었습니다.</div>
                <div className="sub-txt">인증이 완료될 때까지 조금만 기다려주세요.</div>
                <div className="other-login-btn-box">
                  <Link to="/" className="other-login-btn">
                    홈으로 돌아가기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyPending;
