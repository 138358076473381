import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../component/Loading";
import NoticeContent from "../../component/mypage/NoticeContent";
import SideMenu from "../../component/mypage/SideMenu";
import { api, dttm } from "../../utils/util";
import { getImgUrl } from "utils/imageUtil";

function NoticeDetail(props) {
  const navigate = useNavigate();
  const bid = useParams().bid;
  const [notice, setNotice] = useState({ load: false });

  useEffect(() => {
    // if(notice.load) return;
    api
      .get(`/mypage/notice/${bid}`)
      .then((res) => setNotice({ ...res.data, load: true }))
      .catch((err) => {
        alert(err.response.data.msg);
        navigate(-1);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  if (!notice.load) return <Loading />;
  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="notice" />

            <div className="con__layout">
              <section className="magazine__detail" style={{ marginBottom: "113.5px" }}>
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt">공지사항</span>
                  </div>
                </article>

                <div className="detail__group">
                  <div className="tit-box">
                    <div className="subject-box">{notice.notice.title}</div>
                    <div className="info-box">
                      {/* <span className="txt">모어덴</span> */}
                      <span className="txt">{dttm(notice.notice.reg_dttm)}</span>
                    </div>
                  </div>

                  <div className="con-box">
                    <div
                      className="txt-box"
                      dangerouslySetInnerHTML={{ __html: notice.notice.content }}
                      style={{}}
                    ></div>
                    {notice.images &&
                      notice.images.map((item) => {
                        return (
                          <img
                            key={item.link}
                            src={getImgUrl(item.link)}
                            alt="notice"
                            style={{ marginTop: "30px", marginBottom: "30px" }}
                          />
                        );
                      })}
                  </div>
                </div>

                <NoticeContent props={props} />
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default NoticeDetail;
