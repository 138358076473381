import { Link, useLocation } from "react-router-dom";
import { dttm, priceText, seminarDttm, writeDayMMDD } from "../../utils/util";
import { getImgUrl, getStaticImgUrl } from "utils/imageUtil";

const ManageListTable = ({ current, articles, bid }) => {
  // MARK: 광고 상단 공지 입니다. 추후 백엔드에서 받아와야 합니다. 일단 하드코딩;
  const adArticle = {
    bid: 629,
    boardName: "estate",
    location: "서울 중랑구",
    title: "[임대] 서울 면목동 초역세권, 메디컬빌딩에 치과 임대합니다.",
    price: "7,000만원",
    createdAt: "04.09",
    topic: "임대",
  };

  const location = useLocation();
  const param = new URLSearchParams(location.search);
  return (
    <div className="list">
      {current === "recruit" ? (
        <div className="list-head">
          <div className="w15p left">지역</div>
          <div className="w15p left">병원</div>
          <div className="w55p left">제목</div>
          <div className="w5p">작성일</div>
          <div className="w5p">조회수</div>
          <div className="w5p">추천수</div>
        </div>
      ) : current === "estate" ? (
        <div className="list-head">
          <div className="w10p left">분류</div>
          <div className="w15p left">지역</div>
          <div className="w50p left">제목</div>
          <div className="w15p">가격</div>
          <div className="w5p">작성일</div>
          <div className="w5p">조회수</div>
        </div>
      ) : current === "market" ? (
        <div className="list-head">
          <div className="w10p left">이미지</div>
          <div className="w15p left">지역</div>
          <div className="w50p left">제목</div>
          <div className="w10p">가격</div>
          <div className="w10p">작성일</div>
          <div className="w5p">조회수</div>
        </div>
      ) : current === "seminar" ? (
        <div className="list-head">
          <div className="w15p left">토픽</div>
          <div className="w15p left">지역</div>
          <div className="w45p left">제목</div>
          <div className="w15p">일정</div>
          <div className="w10p">보수교육점수</div>
        </div>
      ) : (
        <></>
      )}
      {/* 상단 고정 광고 추가 영역 */}
      {current === "estate" && (
        <Link
          className="width-max notice"
          to={`/management/estate/${adArticle.bid}?${param}`}
          key={adArticle.bid}
          onClick={(event) => {
            event.stopPropagation();
            window.scrollTo(0, 0);
          }}
        >
          <div className="w10p left">
            <span className="accent" style={{ marginRight: "5px" }}>
              [{adArticle.topic}]
            </span>
          </div>
          <div className="w15p left">{adArticle.location}</div>
          <div className="w50p left">{adArticle.title}</div>
          <div className="w15p">{adArticle.price}</div>
          <div className="w5p"></div>
          <div className="w5p"></div>
        </Link>
      )}

      {articles &&
        articles.map((item, i) => {
          return current === "recruit" ? (
            <Link className="width-max" key={item.bid} to={`/recruit/doctor/${item.bid}?${param}`}>
              <div className={item.bid === bid ? "slim content current" : "slim content"}>
                <div className="w15p left">
                  <span className="accent">
                    <b>{item.location1}</b> {" " + item.location2}
                  </span>
                </div>
                <div className="w15p left">
                  <span className="left-hospital">{item.hospital_name}</span>
                </div>
                <div className="w55p left">{item.title}</div>
                <div className="w5p">
                  <span>{writeDayMMDD(item.reg_dttm)}</span>
                </div>
                <div className="w5p">
                  <span>{item.view}</span>
                </div>
                <div className="w5p">
                  <span className="accent">{item.like}</span>
                </div>
              </div>
            </Link>
          ) : current === "estate" ? (
            <Link
              className="width-max"
              to={`/management/${current}/${item.bid}?${param}`}
              key={item.bid}
              onClick={(event) => {
                event.stopPropagation();
                window.scrollTo(0, 0);
              }}
            >
              <div className={item.bid === bid ? "slim content current" : "slim content"}>
                <div className="w10p left">
                  <span className="accent" style={{ marginRight: "5px" }}>
                    [{item.type === "transmission" ? "양도" : "임대"}]
                  </span>
                </div>
                <div className="w15p left">
                  <span>
                    {item.location1} {item.location2}
                  </span>
                </div>
                <div className="w50p left">
                  {item.title.length > 50 ? item.title.slice(0, 50) + "⋯" : item.title}
                </div>
                {item.type === "transmission" ? (
                  <div className="w15p">
                    <span>총 {priceText(item.price1 + item.price2)}</span>
                  </div>
                ) : (
                  <div className="w15p">
                    <span>{priceText(item.price1)}</span>
                  </div>
                )}
                <div className="w5p">
                  <span>{writeDayMMDD(item.reg_dttm)}</span>
                </div>
                <div className="w5p">
                  <span>{item.view}</span>
                </div>
              </div>
            </Link>
          ) : current === "market" ? (
            <Link
              className="width-max"
              key={item.bid}
              to={`/management/${current}/${item.bid}?${param}`}
              onClick={(event) => {
                event.stopPropagation();
                window.scrollTo(0, 0);
              }}
            >
              <div className={item.bid === bid ? "content current" : "content"}>
                <div className="w10p left">
                  <div className="img-box">
                    <img
                      src={
                        item.thumbnail
                          ? getImgUrl(item.thumbnail)
                          : getStaticImgUrl("category/market.jpg")
                      }
                      alt="이미지"
                    />
                  </div>
                </div>
                <div className="w15p left">
                  <span>
                    {item.location1} {item.location2}
                  </span>
                </div>
                <div className="w50p left">
                  {!!item.sold_at && "[판매완료]"}
                  {item.title.length > 80 ? item.title.slice(0, 80) + "⋯" : item.title}
                </div>
                <div className="w10p">
                  <span>{priceText(item.price)}</span>
                </div>
                <div className="w10p">
                  <span>{dttm(item.reg_dttm)}</span>
                </div>
                <div className="w5p">
                  <span>{item.view}</span>
                </div>
              </div>
            </Link>
          ) : (
            <Link
              className="width-max"
              key={item.bid}
              to={`/management/${current}/${item.bid}?${param}`}
            >
              <div className={item.bid === bid ? "slim content current" : "slim content"}>
                <div className="w15p left">
                  <span className="accent">{item.topic}</span>
                </div>
                <div className="w15p left">
                  <span>
                    {item.location1} {item.location2}
                  </span>
                </div>
                <div className="w45p left">
                  {item.title.length > 40 ? item.title.slice(0, 40) + "⋯" : item.title}
                </div>
                <div className="w15p">
                  <span>
                    {item.end_date
                      ? seminarDttm(item.start_date) + " ~ " + seminarDttm(item.end_date)
                      : seminarDttm(item.start_date)}
                  </span>
                </div>
                <div className="w10p">
                  <span>{item.point}점</span>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default ManageListTable;
