import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getResizedImgUrl } from "utils/imageUtil";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ConsultingDentalMaster = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <div className="">
        <div className="inner" style={{ marginTop: 64 }}>
          <div className="video-info">
            <div className="video-info__right">
              <span>원장님들이 원하는 이상적인 마케팅</span>
            </div>
          </div>
          <div className="video-detail">
            <a
              className="theater-publisher"
              href="https://golddoctors.co.kr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://moreden-img.s3.amazonaws.com/img/20231208/53EAF708-6947-4AA9-B2ED-F538A7D08976/KakaoTalk_20231207_105951505%201.png"
                alt="게시자"
              />
              <span>(주) 골드닥터스</span>
            </a>
            <div className="detail__group">
              <div className="menu-box-list theater__menu-box-list">
                <a href="https://golddoctors.co.kr/" target="_blank" rel="noopener noreferrer">
                  <button className="menu-box home__btn">
                    <div className="home"></div>
                    <span className="menu-txt">홈페이지</span>
                  </button>
                </a>
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: "[모어덴 - 골드닥터스 컨설팅]",
                        // text: adsProductState[param].title,
                        url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
          <div className="bottom-article">
            <a class="" target="_blank" rel="noopener noreferrer" href="https://golddoctors.co.kr/">
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/9C1E7FDF-1383-4EA0-8BD3-DE98E429ED37/1.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/2E88AA60-C79D-44DB-A1A2-6FAC49AA02B4/2.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/2F133541-E9CD-42DD-B1C1-E223E7918257/3.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/01F123D0-1529-4BCC-9C5D-B43EE924A90E/4.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/9B61012D-AB38-4AF6-9A60-CB1DA1CEF63B/5.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/43B67039-B1EC-4E23-BCDB-AA29F44AD6C5/6.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/0D246FF4-64D8-4F5E-918E-1AE170A8CB89/7.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/5144015C-D115-4F35-99D3-EBB38B7DFE8D/8.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/D1027CFF-850B-420A-849A-2ABAD48EE4FD/9.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/66BFDC96-1E2F-408D-91AC-F71193C101F5/10.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/683B4808-89E2-4C01-AD8A-B30DA3BCB4BD/11.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/41012710-F20A-4D30-910F-3D6CF07B126F/12.png",
                  1500,
                )}
                alt=""
              />
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20231208/8FF04B07-E16F-4260-8B71-F70085190446/13.png",
                  1500,
                )}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingDentalMaster;
