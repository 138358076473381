import { cls } from "../../utils/util";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
interface CheckBox extends InputProps {
  value?: string | number | readonly string[];
  onChange: React.ChangeEventHandler<HTMLInputElement>; // 필수
  checked: boolean; // 필수
}

export default function StyledCheckBox({ className, children, ...props }: CheckBox) {
  return (
    <label className="relative flex items-center cursor-pointer w-[fit-content]">
      <input
        {...props}
        type="checkbox"
        className={cls(
          className ? className : "accent-primary-800 w-[20px] h-[20px]",
          "block appearance-auto flex-shrink-0 cursor-pointer hover:checked:shadow-none hover:ring-[5px] ring-[#f1f3f880] hover:bg-secondary-100  hover:shadow-[inset_20px_20px_#f1f3f880] ",
        )}
      />
      {children}
    </label>
  );
}
