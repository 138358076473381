import { Link } from "react-router-dom";
import { getStaticImgUrl } from "utils/imageUtil";

function HygienistMain() {
  return (
    <div className="inner">
      <Link to="/contents/article/714">
        <img src={getStaticImgUrl("cheecruit/alba_banner_pc2.png")} alt="" />
      </Link>
      <div className="flex spaceBetween my-10">
        <Link to="/recruit/chicruiting">
          <button className="recruit-direct-button chicruiting">
            <div className="main-txt">
              <div className="top">NEW</div>
              <div className="tag">유료</div>
              <div className="tit">
                헤드헌팅 서비스
                <br />
                <b>치크루팅</b>
                <mark>맞춤인재 즉시 채용</mark>
              </div>
              <div className="sub-tit">
                채용 후 3개월 미만 근무 시 <b>무료!</b>
              </div>
            </div>
            <img src={getStaticImgUrl("cheecruit/doctor.svg")} alt="" />
            <div className="bottom-button">
              <b className="mr-1">헤드헌터를 통해</b>빠르게 매칭받기{" "}
              <i className="ii ii-arrow-right" style={{ color: "#fff" }}></i>
            </div>
          </button>
        </Link>
        <Link to="/recruit/hygienist">
          <button className="recruit-direct-button">
            <div className="main-txt">
              <div className="tag">무료</div>
              <div className="tit">
                모어덴 구인공고
                <br />
                <b>게시판 이용하기</b>
              </div>
              <div className="sub-tit">
                치과위생사 <b style={{ color: "#FF7575" }}>2만명에게 실시간 노출</b>
              </div>
            </div>
            <div className="bottom-button" style={{ background: "#FF7575" }}>
              게시판 글 작성하러가기 <i className="ii ii-arrow-right" style={{ color: "#fff" }}></i>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default HygienistMain;
