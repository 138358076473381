import { useState, useEffect } from "react";
import HeaderColor from "../../component/contents/HeaderColor";
import { api } from "../../utils/util";
import ClassItem from "./ClassItem";
import { getStaticImgUrl } from "utils/imageUtil";

const ClassPage = () => {
  const [isOngoingClass, setIsOngoingClass] = useState(true);
  const [classList, setClassList] = useState({ load: false });

  useEffect(() => {
    api.get("/class/list").then((res) => {
      setClassList({ ...res.data, load: true });
    });
  }, []);

  return (
    <div className="container">
      <HeaderColor>
        <div className="curation-detail class main">
          <div
            className="cover-img"
            style={{ backgroundImage: `url(${getStaticImgUrl("class/class_header.png")})` }}
          >
            <div className="title-box">
              <mark>OPEN</mark>
              <div className="title">모어덴 보수교육/학술대회</div>
              <div className="sub_title">치과의사에게 꼭 필요한 강의만 모았습니다.</div>
            </div>
          </div>

          <div className="wrapper">
            <div className="inner">
              <div className="main_tab">
                <div className="ongoing">
                  <button
                    className={isOngoingClass ? "selected" : ""}
                    onClick={() => setIsOngoingClass(true)}
                  >
                    진행중
                  </button>
                  <button
                    className={!isOngoingClass ? "selected" : ""}
                    onClick={() => setIsOngoingClass(false)}
                  >
                    종료
                  </button>
                </div>
                <div className="sorting">
                  <button>최신순&#8595;</button>
                  <button>교육기관순&#8595;</button>
                </div>
              </div>
              {/* 보수교육 리스트 */}
              {isOngoingClass ? (
                <>
                  <div className="class-tit">
                    <b>수강중인</b> 클래스
                  </div>
                  <div className="class_box_list">
                    {classList.load && classList.my_courses && classList.my_courses.length > 0 ? (
                      classList.my_courses.map((item, idx) => {
                        return <ClassItem item={item} idx={idx} tab="curriculum" key={idx} />;
                      })
                    ) : (
                      <div className="no_class_item">
                        <img src={getStaticImgUrl(`class/no_class_item.svg`)} alt="" />
                        <mark>수강중인 강의가 보여지는 공간입니다.</mark>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="class-tit">
                    <b>종료</b> 보수교육
                  </div>
                  <div className="class_box_list">
                    {classList.load &&
                    classList.closed_courses &&
                    classList.closed_courses.length > 0 ? (
                      classList.closed_courses.map((item, idx) => {
                        return <ClassItem item={item} idx={idx} tab="info" key={idx} />;
                      })
                    ) : (
                      <div className="no_class_item">
                        <img src={getStaticImgUrl(`class/no_class_item.svg`)} alt="" />
                        <mark>종료한 보수교육이 보여지는 공간입니다.</mark>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {isOngoingClass && (
            <>
              <hr />
              <div className="wrapper">
                <div className="inner">
                  {/* 보수교육 리스트 */}
                  <div className="class-tit">
                    <b>수강 가능한</b> 클래스
                  </div>
                  <div className="class_box_list">
                    {classList.load &&
                    classList.active_courses &&
                    classList.active_courses.length > 0 ? (
                      classList.active_courses.map((item, idx) => {
                        return <ClassItem item={item} idx={idx} tab="info" key={idx} />;
                      })
                    ) : (
                      <div className="no_class_item">
                        <img src={getStaticImgUrl(`class/no_class_item.svg`)} alt="" />
                        <mark>수강 가능한 강의가 보여지는 공간입니다.</mark>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </HeaderColor>
    </div>
  );
};

export default ClassPage;
