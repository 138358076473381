import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BoardReportPopup from "../../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../../component/board/BoardTrashPopup";
import Loading from "../../../component/Loading";
import ManageContent from "../../../component/management/ManageContent";
import { api, dttm, htmlTextContent, priceText } from "../../../utils/util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KakaoMap from "../../../component/shared/KakaoMap";
import { getImgUrl } from "utils/imageUtil";

function EstateDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const bid = useParams().bid;
  const [content, setContent] = useState();
  const [pageInfo, setPageInfo] = useState();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";
  const [reportPopup, setReportPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const estateScroll = useRef();

  const modifyFunc = () => {
    navigate(`/management/estate/modify/${bid}`, { state: { ...content } });
  };

  const directDeleteArticle = () => {
    api.delete(`/management/estate/delete/${bid}`).then(() => {
      navigate(`/management/estate`, { replace: true });
    });
  };

  const scrapClicked = async () => {
    const data = {
      scraped: !content.scraped,
    };
    const res = await api.post(`/management/estate/scrap/${bid}`, data).catch((err) => {
      alert(err.response.data.msg);
    });

    res &&
      setContent({
        ...content,
        scraped: res.data.scrap,
      });
  };

  useEffect(() => {
    api
      .get(`/management/estate/article/${bid}`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        alert(err.response.data?.msg);
        navigate(-1);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  useEffect(() => {
    const data = new URLSearchParams();
    page && data.append("page", page);
    q && data.append("q", q);

    api
      .get(`/management/estate/list`, {
        params: data,
      })
      .then((res) => {
        setPageInfo(res.data);
      });
  }, [page, q]);

  if (!content) return <Loading current="management" />;

  return (
    <div className="">
      <div className="wrapper">
        <section className="magazine__detail" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">병원 양도/임대</span>
                {content.article && content.ismine ? (
                  <>
                    <div className="menu-box">
                      <div className="report-box">
                        <button onClick={modifyFunc} className="report-btn">
                          수정
                        </button>
                      </div>
                      <div className="report-box">
                        <button onClick={() => setDeletePopup(true)} className="report-btn">
                          삭제
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="menu-box"></div>
                )}
              </div>
            </article>
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}
            <div className="detail__group">
              <div className="tit-box">
                <div className="subject-box">{content.article.title}</div>
                <div className="info-box">
                  <span className="txt">{content.article.unick}</span>
                  <span className="txt">{dttm(content.article.reg_dttm)}</span>
                </div>
              </div>

              <div className="etc-box">
                <div className="row">
                  <div className="subject">위치:</div>
                  <div className="con">{content.article.address}</div>
                </div>
                <div className="row">
                  <div className="subject">양도/임대:</div>
                  <div className="con">
                    {content.article.type === "transmission" ? "양도" : "임대"}
                  </div>
                </div>
                {content.article && content.article.type === "transmission" ? (
                  <div className="row">
                    <div className="subject">금액:</div>
                    <div className="con">
                      보증금 {priceText(content.article.price1)} | 권리금{" "}
                      {priceText(content.article.price2)}
                      <span className="accent">
                        총 양도금 {priceText(content.article.price1 + content.article.price2)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="subject">금액:</div>
                    <div className="con">
                      보증금 {priceText(content.article.price1)} | 임대료{" "}
                      {priceText(content.article.price2)}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="subject">전화번호:</div>
                  <div className="con">{content.article.call_number}</div>
                </div>
              </div>

              <div className="con-box">
                <div className="txt-box" style={{}}>
                  {htmlTextContent(content.article.content)}
                </div>
                <div className="img-box">
                  {content.images &&
                    content.images.map((item) => {
                      return <img key={item.link} src={getImgUrl(item.link)} alt="이미지" />;
                    })}
                </div>
                <div className="location-box" style={{}}>
                  <b>근무지 위치</b>
                  <div className="info-box" style={{}}>
                    {content.article.address}
                  </div>
                  <KakaoMap lati={content.article.lati} long={content.article.long} />
                </div>
              </div>
              <div className="menu-box-list">
                {content.article && !content.ismine && (
                  <>
                    <button
                      className={content.scraped ? "menu-box on" : "menu-box"}
                      onClick={scrapClicked}
                    >
                      <div className={content.scraped ? "scrap-btn-n on" : "scrap-btn-n"}></div>
                      <span className="menu-txt">스크랩</span>
                    </button>
                    <button className="menu-box" onClick={() => setReportPopup(true)}>
                      <div className="police-btn-n on"></div>
                      <span className="menu-txt">신고</span>
                    </button>
                  </>
                )}
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: content.article.title,
                        text: `[모어덴 - 병원 양도/임대 게시판]\n${content.article.title}`,
                        url: location.pathname,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
        </section>

        <section
          className="magazine__list"
          style={{ marginBottom: "136.5px", marginTop: "50px" }}
          ref={estateScroll}
        >
          <div className="inner">
            {pageInfo && (
              <ManageContent pageInfo={pageInfo} current="estate" pages={pageInfo.pages} />
            )}
          </div>
        </section>
      </div>
      {reportPopup && (
        <BoardReportPopup category="management_estate" setReportPopup={setReportPopup} bid={bid} />
      )}
    </div>
  );
}

export default EstateDetailPage;
