import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import DetectUrl from "../component/contents";
import { getAuthToken } from "api/auth/api";
import { getTokenWithPayload } from "./authUtil";

const { VITE_APP_BASE_URL, VITE_APP_PUBLIC_API_URL, VITE_APP_MEILISEARCH_BASE_URL } = import.meta
  .env;

export const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

export const parseJSON = (text) => {
  try {
    return JSON.parse(text);
  } catch (e) {
    return null;
  }
};

export const tokenUpdateInApiOption = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
  publicApi.defaults.headers.Authorization = `Bearer ${token}`;
};

export const getStorage = () => {
  return "token" in localStorage ? localStorage : sessionStorage;
};

const apiOption = {
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${
      getStorage().getItem("token") && parseJSON(getStorage().getItem("token")).access_token
    }`,
    "Content-type": "application/json",
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: "useCache",
  }),
};

export const publicApiOption = {
  ...apiOption,
  baseURL: VITE_APP_PUBLIC_API_URL,
};

const searchApiOption = {
  baseURL: VITE_APP_MEILISEARCH_BASE_URL,
  headers: {
    Authorization: `Bearer ${getStorage().getItem("searchKey")}`,
    "Content-type": "application/json",
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: "useCache",
  }),
};

export const getRefreshToken = (storage) => {
  const storedToken = storage.getItem("token");
  const tokenData = storedToken ? JSON.parse(storedToken) : {};
  const { refresh_token = "" } = tokenData || {};
  return refresh_token;
};

export const getIdToken = (storage) => {
  const storedToken = storage.getItem("token");
  const tokenData = storedToken ? JSON.parse(storedToken) : {};
  const { id_token = "" } = tokenData || {};
  return id_token;
};

export const _api = (option) => {
  const instance = axios.create(option);
  try {
    instance.interceptors.response.use(null, async (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 422)) {
        try {
          const storage = getStorage();
          const refreshToken = getRefreshToken(storage);
          const idToken = getIdToken(storage);
          const data = { refresh_token: refreshToken };
          const tokenData = await getAuthToken(data);

          if (!tokenData) {
            throw new Error("토큰 갱신에 실패했습니다.");
          }

          const decodedData = getTokenWithPayload({ id_token: idToken, ...tokenData });

          storage.clear();
          try {
            window.webkit.messageHandlers.moreden.postMessage({
              action: "token",
              data: JSON.stringify(decodedData),
            });
            // eslint-disable-next-line no-empty
          } catch (e) {}
          storage.setItem("token", JSON.stringify(decodedData));
          instance.defaults.headers.Authorization =
            error.config.headers.Authorization = `Bearer ${decodedData.access_token}`;
          return axios(error.config);
        } catch (e) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    });
    return instance;
  } catch (e) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    return null;
  }
};

export const searchApi = (() => {
  const instance = _api(searchApiOption);
  instance.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${getStorage().getItem("searchKey")}`;
    config.headers["Content-Type"] = "application/json";
    return config;
  });
  return instance;
})();

export const publicApi = (() => {
  const instance = _api(publicApiOption);
  return instance;
})();

export const api = (() => {
  const instance = _api(apiOption);
  return instance;
})();

dayjs.locale("ko");
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const dttm = (dttm) => dayjs(dttm, "YYYYMMDDHHmmss").fromNow();

export const dttmUTC = (dttm) => dayjs.utc(dttm, "YYYYMMDDHHmmss").fromNow();
export const writeDayMMDD = (writeDay) => dayjs(writeDay, "YYYYMMDDHHmmss").format("MM.DD");
export const writeDayYtoS = (writeDayYtoS) =>
  dayjs(writeDayYtoS, "YYYYMMDDHHmmss").format("YYYY.MM.DD HH:mm:ss");
export const noticeDay = (noticeDay) => dayjs(noticeDay, "YYYYMMDDHHmmss").format("YYYY.MM.DD");
export const yyyymmdd = (string) => dayjs(string).format("YYYY.MM.DD");

export const listDttm = (writeDayChange) => {
  const today = dayjs().format("YYYYMMDD");
  let writeDay;

  // 입력된 writeDayChange 형식에 따라 파싱
  if (dayjs(writeDayChange, "YYYYMMDDHHmmss", true).isValid()) {
    writeDay = dayjs(writeDayChange, "YYYYMMDDHHmmss");
  } else if (dayjs(writeDayChange).isValid()) {
    writeDay = dayjs(writeDayChange);
  } else {
    throw new Error("Invalid date format");
  }

  // 날짜 비교
  const writeDayFormatted = writeDay.format("YYYYMMDD");
  if (today !== writeDayFormatted) {
    // 당일이 아니면

    // 올해면
    if (writeDay.format("YYYY") === dayjs().format("YYYY")) {
      return writeDay.format("MM.DD");
    }
    return writeDay.format("YY.MM.DD");
  } else {
    // 당일이면
    return writeDay.format("HH:mm");
  }
};

export const commentDttm = (writeDayChange) => {
  const today = new dayjs().format("MMDD");
  const writeDay = dayjs(writeDayChange, "YYYYMMDDHHmmss").format("MMDD");

  if (today !== writeDay) {
    // 당일이 아니면
    return dayjs(writeDayChange, "YYYYMMDDHHmmss").format("MM.DD HH:mm");
  } else {
    // 당일이면
    return dayjs(writeDayChange, "YYYYMMDDHHmmss").fromNow();
  }
};
export const seminarDttm = (seminarDttm) => dayjs(seminarDttm, "YYYYMMDD").format("MM - DD");
export const ClassDttm = (ClassDttm) => dayjs(ClassDttm, "YYYYMMDD").format("MM월 DD일");
export const diffFromDate = (date1, date2) => {
  return dayjs.duration(dayjs(date1).diff(date2));
};

export const priceText = (price) => {
  if (price <= 0) return `0원`;
  if (price >= 100000000) {
    return `${(price - (price % 1000000)) / 100000000}억`;
  }
  return (
    (price > 100000000 ? Math.floor(price / 100000000) + "억" : "") +
    (Math.floor((price % 100000000) / 10000)
      ? Math.floor((price % 100000000) / 10000) + "만"
      : "") +
    (price % 10000 ? price % 10000 : "") +
    "원"
  );
};

export const preventSpaceBar = (value) => (value.includes(" ") ? false : true);

export const preventKorean = (value) => {
  const regexp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
  if (value.match(regexp)) {
    return false;
  }
  return true;
};

export const outResource = (url) => {
  window.open(url, "_blank");
};

export const scrollTopList = (dom, behavior = "", offset = 55) => {
  const scrollOption = { top: dom.current.offsetTop - offset }; // 헤더 크기 55
  if (behavior) scrollOption.behavior = behavior;
  window.scrollTo(scrollOption);
};

export const htmlTextContent = (text) => {
  return text
    ? text.split("\n").map((text, i) => (
        <div className="w-full" key={`${text}+${i}`}>
          &#8203;<DetectUrl>{text}</DetectUrl>
        </div>
      ))
    : "";
};

export const htmlTextMessage = (text) => {
  return text
    ? text.split("\n").map((text, i) => (
        <div className="w-full" key={`${text}+${i}`}>
          &#8203;<DetectUrl>{text}</DetectUrl>
        </div>
      ))
    : "";
};

export const selectStyles = {
  control: (base, { isFocused, isHovered }) => ({
    ...base,
    overflow: "auto",
    height: "34px",
    fontSize: "14px",
    zIndex: 98,
    borderColor: isFocused ? "#4a25aa" : "hsl(0, 0%, 80%)",
    boxShadow: isFocused ? "0 0 0 1px #4a25aa" : null,
    "&:hover": {
      border: null,
    },
  }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    marginTop: "5px",
    marginBottom: "5px",
    backgroundColor: isDisabled ? null : isSelected ? "#4a25aa" : isFocused ? "#eceafd" : null,
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: "16px",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 8px",
  }),
  menu: (base) => ({ ...base, zIndex: 99 }),
};

export const selectDTStyles = {
  control: (base, { isFocused, isHovered }) => ({
    ...base,
    width: "125px",
    height: "34px",
    fontSize: "14px",
    zIndex: 98,
    borderColor: isFocused ? "#4a25aa" : "#fff",
    boxShadow: isFocused
      ? "0 0 0 1px #4a25aa"
      : "0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.24)",
    "&:hover": {
      border: null,
    },
  }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    marginTop: "5px",
    marginBottom: "5px",
    backgroundColor: isDisabled ? null : isSelected ? "#4a25aa" : isFocused ? "#eceafd" : null,
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: "16px",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0 8px",
  }),
  menu: (base) => ({ ...base, zIndex: 99 }),
};

export const cutCateLength = (cate_long) => {
  if (cate_long === "임플란트/상악동거상술") return "임플란트";
  if (cate_long === "법률/의료분쟁") return "법률/분쟁";
  if (cate_long === "정량광형광기(큐레이)") return "큐레이";
  if (cate_long === "개원/치과경영") return "개원/경영";
  return cate_long;
};

export const watermark = (token) => {
  if (!token) return;
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="100"><text x="-10" y="25" fill="gray" opacity="0.15" transform="rotate(30 20,40)">${
    token.user_id || token.user_name
  }</text><text x="35" y="15" font-size="50px" fill="rgba(0,0,0,0.03)" opacity="0.15" transform="rotate(30 20,40)">${
    token.user_id || token.user_name
  }</text></svg>') repeat`;
};

export const watermarkOnImg = (token) => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200"><text x="-10" y="25" fill="black" opacity="0.15" transform="rotate(30 20,40)">${
    token.user_id || token.user_name
  }</text><text x="5" y="60" font-size="50px" fill="rgba(255,255,255,0.05)" opacity="0.15" transform="rotate(30 20,40)">${
    token.user_id || token.user_name
  }</text></svg>') repeat`;
};

export const uidEncode = (uid, nickname) => {
  const encoder = new TextEncoder();
  const data = `${uid}+${nickname}`;
  const encodedData = encoder.encode(data);
  let base64 = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let padding = 0;
  let buffer = 0;
  for (let i = 0; i < encodedData.length; i++) {
    buffer = (buffer << 8) | encodedData[i];
    padding += 8;
    while (padding >= 6) {
      const index = (buffer >> (padding - 6)) & 0x3f;
      base64 += characters[index];
      padding -= 6;
    }
  }
  if (padding === 2) {
    base64 += characters[(buffer & 0x3) << 4];
    base64 += "==";
  } else if (padding === 4) {
    base64 += characters[(buffer & 0xf) << 2];
    base64 += "=";
  }

  return base64;
};

export const uidDecode = (encodedString) => {
  const decodedData = new Uint8Array(
    Array.from(atob(encodedString)).map((char) => char.charCodeAt(0)),
  );
  const decoder = new TextDecoder("utf-8");
  const decodedString = decoder.decode(decodedData);

  return decodedString.split("+")[0];
};

export const isDigit = (text) => (!text || text.match(/^\d+$/) ? true : false);

export const cls = (...classnames) => {
  return classnames.join(" ");
};

export const isPhoneNumber = (text) =>
  text.match(/^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/) ? true : false;

export const discountedRate = (normal, discounted) =>
  normal - discounted !== 0 ? Math.floor(100 - (discounted / normal) * 100) : 0;

export const isEmail = (text) =>
  text.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i);

export const totalTime = (time) => {
  const hour = Math.floor(time / 3600);
  const minute = Math.floor((time - hour * 3600) / 60);
  const second = time - hour * 3600 - minute * 60;
  return `${hour >= 10 ? hour : "0" + hour}:${minute >= 10 ? minute : "0" + minute}:${
    second >= 10 ? second : "0" + second
  }`;
};

export function chunk(data = [], size = 1) {
  const arr = [];
  for (let i = 0; i < data.length; i += size) {
    arr.push(data.slice(i, i + size));
  }
  return arr;
}

export const getEarlierDate = (date1, date2) => {
  if (!date1 && !date2) return null;
  if (!date1 && date2) return new Date(date2);
  if (!date2 && date1) return new Date(date1);
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1 < d2 ? d1 : d2;
};

export const klassExpiredAt = (course, progress) => {
  // progress가 있으면 expiredate사용
  // 구매하지 않은 상태이면 registrationValidDays랑 leanendate 비교
  const now = dayjs(new Date());
  const learnEndAt = course.learnEndAt;
  const expireAt = progress?.expireAt;
  const validDays = dayjs().add(course.registrationValidDays, "day");
  const targetDate = expireAt
    ? dayjs(getEarlierDate(expireAt, learnEndAt))
    : dayjs(getEarlierDate(validDays, learnEndAt));

  if (targetDate.diff(now, "day") > 0) return targetDate.diff(now, "day") + "일";
  else if (targetDate.diff(now, "hours") > 0) return targetDate.diff(now, "hours") + "시간";
  else return "0시간";
};

export const openChannelTalk = () => {
  if (window.ChannelIO) {
    window.ChannelIO("openChat", undefined);
  } else {
    window.open("https://moreden.channel.io");
  }
};

export const getMention = (content) => {
  const reg = /<mark[^>]*>(.+)<\/mark>/;
  const match = content.match(reg);
  return match;
};

export const removeMention = (content) => {
  return content.replace(/<mark[^>]*>(.+)<\/mark>/, "");
};

export const loadScript = (id, src) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);
    if (existingScript) {
      resolve(); // Script is already loaded, so resolve immediately
      return;
    }

    const script = document.createElement("script");
    script.id = id;
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.body.appendChild(script);
  });
};

export const marketUrl = (url) => {
  if (!url || url === "/") {
    return import.meta.env.VITE_APP_MARKET_URL;
  }

  if (url.startsWith("/market")) {
    return `${import.meta.env.VITE_APP_MARKET_URL}${url.replace("/market", "")}`;
  }

  return `${import.meta.env.VITE_APP_MARKET_URL}${url}`;
};

export const setCookie = (name, value, expireHours) => {
  document.cookie = `${name}=${value}; path=/; expires=${dayjs()
    .add(expireHours, "h")
    .toDate()
    .toUTCString()};`;
};

export const notiIcon = (type) => {
  switch (type) {
    case "comment":
      return "ii ii-message-line-outline";
    case "article":
      return "ii ii-document-with-lines-outline";
    case "like":
      return "ii ii-like-outline";
    case "badge":
      return "ii ii-medal-outline";
    case "admin_selct":
      return "ii ii-trophy-outline";
    case "writer_select":
      return "ii ii-trophy-outline";
    default:
      return "";
  }
};
