import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getResizedImgUrl } from "utils/imageUtil";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ConsultingDentalMaster = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <div className="">
        <div className="inner" style={{ marginTop: 64 }}>
          <div className="video-info">
            <div className="video-info__right">
              <span>메디라이즈는 병원의 특성을 누구보다 잘 이해합니다!!!</span>
            </div>
          </div>
          <div className="video-detail">
            <a
              className="theater-publisher"
              href="http://www.medirise.or.kr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://moreden-img.s3.amazonaws.com/img/20231220/80F3870A-A3E9-4201-9964-848E4A8BA372/%EB%A9%94%EB%94%94%EB%9D%BC%EC%9D%B4%EC%A6%88%20%EB%A1%9C%EA%B3%A0.jpg"
                alt="게시자"
              />
              <span>(주) 메디라이즈</span>
            </a>
            <div className="detail__group">
              <div className="menu-box-list theater__menu-box-list">
                <a href="http://www.medirise.or.kr/" target="_blank" rel="noopener noreferrer">
                  <button className="menu-box home__btn">
                    <div className="home"></div>
                    <span className="menu-txt">홈페이지</span>
                  </button>
                </a>
                {navigator.share ? (
                  <button
                    className="menu-box"
                    onClick={() =>
                      navigator.share({
                        title: "[모어덴 - 메디라이즈 컨설팅]",
                        // text: adsProductState[param].title,
                        url: `https://www.moreden.co.kr${location.pathname}${location.search}`,
                      })
                    }
                  >
                    <div className="share-btn-n"></div>
                    <span className="menu-txt">공유</span>
                  </button>
                ) : (
                  <CopyToClipboard
                    text={`https://www.moreden.co.kr${location.pathname}${location.search}`}
                    onCopy={() => alert("링크를 복사하였습니다")}
                  >
                    <button className="menu-box">
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </div>
          <div className="bottom-article">
            <a class="" target="_blank" rel="noopener noreferrer" href="http://www.medirise.or.kr/">
              <img
                src={getResizedImgUrl(
                  "https://moreden-img.s3.amazonaws.com/img/20240105/8E80134A-3A97-4F63-8C66-C81A3FB650E6/%EC%BB%A8%EC%84%A4%ED%8C%85_pc_0115_2.png",
                  1500,
                )}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingDentalMaster;
