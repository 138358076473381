import { useSearchParams } from "react-router-dom";
import { dttmUTC } from "utils/util";
import { highlightedText } from "utils/searchUtil";
import { getResizedImgUrl, getImgUrl } from "utils/imageUtil";

const SearchItemArticle = ({
  tag,
  title,
  board_name,
  description,
  thumbnail,
  nickname,
  user_level,
  view,
  like,
  comment_count,
  reg_dttm,
}) => {
  const [searchParams] = useSearchParams();
  const params = {
    indexUid: searchParams.get("indexUid") || "",
    q: searchParams.get("q") || "",
  };
  const q = params.q;
  return (
    <div className="py-4">
      <div className="overflow-hidden">
        {thumbnail && (
          <figure className="float-right ml-4">
            <img
              className="w-[100px] h-[100px] object-cover"
              src={getResizedImgUrl(getImgUrl(thumbnail), 200)}
              alt=""
            />
          </figure>
        )}
        {tag && (
          <span className="inline-block mb-3 px-2 py-1 rounded-full text-[12px] bg-gray-100">
            {tag}
          </span>
        )}
        {board_name && (
          <span className="inline-block mb-3 px-2 py-1 rounded-full text-[12px] bg-gray-100">
            {board_name}
          </span>
        )}
        <h4 className="mb-1 text-[16px] font-bold">{highlightedText(title, q)}</h4>
        <p className="text-[14px] text-gray-500 line-clamp-2">{highlightedText(description, q)}</p>
      </div>
      <div className="mt-3 flex items-center justify-between">
        <p className="text-[13px]">
          {user_level > 0 && <span className="mr-1">Lv.{user_level}</span>}
          {nickname}
          {/* FIXME: Nickname 공통 컴포넌트로 대체해야 합니다.
          <Nickname
            uid={item.userUid}
            unick={item.unick}
            level={item.level}
            isOwner={item.is_owner}
          /> */}
          <span className="inline-block mx-1 text-secondary-500">&middot;</span>
          <span className="text-secondary-500">{dttmUTC(reg_dttm)}</span>
        </p>
        <p className="text-[12px] text-secondary-500">
          {view && (
            <span className="mr-3">
              <i className="ii ii-eye text-[16px] text-secondary-500"></i> {view}
            </span>
          )}
          <span className="mr-3">
            <i className="ii ii-like-outline text-[16px] text-secondary-500"></i>
            {like}
          </span>
          <span className="mr-3">
            <i className="ii ii-message-dots-outline text-[16px] text-secondary-500"></i>
            {comment_count}
          </span>
        </p>
      </div>
    </div>
  );
};
export default SearchItemArticle;
