/* eslint-disable no-empty */
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StyledInput from "component/shared/StyledInput";
import StyledCheckBox from "component/shared/StyledCheckBox";
import { toast } from "react-toastify";
import { Agreement } from "types/agreement";
import { checkDuplicateId } from "api/auth/api";
import ChannelTalk from "component/ChannelTalk";
import StyledButton from "component/shared/StyledButton";
import { toastOptions } from "component/shared/StyledToastContainer";
import { preventKorean, preventSpaceBar } from "utils/util";
import SignupAgreement from "component/agreement/SignupAgreement";
import Logo from "component/Logo";

type GenderCd = "M" | "F";
type LoginType = "kakao" | "apple";

interface SignupState {
  birthday: string;
  ci: string;
  genderCd: GenderCd;
  name: string;
  phone: string;
  id?: string;
  loginType?: LoginType;
  di?: string;
}

const ID_PLACEHOLDER = "아이디를 입력해주세요. (4글자 이상)";
const ID_LENGTH_ERROR_MSG = "아이디는 4글자 이상 입력해주세요.";
const ID_INVALID_ERROR_MSG = "4 ~ 30자의 영문, 숫자와 특수문자 '_'만 사용 가능합니다";
const ID_DUPLICATE_ERROR_MSG = "이미 사용중인 아이디입니다";
const PW_PLACEHOLDER = "비밀번호를 입력해주세요. (4글자 이상)";
const PW_LENGTH_ERROR_MSG = "비밀번호는 4글자 이상 입력해주세요.";
const PW_MATCH_ERROR_MSG = "비밀번호가 일치하지 않습니다.";
const PW2_PLACEHOLDER = "비밀번호를 한번 더 입력해주세요.";
const AGREEMENT_ERROR_MSG = "약관을 불러오는 중 오류가 발생했습니다.";
const AGREEMENT_INVALID_MSG = "필수 약관에 동의해주세요.";

const isUsernameValid = (username: string) => {
  const usernamePattern = /^[a-zA-Z0-9_]{4,30}$/;
  return usernamePattern.test(username);
};

const Signup = () => {
  const navigate = useNavigate();
  const { state }: { state: SignupState } = useLocation();
  const [username, setUsername] = useState<string>(state?.id || "");
  const [isUsernameDuplicate, setIsUsernameDuplicate] = useState<boolean | null>(null);
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [agreements, setAgreements] = useState<Agreement>({});

  const idInput = useRef<HTMLInputElement>(null);
  const pw1Input = useRef<HTMLInputElement>(null);
  const pw2Input = useRef<HTMLInputElement>(null);

  const isRequiredAgreementValid = (agreement: Agreement): boolean => {
    return Object.values(agreement).every((item) => !item.isRequired || item.isAgreed);
  };

  const alert = (msg: string) => {
    toast.error(msg, { ...toastOptions, position: "bottom-left" });
  };

  const checkFormValid = () => {
    if (username.length < 4) {
      alert(ID_LENGTH_ERROR_MSG);
      idInput.current?.focus();
      return Promise.reject();
    }

    if (!isUsernameValid(username)) {
      alert(ID_INVALID_ERROR_MSG);
      idInput.current?.focus();
      return Promise.reject();
    }

    if (isUsernameDuplicate === null) {
      checkUsernameDuplicate();
      return Promise.reject();
    }

    if (isUsernameDuplicate === true) {
      alert(ID_DUPLICATE_ERROR_MSG);
      idInput.current?.focus();
      return Promise.reject();
    }

    if (password.length < 4) {
      alert(PW_LENGTH_ERROR_MSG);
      pw1Input.current?.focus();
      return Promise.reject();
    }

    if (password !== password2) {
      alert(PW_MATCH_ERROR_MSG);
      pw2Input.current?.focus();
      return Promise.reject();
    }

    if (Object.keys(agreements).length === 0) {
      alert(AGREEMENT_ERROR_MSG);
      return Promise.reject();
    }

    if (!isRequiredAgreementValid(agreements)) {
      alert(AGREEMENT_INVALID_MSG);
      return Promise.reject();
    }

    return Promise.resolve();
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toLowerCase();

    if (preventSpaceBar(e.target.value) && preventKorean(e.target.value)) {
      setUsername(e.target.value);
      setIsUsernameDuplicate(null);
    }
  };

  const checkUsernameDuplicate = async () => {
    if (!isUsernameValid(username)) {
      return;
    }

    const res = await checkDuplicateId({ id: username });

    if (res) {
      setIsUsernameDuplicate(res.data.duplicate);
    }
  };

  const save = async () => {
    await checkFormValid();

    navigate("/recommender", {
      state: {
        ...state,
        id: username,
        password: password,
        marketing: agreements["marketing"].isAgreed ? "Y" : "N",
      },
    });
  };

  useEffect(() => {
    idInput.current && idInput.current.focus();
  }, []);

  useEffect(() => {
    if (!state || !state.phone) {
      navigate("/phoneVerify", {
        state: {
          loginType: "id",
        },
        replace: true,
      });
    }
  });

  return (
    <div className="container footer-none min-h-screen lg:bg-secondary-100">
      <div className="wrapper">
        <div className="flex justify-center pt-10">
          <Logo />
        </div>
        <div className="bg-white mx-auto max-w-[450px] flex flex-col gap-5 mt-5 lg:border-primary-800 lg:border-[1px] lg:rounded lg:p-5 px-5">
          <div className="text-lg font-semibold text-center">회원가입을 시작할게요!</div>
          <div className="flex flex-col gap-3">
            <StyledInput
              maxLength={30}
              ref={idInput}
              placeholder={ID_PLACEHOLDER}
              name="username"
              value={username}
              onChange={handleUsernameChange}
              isInvalid={!isUsernameValid(username) || isUsernameDuplicate === true}
              invalidMsg={(() => {
                if (username.length < 4) return ID_LENGTH_ERROR_MSG;
                if (!isUsernameValid(username)) return ID_INVALID_ERROR_MSG;
                if (isUsernameDuplicate) return ID_DUPLICATE_ERROR_MSG;
              })()}
              onBlur={checkUsernameDuplicate}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  pw1Input.current?.focus();
                }
              }}
            />
            <StyledInput
              ref={pw1Input}
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              placeholder={PW_PLACEHOLDER}
              isInvalid={password.length < 4}
              invalidMsg={PW_LENGTH_ERROR_MSG}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  pw2Input.current?.focus();
                }
              }}
            />
            <StyledInput
              ref={pw2Input}
              name="password2"
              type={isPasswordVisible ? "text" : "password"}
              value={password2}
              placeholder={PW2_PLACEHOLDER}
              isInvalid={password !== password2}
              invalidMsg={PW_MATCH_ERROR_MSG}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
            />
            <div className="ml-auto">
              <StyledCheckBox
                checked={isPasswordVisible}
                onChange={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <div className="text-sm ml-2">비밀번호 표시</div>
              </StyledCheckBox>
            </div>
          </div>
          <SignupAgreement agreements={agreements} setAgreements={setAgreements} />
          <StyledButton onClick={save}>다음</StyledButton>
        </div>
      </div>
      <ChannelTalk />
    </div>
  );
};

export default Signup;
