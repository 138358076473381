import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { tokenState } from "../../state";
import { api, getStorage } from "../../utils/util";
import LicenseVerify from "./LicenseVerify";
import VerifyPending from "./VerifyPending";

const Verify = () => {
  const [token, setToken] = useRecoilState(tokenState);

  useEffect(() => {
    api.get("/user/token").then((res) => {
      const t = { ...token, ...res.data };
      getStorage().setItem("token", JSON.stringify(t));
      setToken(t);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return token.user_status === "pending" ? <VerifyPending /> : <LicenseVerify />;
};

export default Verify;
