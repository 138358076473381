import { api, chunk, uidEncode } from "../../utils/util";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Blur from "../../page/user/Blur";
import { getStaticImgUrl } from "utils/imageUtil";

const Ranking = ({ user }) => {
  const navigate = useNavigate();

  const { data: pickInfo, isLoading: pickInfoLoading } = useQuery(
    "forumListPick",
    () => api.get("/forum/list_pick").then((res) => res.data),
    {
      enabled: !!user,
    },
  );

  return (
    <div className="w-[384px] flex flex-col">
      <span className="flex justify-between items-center pb-3">
        <b className="text-[20px]">명의 전당</b>
        <div className="swiper__button__box">
          <div className="swiper-button-prev1">
            <i class="ii ii-caret-left text-white"></i>
          </div>
          <div className="swiper-button-next1">
            <i className="ii ii-caret-right text-white"></i>{" "}
          </div>
        </div>
      </span>
      <div className="h-[180px]">
        <Blur>
          <div className="hard-blur h-full">
            <Swiper
              modules={[Autoplay, Navigation]}
              className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events h-full"
              spaceBetween={10}
              slidesPerView={1}
              slidesPerGroup={1}
              setWrapperSize={true}
              loop={true}
              autoplay={true}
              navigation={{
                nextEl: ".swiper-button-next1",
                prevEl: ".swiper-button-prev1",
              }}
            >
              {!pickInfoLoading &&
                pickInfo &&
                chunk(pickInfo.forum_ranking, 5).map((rankerChunk, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <div className="flex flex-col items-center justify-between h-full">
                        {rankerChunk.map((ranker, index) => (
                          <div
                            className="w-full h-[20%] flex justify-between items-center cursor-pointer group"
                            key={index}
                            onClick={() =>
                              navigate(`/profile/${uidEncode(ranker.userUid, ranker.unick)}`)
                            }
                          >
                            <div className="text-[15px] text-gray-600 flex">
                              <img
                                className="w-[20px] mr-1"
                                src={getStaticImgUrl(`rank/rank_${5 * idx + index + 1}.svg`)}
                                alt=""
                              ></img>
                              <div className="group-hover:text-primary-800">{ranker.unick}</div>
                            </div>
                            <div className="text-primary-800 text-[16px]">{ranker.forum_point}</div>
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </Blur>
      </div>
    </div>
  );
};

export default Ranking;
