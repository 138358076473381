import React from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import HotContent from "../../component/board/all/HotContent";
import Loading from "../../component/Loading";
import FilterBox from "../../component/management/FilterBox";
import { tokenState } from "../../state";
import { api, watermark } from "../../utils/util";
import DoctorContent from "../../component/recruit/doctorContent";
import { useQuery } from "react-query";
import Banner from "../../component/advertisment/banner";
import { Link } from "react-router-dom";
import { getStaticImgUrl } from "utils/imageUtil";

function DoctorPage() {
  const location = useLocation();
  const token = useRecoilValue(tokenState);
  const urlParams = new URLSearchParams(location.search);
  const major = urlParams.get("major") || "";
  const workingTime = urlParams.get("working_time") || "";
  const loc1 = urlParams.get("loc1") || "";
  const loc2 = urlParams.get("loc2") || "";
  const netWage = urlParams.get("wage") || "";
  const q = urlParams.get("q") || "";
  const page = parseInt(urlParams.get("page")) || 1;
  const params = { workingTime, major, loc1, loc2, netWage, q, page };
  const filterParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value));

  const { data: pageInfo, isLoading: pageInfoLoading } = useQuery(
    ["managementRecruitList", filterParams],
    () => api.get("/management/recruit/list", { params: filterParams }).then((res) => res.data),
  );

  if (pageInfoLoading || !pageInfo) return <Loading current="management" />;
  return (
    <div className="">
      <div className="inner">
        <Link to="/chicruiting">
          <img
            src={getStaticImgUrl("banner/chicruiting1.png")}
            alt=""
            style={{ width: "100%", borderRadius: "10px" }}
          />
        </Link>
      </div>
      <div className="inner recruit_banner">
        <Banner page="DoctorPage" name="pc_DoctorPage_top" />
      </div>
      <FilterBox />
      <div className="wrapper" style={{ background: watermark(token), backgroundRepeat: "space" }}>
        {pageInfo.hot_items && <HotContent current="recruit" hot_items={pageInfo.hot_items} />}

        <section className="magazine__list" style={{ marginBottom: "136.5px" }}>
          {pageInfo && (
            <div className="inner relative">
              <div className="text-secondary-600 absolute -top-11">
                구인공고 게시판에는 치과의사 구인글 작성만 가능합니다.
              </div>
              <DoctorContent pageInfo={pageInfo} pages={pageInfo.pages} />
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default DoctorPage;
