import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/util";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Blur from "../../page/user/Blur";
import CensoredData from "page/user/CensoredData";
import { getResizedImgUrl, getStaticImgUrl } from "utils/imageUtil";

const HotForum = () => {
  const navigate = useNavigate();
  const { data: forumHotState, isLoading: forumHotStateLoading } = useQuery("forumHot", () =>
    api?.get("forum/hot").then((res) => res.data),
  );

  return (
    <div className="col-span-2">
      <span className="flex justify-between items-center pb-3">
        <b className="text-[20px]">인기 임상포럼</b>
        <Link className="cursor-pointer text-[13px] text-gray-600 hover:underline" to="/forum">
          + 더보기
        </Link>
      </span>
      <div className="h-[180px]">
        <Blur>
          <div className="hard-blur h-full">
            {!forumHotStateLoading && forumHotState && !!forumHotState.length && (
              <Swiper
                modules={[Autoplay]}
                className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
                slidesPerView={4}
                spaceBetween={24}
                loop={true}
                parallax={true}
                slideToClickedSlide={true}
                autoplay={true}
              >
                {forumHotState.map((item: any, idx: number) => (
                  <SwiperSlide key={idx} style={{ cursor: "pointer" }} className="swiper-slide">
                    <div
                      className="group rounded-md overflow-hidden"
                      onClick={() => navigate(`/forum/article/${item.bid}?page=1`)}
                    >
                      <img
                        className="w-[180px] h-[180px]"
                        src={getResizedImgUrl(
                          item.thumbnail
                            ? item.thumbnail
                            : getStaticImgUrl(`category/forum/default.png`),
                          416,
                        )}
                        alt=""
                      />
                      <div className="w-full h-[84px] p-[10px] rounded-b-md absolute bottom-0 bg-black/50 group-hover:bg-black/70">
                        <div className="text-[15px] text-white font-semibold ellipsis w-full">
                          <CensoredData>{item.title}</CensoredData>
                        </div>
                        <div className="text-[13px] text-gray-300 mt-3">by {item.unick}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </Blur>
      </div>
    </div>
  );
};

export default HotForum;
