import { Link } from "react-router-dom";
import { marketUrl } from "utils/util";
import { getStaticImgUrl } from "utils/imageUtil";

export default function CommunityAccessDenied() {
  return (
    <div className="container full absolute z-[500] top-0">
      <section className="member">
        <div className="member__wrap">
          <div className="logo-box">
            <img src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_login_logo.svg`} alt="로고" />
          </div>

          <div className="member__con">
            <div className="join-form">
              <div className="success-box">
                <div className="logo">
                  <img
                    className="inline-block"
                    src={getStaticImgUrl("klass/order/complete_icon.svg")}
                    alt="이미지"
                  />
                </div>
                <div className="main-txt">마켓 전용 계정으로 접속하셨습니다.</div>
                <div className="sub-txt">모어덴 계정으로 접속하시면 이용 가능합니다.</div>
                <div className="other-login-btn-box">
                  <Link to={marketUrl("/")} className="other-login-btn">
                    마켓으로 이동하기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
