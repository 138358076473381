import { useCallback, useEffect, useState } from "react";
import { cls } from "utils/util";

export default function Timer({ targetDate, timerUnitStyle, simple }) {
  const [timeRemaining, setTimeRemaining] = useState(targetDate - Date.now());

  const timer = useCallback(() => {
    setTimeout(() => {
      setTimeRemaining(() => targetDate - Date.now());
      if (targetDate <= Date.now()) {
        setTimeRemaining(0);
        return;
      }
      requestAnimationFrame(timer);
    }, 100);
  }, [targetDate]);

  useEffect(() => {
    timer();
  }, [timer]);

  const getDate = (time) => {
    return Math.floor((time % (1000 * 60 * 60 * 24 * 10)) / (1000 * 60 * 60 * 24)); // 최대 9일까지 표시
  };
  const getHours = (time) => {
    return Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + getDate(time) * 24;
  };
  const getMinutes = (time) => {
    return Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  };
  const getSeconds = (time) => {
    return Math.floor((time % (1000 * 60)) / 1000);
  };
  const getMilliSeconds = (time) => {
    return Math.floor(time % 1000);
  };

  return (
    <>
      {!simple ? (
        <div className="flex items-center space-x-1">
          <TimerUnit
            counter={getHours(timeRemaining)}
            maxNum={999}
            timerUnitStyle={timerUnitStyle}
          />
          <b className="text-xl text-secondary-900">:</b>
          <TimerUnit counter={getMinutes(timeRemaining)} timerUnitStyle={timerUnitStyle} />
          <b className="text-xl text-secondary-900">:</b>
          <TimerUnit counter={getSeconds(timeRemaining)} timerUnitStyle={timerUnitStyle} />
          <b className="text-xl text-secondary-900">.</b>
          <TimerUnit
            counter={Math.floor(getMilliSeconds(timeRemaining) / 100)}
            maxNum={9}
            hasTwoDigits={false}
            timerUnitStyle={timerUnitStyle}
          />
        </div>
      ) : (
        <div className="flex items-center">
          <span className="font-semibold">
            {getHours(timeRemaining) < 10 ? "0" : ""}
            {getHours(timeRemaining)}
          </span>
          <b className="text-lg">:</b>
          <span className="font-semibold">
            {getMinutes(timeRemaining) < 10 ? "0" : ""}
            {getMinutes(timeRemaining)}
          </span>
          <b className="text-lg">:</b>
          <span className="font-semibold">
            {getSeconds(timeRemaining) < 10 ? "0" : ""}
            {getSeconds(timeRemaining)}
          </span>
          <b className="text-lg">:</b>
          <span className="font-semibold">{Math.floor(getMilliSeconds(timeRemaining) / 100)}</span>
        </div>
      )}
    </>
  );
}

function TimerUnit({ counter, maxNum = 59, hasTwoDigits = true, timerUnitStyle = {} }) {
  return (
    <ul className="timer-unit" style={timerUnitStyle}>
      {[...Array(maxNum + 1).keys()].map((num) => (
        <li
          key={num}
          className={cls(
            num === counter ? "front" : "",
            num === (counter + 1) % (maxNum + 1) ? "back" : "",
            num > maxNum ? "hidden" : "",
          )}
        >
          <div className="upper">
            <div className="num">
              <span>
                {hasTwoDigits && num < 10 ? "0" : ""}
                {num}
              </span>
            </div>
          </div>
          <div className="lower">
            <div className="num">
              <span>
                {hasTwoDigits && num < 10 ? "0" : ""}
                {num}
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
