import { getStaticImgUrl } from "utils/imageUtil";

export const ServicePolicy = () => {
  return (
    <div className="border-solid border-[1px] border-gray-300 w-full h-[150px] my-3 overflow-auto">
      <div className="text-sm leading-5 m-2">
        제1조 목적 본 약관은 ㈜데니어 (이하 “회사”)가 제공하는 모어덴 및 모어덴 관련 제반 서비스의
        이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로
        합니다. 제2조 용어의 정의 본 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다. 1.
        “서비스”라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이
        회원이 이용할 수 있는 회사 제공의 모든 서비스를 의미합니다. 2. “회원”이라 함은 회사에
        개인정보를 제공하여 회원등록을 한 자로서, 회사와 서비스 이용계약을 체결하고 회원
        아이디(ID)를 부여받은 자를 말합니다. 3. “아이디(ID)”라 함은 회원의 식별과 서비스 이용을
        위하여 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다. 4. “비밀번호”라 함은
        회원이 부여받은 아이디와 일치되는 회원임을 확인하고 회원의 개인정보를 보호하기 위하여 회원
        자신이 정한 문자 또는 숫자의 조합을 의미합니다. 5. “콘텐츠”라 함은 서비스에 게재된
        문자ㆍ부호ㆍ음성ㆍ음향ㆍ이미지 또는 영상 등으로 표현된 자료 또는 정보를 말합니다. 6.
        “탈퇴”라 함은 회원이 이용계약을 해지하는 것을 말합니다. 7. 그 외 본 약관에서 정의하지 않은
        용어는, 개별서비스에 대한 별도 약관 또는 이용규정 등에서 정의합니다. 제3조 약관 등의 명시와
        설명 및 개정 1. 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에 게시하여
        공지하고, 본 약관에 동의한 회원 모두에게 그 효력이 발생합니다. 2. 회사는 관련 법령을
        위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 3. 회사가 본 약관을 개정할 경우에는
        적용일자 및 개정사유를 명시하여 회사의 공지사항에 그 적용일자 7일 전에 공지합니다. 단,
        회원에게 불리하거나 중대한 사항의 변경일 경우에는 적용일자 및 변경사유를 명시하여 현행
        약관과 함께 그 개정 약관의 적용일자 30일 전에 공지합니다. 4. 회원이 개정약관의 적용에
        동의하지 않는 경우, 이용 계약을 해지함으로써 거부 의사를 표시할 수 있습니다. 단, 30일 내에
        거부 의사 표시를 하지 않을 경우 약관에 동의한 것으로 간주합니다. 5. 회원은 약관 일부분만을
        동의 또는 거부할 수 없습니다. 제4조 이용 계약의 체결 1. 이용 계약은 회원이 되고자 하는
        자(이하 “가입신청자”)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가
        이러한 신청에 대하여 승낙함으로써 체결됩니다. 2. 회원가입신청 양식에 기재하는 모든 회원
        정보는 실제 데이터인 것으로 간주하며 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수
        없으며, 서비스 이용의 제한을 받을 수 있습니다. 3. 회사는 가입신청자 중 치과의사/치대생의
        자격이 확인되는 자에 한해 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에
        해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용 계약을 해지할 수 있습니다. 1)
        치과의사/치대생의 자격이 확인되지 않는 경우 2) 가입신청자가 본 약관에 의하여 이전에
        회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함. 3)
        실명이 아니거나 타인의 명의를 이용한 경우 4) 허위의 정보를 기재하거나, 회사가 제시하는
        내용을 기재하지 않은 경우 5) 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
        제반 사항을 위반하며 신청하는 경우 6) 부정한 용도 혹은 영리를 추구할 목적으로 신청한 경우 4.
        제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을
        요청할 수 있습니다. 5. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
        있는 경우에는 승낙을 유보할 수 있습니다. 6. 회사는 회원에 대해 회사정책에 따라 등급별로
        구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다. 제5조
        개인정보보호 회사는 “정보통신망법” 등 관계법령이 정하는 바에 따라 회원등록정보를 포함한
        회원의 개인정보를 보호하기 위하여 노력을 합니다. 회원의 개인정보보호에 관하여 관계법령 및
        회사가 정하는 개인정보처리방침에 정한 바에 따릅니다. 제6조 회원정보의 변경 1. 회원은
        정보수정 화면을 통해 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 2. 회원은
        회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타
        방법으로 회사 그 변경사항을 알려야 합니다. 3. 회원이 제2항의 변경사항을 회사에 알리지 않아
        발생한 불이익에 대하여 회사는 책임지지 않습니다. 제7조 회원의 아이디 및 비밀번호의 관리에
        대한 의무 1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가
        이용하도록 하여서는 안 됩니다. 2. 회사는 회원의 아이디가 개인정보 유출 우려가 있거나,
        반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 관계자로 오인할 우려가 있는 경우, 해당
        아이디의 이용을 제한할 수 있습니다. 3. 회원은 아이디 및 비밀번호가 도용되거나 제3자가
        사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다. 4.
        제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에
        따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다. 제8조 회원에 대한 통지 1.
        회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항 혹은 전자 우편 등의
        방법으로 회원에게 제공할 수 있습니다. 2. 회사는 불특정다수 회원에 대한 통지를 하는 경우
        공지사항 혹은 팝업창으로 게시함으로써 개별 통지에 갈음할 수 있습니다. 제9조 회원의 의무 1.
        회원은 다음 행위를 하여서는 안 됩니다. 1) 회원신청 또는 변경 시 개인정보를 허위, 누락, 오기,
        도용 허위내용의 기재 2) 타인의 개인정보 및 계정을 수집, 저장, 공개 이용하는 행위 3) 자신과
        타인의 개인정보를 제3자에게 공개, 양도하는 행위 4) 다중 계정을 생성 및 이용하는 행위 5)
        회사에 게시된 정보의 변경 6) 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시 7)
        임의로 콘텐츠를 다운로드하거나 저장, 무단 배포 등 회사와 기타 제3자의 저작권 등 지적재산권에
        대한 침해 8) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 9) 회사의 모든
        재산에 대한 침해 행위 10) 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는
        정보를 회사의 사이트에 공개, 게시, 전자우편 전송 또는 기타 방법으로 타인에 유포하는 행위 11)
        회사의 사전승낙 없이 서비스를 이용하여 상품의 판매 등 영업활동이나 타사 서비스의 홍보, 회원
        유치를 하는 행위 12) 회사가 제공하는 콘텐츠를 공공장소 및 영리를 목적으로 하는 영업장, 매장
        등에서 공중에 대한 공개 재현용 등 사적인 이용을 벗어난 범위에서의 이용하는 행위 13) 서비스
        내부 정보 일체를 허가 없이 이용, 변조, 삭제 및 외부로 유출하는 행위 14) 기타 현행법에
        어긋나거나 부적절하다고 판단되는 행위 2. 회원은 관계법령, 본 약관의 규정, 이용안내 및
        콘텐츠와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의
        업무에 방해되는 행위를 하여서는 안 됩니다. 3. 서비스를 통해 제공되는 모든 콘텐츠는
        저작권법의 보호를 받으며 회원이 본 조항에서 규정한 사항을 위반할 경우 이용이 중지되고 관련
        법령에 의해 처벌받을 수 있습니다. 4. 회원은 내용별로 회사가 서비스 공지사항에 게시하거나
        별도로 공지한 이용 제한사항을 준수하여야 합니다. 5. 회원이 1항에 해당하는 행위를 할 경우,
        회사는 다음과 같은 조치를 영구적으로 취할 수 있습니다. 1) 회원의 서비스 이용 권한, 자격,
        혜택 제한 및 회수 2) 회원과 체결된 이용계약을 회원의 동의나 통보 없이 파기 3) 회원의 콘텐츠,
        이용기록을 임의로 삭제, 중단, 변경 4) 그 외 회사가 필요하다고 판단되는 조치 6. 회사는
        1항부터 전항까지로 인해 발생한 피해에 대해 어떠한 책임을 지지 않으며, 회원은 귀책사유로 인해
        발생한 모든 손해를 배상할 책임이 있습니다. 제10조 탈퇴 및 자격 상실 등 1. 회원은 회사에
        언제든지 서비스 탈퇴를 요청할 수 있으며 회사는 즉시 회원 서비스 탈퇴를 처리합니다. 2. 탈퇴
        처리가 완료되었더라도 회원이 게시한 게시물은 삭제되지 않습니다. 3. 회원이 다음 각 호의
        사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지, 가입취소 시킬 수 있습니다. 1) 가입신청
        시에 허위내용을 등록한 경우 2) 회사의 서비스 이용대금, 기타 회사의 서비스 이용에 관련하여
        회원이 부담하는 채무를 기일에 이행하지 않는 경우 3) 다른 사람의 회사의 서비스이용을
        방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 4) 회사를 이용하여 법령
        또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 5) 회원이 제9조 회원의 의무
        1항에 해당하는 행위를 할 경우 4. 회사가 회원자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상
        반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 박탈할 수
        있습니다. 5. 회사가 회원자격을 박탈할 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를
        통지하고, 회원 등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다. 6.
        회사는 1항부터 전항까지로 인해 발생한 피해에 대해 어떠한 책임을 지지 않습니다. 제11조 회사의
        의무 1. 회사는 회원의 가입을 이 약관에 따라 승낙하고 회원의 변경, 취소가 없는 한 즉시
        서비스를 제공합니다 2. 회사는 법령과 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에
        따라 성실하게 하여야 합니다. 3. 회사는 회원이 안전하게 콘텐츠를 이용할 수 있도록
        개인정보(신용정보 포함) 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
        준수합니다. 4. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고
        인정할 경우에는 이를 지체없이 처리합니다. 회원이 제기한 의견이나 불만사항에 대해서는
        게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다. 제12조 서비스의
        제공 1. 회사는 회원에게 아래와 같은 서비스를 제공합니다. 1) 게시판 서비스 2) Q&A 서비스 3)
        병원경영 서비스 4) 기타 회원에게 유용한 부가 서비스나 회사가 정하는 서비스 * 일부 서비스는
        회원 구분에 따라 서비스 이용이 제한될 수 있습니다. 2. 회사는 서비스를 일정 범위로 분할하여
        각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을
        사전에 공지합니다. 3. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 4. 회사에서
        제공하는 서비스는 기본적으로 무료입니다. 다만, 회사가 정한 별도의 유료 서비스에 대하여는
        그러하지 아니합니다. 유료서비스의 이용에 대한 사항은 회사가 별도로 정한 서비스약관 및 정책
        또는 운영규칙에 따릅니다. 5. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
        통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
        이 경우 회사는 제8조 회원에 대한 통지에 정한 방법으로 회원에게 통지합니다. 다만, 회사가
        사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다. 6. 회사는
        서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에
        공지한 바에 따릅니다. 제13조 서비스의 변경 1. 회사는 상당한 이유가 있는 경우에 운영상,
        기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다. 2. 서비스의
        내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및
        제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다. 3. 회사는 무료로
        제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
        있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
        제14조 콘텐츠의 저작권 및 권리 1. 회사가 작성한 콘텐츠에 대한 권리는 회사에게 귀속되며,
        회원이 서비스 내에 게시한 콘텐츠의 저작권은 해당 콘텐츠의 저작자에게 귀속됩니다. 2. 회원이
        서비스 내에 게시하는 콘텐츠는 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며,
        해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우,
        회사는 저작권법 규정을 준수하며, 회원은 언제든지 서비스 내 관리기능을 통해 해당 콘텐츠에
        대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다. 3. 회사는 제2항 이외의
        방법으로 회원의 콘텐츠를 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에
        회원의 동의를 얻어야 합니다. 4. 회원의 콘텐츠가 “정보통신망법” 및 “저작권법” 등 관련법에
        위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 콘텐츠의 게시중단 및
        삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다. 5. 회사는 전항에
        따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
        관련법에 위반되는 경우에는 관련법에 따라 해당 콘텐츠에 대해 임시조치 등을 취할 수 있습니다.
        제15조 저작권의 귀속 1. 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 회원의
        콘텐츠 및 제휴 계약에 따라 제공된 저작물 등은 제외합니다. 2. 회원은 서비스를 이용함으로써
        얻은 정보를 회사의 사전 승낙 없이 임의 가공, 판매, 복제, 송신, 출판, 배포, 방송, 기타 방법에
        의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다. 제16조 정보의 제공 및
        광고의 게재 1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나
        전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보
        및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수
        있습니다. 2. 회사는 서비스의 제공을 위해 서비스 내부에 광고를 게재할 수 있습니다. 3. 회사는
        이용자의 이용 기록을 활용한 광고를 게재할 수 있습니다. 4. 회사는 회원이 광고성 정보 수신에
        동의할 경우, 서비스 내부 알림 수단과 회원의 연락처를 이용하여 광고성 정보를 발신할 수
        있습니다. 5. 회원은 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정,
        제한하는 등의 조치를 취하지 않습니다. 6. 회사가 제공하는 서비스 내에는 다양한 배너와 링크를
        포함하고 있습니다. 많은 경우 타 사이트의 페이지와 연결되어 있으며, 이는 광고주와의
        계약관계에 의하거나 제공받은 콘텐츠의 출처를 밝히기 위한 조치입니다. 서비스 내에 포함되어
        있는 링크를 클릭하여 타 사이트의 페이지로 옮겨갈 경우 해당 사이트의 개인정보보호정책은
        회사와 무관하므로 새로 방문한 사이트의 정책을 검토하셔야 합니다. 7. 회사는 광고 게재 및
        동의된 광고성 정보의 발신으로 인해 발생한 피해에 대해 어떠한 책임을 지지 않습니다. 제17조
        계약 해제, 해지 등 1. 회원이 서비스 이용 계약을 해지하고자 하는 때에는 언제든지 회원 정보
        관리 메뉴에서 회사가 정한 절차에 따라 회원의 아이디를 삭제하고 탈퇴할 수 있습니다. 2. 회원이
        제11조의 규정을 위반한 경우 회사는 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스
        운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임도 물을 수 있습니다. 3. 회원이 서비스를
        이용하는 도중, 연속하여 일년 동안 서비스를 이용하기 위해 회사의 서비스에 로그인한 기록이
        없는 경우 회사는 회원의 회원자격을 박탈할 수 있습니다. 제18조 책임 제한 1. 회사는 천재지변
        또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한
        책임이 면제됩니다. 2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을
        지지 않습니다. 3. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성
        등의 내용에 관하여는 책임을 지지 않습니다. 4. 회사는 회원 간 또는 회원과 제3자 상호간에
        서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 5. 회사는 무료로 제공되는
        서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다. 제19조 준거법 및
        재판 관할 1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다. 2. 회사와 회원간
        발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우 거소를 관할하는
        지방법원의 전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 아니한 경우의
        관할법원은 민사소송법에 따라 정합니다. 3. 해외에 주소나 거소가 있는 회원의 경우 회사와
        회원간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로
        합니다. [부칙] 제1조 시행일 본 약관은 2021년 3월 15일부터 시행합니다.{" "}
      </div>
    </div>
  );
};

export const PrivacyPolicy = () => {
  return (
    <div className="w-full my-3 overflow-hidden">
      <iframe
        className="w-full border-solid border-[1px] border-gray-300"
        src={getStaticImgUrl("html/privacy_policy_2024.html")}
      ></iframe>
    </div>
  );
};
