import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { api, cls, marketUrl } from "../../utils/util";
import NoticesBox from "./NoticesBox";
import AuthUserBox from "./AuthUserBox";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";
import Popup from "../Popup";
import { isMarketOnlyUser } from "utils/marketUtil";
import { getStaticImgUrl } from "utils/imageUtil";

const UserBox = () => {
  const token = useRecoilValue(tokenState);
  const [logoutPopup, setLogoutPopup] = useState(false);

  return (
    <div className="w-full flex flex-col justify-between">
      {logoutPopup && <LogoutPopup setLogoutPopup={setLogoutPopup} />}
      <div className="h-[150px] rounded-md border-solid border-[1px] border-primary-800 overflow-hidden">
        {token ? (
          token.user_type ? (
            isMarketOnlyUser(token.user_type) ? (
              <MarketUserBox setLogoutPopup={setLogoutPopup} />
            ) : (
              <AuthUserBox setLogoutPopup={setLogoutPopup} />
            )
          ) : (
            <UnAuthUserBox
              isPending={token.user_status === "pending"}
              setLogoutPopup={setLogoutPopup}
            />
          )
        ) : (
          <UnloginBox />
        )}
      </div>
      <NoticesBox />
    </div>
  );
};

export default UserBox;

const UnloginBox = () => {
  return (
    <div className="p-4 flex flex-col items-center justify-between h-full">
      <img src={getStaticImgUrl("rank/unlogin_icon.svg")} alt="logo" />
      <div className="my-2 text-[15px]">모어덴에 오신 것을 환영합니다</div>
      <Link
        to="/login"
        className="bg-primary-800 hover:bg-primary-900 text-center text-white w-full py-1 rounded-md"
      >
        <span>로그인</span>
      </Link>
    </div>
  );
};

const MarketUserBox = ({
  setLogoutPopup,
}: {
  setLogoutPopup: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex flex-col items-center justify-between h-full pt-5 text-[15px]">
      <img src={getStaticImgUrl("rank/unlogin_icon.svg")} alt="logo" />
      <span className="my-2">마켓 전용 계정으로 로그인 하셨습니다.</span>
      <div className={cls("bg-gray-50 grid w-full grid-cols-2")}>
        <button
          className="text-center font-medium hover:bg-gray-100 py-2"
          onClick={() => setLogoutPopup(true)}
        >
          로그아웃
        </button>
        <Link
          className="mypage text-center hover:bg-gray-100 font-medium text-primary-800 py-2"
          to={marketUrl("/")}
        >
          마켓으로 이동
        </Link>
      </div>
    </div>
  );
};

const UnAuthUserBox = ({
  isPending,
  setLogoutPopup,
}: {
  isPending: boolean;
  setLogoutPopup: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex flex-col items-center justify-between h-full pt-5 text-[15px]">
      <img src={getStaticImgUrl("rank/unlogin_icon.svg")} alt="logo" />
      <span className="my-2">
        {isPending ? "회원가입 신청이 완료되었습니다" : "인증이 필요합니다"}
      </span>
      <div className={cls("bg-gray-50 grid w-full", !isPending && "grid-cols-2")}>
        <button
          className="text-center font-medium hover:bg-gray-100 py-2"
          onClick={() => setLogoutPopup(true)}
        >
          로그아웃
        </button>
        <Link
          className={cls(
            isPending && "hidden",
            "mypage text-center hover:bg-gray-100 font-medium text-primary-800 py-2",
          )}
          to="/verifyPending"
        >
          인증
        </Link>
      </div>
    </div>
  );
};

const LogoutPopup = ({ setLogoutPopup }: { setLogoutPopup: Dispatch<SetStateAction<boolean>> }) => {
  const [logoutAll, setLogoutAll] = useState(false);
  const navigate = useNavigate();

  const setLogout = () => {
    const data = {
      device: logoutAll ? "all" : null,
    };
    api?.post("/user/logout", data);
    navigate("/logout");
  };

  return (
    <Popup setPopup={setLogoutPopup} title="로그아웃" setConfirm={setLogout} confirmBtn="로그아웃">
      <div>로그아웃 하시겠습니까?</div>
      <div className="cbox">
        <label>
          <input type="checkbox" onChange={(e) => setLogoutAll(e.target.checked)} />
          <i></i>
          <span className="txt">모든 기기에서 로그아웃</span>
        </label>
      </div>
    </Popup>
  );
};
