import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../utils/util";

// TODO : phone 인증 중간에 취소하는 경우 처리
const PhoneVerify = () => {
  const navigate = useNavigate();
  const data = {
    min_age: 17,
  };
  const { state } = useLocation();

  const callback = (res) => {
    if (res.success === true) {
      const data = {
        impUid: res.imp_uid,
      };
      api.post("/user/phoneCertification", data).then((res) => {
        if (res.status === 200) {
          navigate("/signup", {
            state: {
              ...state,
              birthday: res.data.birthday,
              phone: res.data.phone,
              genderCd: res.data.gender === "male" ? "M" : "F",
              name: res.data.name,
              ci: res.data.unique_key,
              di: res.data.unique_in_site,
            },
          });
        }
      });
    } else {
      alert("실명인증에 실패하였습니다" + JSON.stringify(res));
      navigate(-1);
    }
  };

  useEffect(() => {
    const IMP = window.IMP;
    IMP.init("imp60774027");
    IMP.certification(data, callback);

    return () => {
      window.IMP.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default PhoneVerify;
