import { SetStateAction, useState, Dispatch } from "react";
import { Link } from "react-router-dom";
import { cls } from "../../utils/util";
import { CLASS_ROUTE } from "App";
import { TOPICS } from "../../constants";
import { getStaticImgUrl } from "utils/imageUtil";

const TopicsMenu = () => {
  const [topicsMenuDisplay, setTopicsMenuDisplay] = useState<boolean>(false);
  return (
    <div className="bg-gray-50 lg:bg-transparent">
      <ul className="grid grid-cols-5 lg:grid-cols-10 pt-4 pb-1 lg:py-6">
        {TOPICS.filter((topic) => {
          return ["IMPL", "GBR", "ENDO", "PROS", "MGMT", "EXT", "ESTHE", "OMFS", "RESTO"].includes(
            topic.name,
          );
        }).map((topic) => {
          return (
            <li className="text-center pb-3 lg:pb-0 group" key={topic.name}>
              {/* '/' 나 '&' 과 같이 URL 구분자의 충돌을 일으킬 가능성이 큰 문자는 인코딩 합니다. */}
              <Link
                className="block"
                to={`/${CLASS_ROUTE}/search?topics=${topic.label
                  .replace(/\s+/g, "")
                  .replace("/", encodeURIComponent("/"))
                  .replace("&", encodeURIComponent("&"))}`}
              >
                <div className="inline-block relative w-[35px] h-[35px] lg:w-[74px] lg:h-[74px] rounded-[15px] lg:rounded-full shadow-custom lg:transition lg:group-hover:transform lg:group-hover:scale-105">
                  <img
                    className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] scale-[0.55] lg:scale-100"
                    src={getStaticImgUrl(`klass/${topic.img}`)}
                    alt=""
                  />
                </div>
                <span className="block mt-1.5 lg:mt-2 !text-gray-800 text-[11px] lg:text-[16px] font-medium lg:font-semibold whitespace-nowrap">
                  {topic.label}
                </span>
              </Link>
            </li>
          );
        })}
        <li className="text-center pb-3 lg:pb-0 group">
          <button type="button" onClick={() => setTopicsMenuDisplay(true)}>
            <div className="inline-block relative w-[35px] h-[35px] lg:w-[74px] lg:h-[74px] rounded-[15px] lg:rounded-full bg-primary-50 shadow-custom lg:transition lg:group-hover:transform lg:group-hover:scale-105">
              <img
                className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] scale-[0.55] lg:scale-100"
                src={getStaticImgUrl(`klass/ico-topic-all.svg`)}
                alt=""
              />
            </div>
            <span className="block mt-1.5 lg:mt-2 !text-gray-800 text-[11px] lg:text-[16px] font-medium lg:font-semibold whitespace-nowrap">
              전체보기
            </span>
          </button>
        </li>
      </ul>
      {topicsMenuDisplay && <TopicsMenuPopup setTopicsMenuDisplay={setTopicsMenuDisplay} />}
    </div>
  );
};

export default TopicsMenu;

export const TopicsMenuPopup = ({
  setTopicsMenuDisplay,
}: {
  setTopicsMenuDisplay: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="relative">
      <div
        className={cls(
          "z-[520] overflow-auto w-full p-[30px] bg-white shadow-custom",
          "fixed top-0 bottom-0",
          "lg:absolute lg:bottom-auto lg:pr-[125px] lg:rounded-md",
        )}
      >
        <button
          className="absolute top-[20px] right-[20px] w-[40px] h-[40px] rounded-full hover:bg-gray-100"
          type="button"
          title="닫기"
          onClick={() => setTopicsMenuDisplay(false)}
        >
          <i className="ii ii-remove text-[24px]"></i>
        </button>
        <h4 className="text-gray-800 text-[20px] font-semibold">카테고리</h4>
        <ul className="mt-7 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 gap-y-3">
          <li>
            <Link
              className="block py-[8px] rounded-md bg-gray-100 hover:bg-primary-100 text-gray-800 text-[16px] text-center whitespace-nowrap"
              to={`/${CLASS_ROUTE}/search`}
              onClick={() => setTopicsMenuDisplay(false)}
            >
              전체
            </Link>
          </li>
          {TOPICS.map((topic) => {
            return (
              <li key={topic.name}>
                {/* '/' 나 '&' 과 같이 URL 구분자의 충돌을 일으킬 가능성이 큰 문자는 인코딩 합니다. */}
                <Link
                  className="block py-[8px] rounded-md bg-gray-100 hover:bg-primary-100 text-gray-800 text-[16px] text-center whitespace-nowrap"
                  to={`/${CLASS_ROUTE}/search?topics=${topic.label
                    .replace(/\s+/g, "")
                    .replace("/", encodeURIComponent("/"))
                    .replace("&", encodeURIComponent("&"))}`}
                  onClick={() => setTopicsMenuDisplay(false)}
                  key={topic.name}
                >
                  {topic.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
