import { getStaticImgUrl } from "utils/imageUtil";

const Badge = ({ item_level, style }) => {
  if (typeof item_level === "number")
    return (
      <div className="user_level_badge" style={style}>
        <div className="box">
          <span className="number">
            <b>Lv.</b>
            <span>{item_level}</span>
          </span>
        </div>
      </div>
    );

  return (
    <img
      className="absolute -translate-x-[180%]"
      style={style}
      src={getStaticImgUrl(`rank/rank_badge_${item_level}.svg`)}
      alt=""
    />
  );
};

export default Badge;
