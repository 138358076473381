import { uploadLicenseImg } from "page/user/LicenseVerify";
import { useState } from "react";
import { useRecoilState } from "recoil";
import Loading from "../../component/Loading";
import { tokenState } from "../../state";
import { getStorage } from "../../utils/util";
import { getStaticImgUrl } from "utils/imageUtil";

const DoctorVerifyPopup = ({ children }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgSrc2, setImgSrc2] = useState(null);
  const [token, setToken] = useRecoilState(tokenState);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const upload = async () => {
    if (!imgSrc && !imgSrc2) {
      alert("이미지를 첨부해주세요");
      return;
    }

    getStorage().setItem("token", JSON.stringify({ ...token, user_status: "pending" }));
    setToken({ ...token, user_status: "pending" });
  };

  if (!popup) return <div onClick={() => setPopup(true)}>{children}</div>;
  return (
    <div className="popup-overlay dark">
      <section className="member">
        <div className="member__wrap">
          <div className="popup_close" onClick={() => setPopup(false)}></div>
          {loading && <Loading />}
          {token.user_status === "pending" ? (
            <div className="member__con">
              <div className="login__tit">
                <div className="tit-txt">면허 등록완료</div>
              </div>

              <div className="join-form">
                <div className="success-box">
                  <div className="logo">
                    <img
                      src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_certify_success.png`}
                      alt="이미지"
                      className="m-auto"
                    />
                  </div>
                  <div className="main-txt">치과의사 면허 등록이 완료되었습니다.</div>
                  <div className="sub-txt">인증이 완료될 때까지 조금만 기다려주세요.</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="member__con">
              <div className="member__tit">
                <div className="tit-txt">면허 인증</div>
              </div>

              <div className="certify-info">
                <div className="bottom-txt">
                  하단 버튼을 눌러, 카메라로 의사 면허증을 찍어 업로드 해주세요
                </div>
                <div className="top-txt">
                  한번 치과의사 회원 전환 시, 다시는 치대생 회원으로 돌아갈 수 없으며 치대생 게시판
                  접근도 불가합니다.
                </div>
              </div>
              <div className="certify-form">
                <div className="photo-upload-btn-box">
                  <label className="photo-upload-btn">
                    <i className="ic ic-photo"></i>
                    <input
                      type="file"
                      onChange={(e) => uploadLicenseImg(e, setLoading, setImgSrc)}
                      accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                      style={{ display: "none", cursor: "pointer" }}
                    />
                    <span className="txt" style={{ cursor: "pointer" }}>
                      이미지 첨부 1
                    </span>
                  </label>
                </div>
                <div className="photo-upload-btn-box">
                  <label className="photo-upload-btn">
                    <i className="ic ic-photo"></i>
                    <input
                      type="file"
                      onChange={(e) => uploadLicenseImg(e, setLoading, setImgSrc2)}
                      accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                      style={{ display: "none", cursor: "pointer" }}
                    />
                    <span className="txt" style={{ cursor: "pointer" }}>
                      이미지 첨부 2
                    </span>
                  </label>
                </div>
              </div>
              <div className="certify-how">
                {imgSrc || imgSrc2 ? (
                  <>
                    {imgSrc && (
                      <div className="certificate">
                        <div className="photo-upload-img2">
                          <div className="img-box">
                            <img
                              style={{
                                maxHeight: 200,
                              }}
                              src={imgSrc}
                              alt="이미지"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {imgSrc2 && (
                      <div className="certificate">
                        <div className="photo-upload-img2">
                          <div className="img-box">
                            <img
                              style={{
                                maxHeight: 200,
                              }}
                              src={imgSrc2}
                              alt="이미지"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="certificate">
                    <img src={getStaticImgUrl("certificate_doctor.png")} alt="" />
                  </div>
                )}
              </div>

              <div className="grey mt30">
                1. 정확하지 않은 사진은 등록이 거절될 수 있으니, 반드시 식별 가능하게 찍어주세요.
                <br />
                2. 승인까지는 영업일 기준 최대 3일이 소요될 수 있습니다.
              </div>
              <div className="photo-certify-btn-box">
                <button
                  className="certify-btn"
                  onClick={upload}
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  제출하기
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DoctorVerifyPopup;
