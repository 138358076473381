import { api, cls } from "../utils/util";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const Rating = ({ myScore, scoreSum, scoreCount }) => {
  const [isRated, setIsRated] = useState(myScore ? myScore.score : null);
  const [hover, setHover] = useState(10);
  const sroreArray = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  const marks = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
  };
  const bid = parseInt({ ...useParams() }.bid || 0);
  const onScoreClick = (num) => {
    const data = { score: num };
    api.post(`/contents/score/${bid}`, data).then((res) => {
      setIsRated(num);
    });
  };

  return (
    <div className="my-10">
      {scoreCount !== 0 && (
        <div className="flex justify-center text-xl font-semibold gap-1 mb-2">
          평점{" "}
          <span className="text-primary-800 text-xl font-medium flex items-center">
            <i className="ii ii-star text-primary-800" />
            {(scoreSum / scoreCount).toFixed(1)}점
          </span>
        </div>
      )}
      <div className="flex flex-col items-center gap-2">
        <div className="text-[17px] font-medium">이 콘텐츠 어떠셨나요?</div>
        <div className="text-sm">유저분들의 소중한 의견을 기다립니다!</div>
      </div>
      <div className="bg-gray-50 py-4 px-8 lg:p-3 lg:w-fit lg:min-w-[350px] mx-auto rounded-md my-3">
        {isRated ? (
          <div className="text-gray-600 text-center">참여가 완료되었습니다.</div>
        ) : (
          <>
            <div className="text-gray-600 text-base text-center">
              내 점수 <span className="text-primary-800">{hover}점</span>
            </div>
            <div className="hidden lg:flex flex-row-reverse items-center justify-center mt-1">
              <div className="text-2xl">👍</div>
              {sroreArray.map((i) => {
                return (
                  <div
                    onClick={() => {
                      if (window.confirm("평점을 등록하시겠습니까?")) onScoreClick(i);
                    }}
                    className="rate pr-2 cursor-pointer group"
                    onMouseOver={() => setHover(i)}
                    key={i}
                  >
                    <div
                      className={cls(
                        i <= isRated ? "bg-primary-100 text-primary-800" : "bg-white",
                        "group-hover:bg-primary-100 group-hover:text-primary-800 number text-base w-8 h-8 flex items-center justify-center shadow-card rounded-md",
                      )}
                    >
                      {i}
                    </div>
                  </div>
                );
              })}
              <div className="text-2xl mr-2">👎</div>
            </div>
            <div className="rate_box flex flex-col items-center justify-center lg:hidden">
              <Slider
                min={1}
                max={10}
                step={1}
                defaultValue={10}
                className="rate__slider"
                marks={marks}
                onChange={(e) => setHover(e)}
              />
              <button
                onClick={() => onScoreClick(hover)}
                className="bg-white w-fit px-2 py-1 rounded-md text-base text-primary-800 shadow-card"
              >
                평점 주기
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Rating;
