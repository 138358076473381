import { discountedRate } from "../../utils/util";
import LevelIcon from "./LevelIcon";
import { getResizedImgUrl } from "utils/imageUtil";

const CourseCard = ({
  thumbnail,
  lecturers,
  title,
  regularPrice,
  salePrice,
  topic,
  level,
  isSwiper,
  isFree,
  highlightedText,
}: {
  thumbnail: string;
  lecturers: string[];
  title: string;
  regularPrice?: number;
  salePrice?: number;
  topic?: string[];
  level?: string;
  isSwiper?: boolean;
  isFree?: boolean;
  highlightedText?: (title: string) => string | JSX.Element;
}) => {
  // console.log("highlightedText in CourseCard.tsx: ", highlightedText);
  return (
    <section className="cursor-pointer lg:hover:shadow-card group lg:p-[10px] rounded-lg">
      <div className="relative">
        <div className="flex justify-center w-full aspect-class overflow-hidden rounded-md">
          <img
            src={getResizedImgUrl(thumbnail, "560")}
            alt=""
            className="rounded-md scale-100 ease-linear duration-300 lg:group-hover:scale-105"
          />
        </div>
        <div className="classnamebox" />
        <div className="flex items-center space-x-1 absolute bottom-0 px-3 py-1.5 w-full truncate">
          <i className="ii-user text-sm text-secondary-50 z-20"></i>
          <div className="text-xs font-medium !text-secondary-50 truncate z-20">
            {lecturers.join(",")}
          </div>
        </div>
      </div>

      <div className="flex flex-col py-2.5 justify-between h-[100px] border-b-[1px] border-solid border-secondary-200">
        <div className="text-[18px] leading-[24px] !text-gray-900 font-medium break-all overflow-hidden text-ellipsis line-clamp-2">
          {highlightedText ? highlightedText(title) : title}
        </div>
        {topic && (
          <div className="overflow-hidden h-[25px]">
            {topic?.map((item, idx) => (
              <div
                key={idx}
                className="inline-block bg-secondary-100 mr-2 mb-1 px-1 rounded-sm !text-secondary-700 text-xs font-medium whitespace-nowrap"
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
      {isSwiper ? (
        <div className="mt-2.5 flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <div className="!text-secondary-800 text-xs font-medium leading-loose">
              {level ?? ""}
            </div>
            <LevelIcon level={level} background="bg-secondary-800" />
          </div>
          {isFree ? (
            <div className="text-base font-medium !text-secondary-800">무료</div>
          ) : (
            <div className="flex items-center gap-[5px]">
              {discountedRate(regularPrice, salePrice) !== 0 && (
                <>
                  {regularPrice && (
                    <div className="text-sm font-normal !text-secondary-300 line-through">
                      {regularPrice.toLocaleString() + "원"}
                    </div>
                  )}
                  {regularPrice && salePrice && (
                    <div className="!text-red-200 text-[16px] font-semibold">
                      {discountedRate(regularPrice, salePrice)}%
                    </div>
                  )}
                </>
              )}
              {salePrice && (
                <div className="!text-secondary-800 text-[16px] font-semibold">
                  {salePrice.toLocaleString() + "원"}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="relative pt-[6px] lg:pt-0">
          <div className="flex lg:flex-row-reverse items-end justify-between h-[43px] lg:h-full lg:mt-1">
            <div className="lg:flex lg:items-center lg:flex-row-reverse">
              {(isFree || salePrice) && (
                <div className="!text-secondary-800 text-base font-semibold">
                  {isFree ? "무료" : salePrice?.toLocaleString() + "원"}
                </div>
              )}
              {!isFree && discountedRate(regularPrice, salePrice) !== 0 && (
                <div className="flex items-center space-x-1 absolute top-1 left-0 lg:static lg:mr-1">
                  {regularPrice && (
                    <div className="text-[13px] font-medium !text-[#AEAEAE] line-through">
                      {regularPrice.toLocaleString() + "원"}
                    </div>
                  )}

                  {regularPrice && salePrice && (
                    <div className="!text-red-200 font-semibold text-[13px]">
                      {discountedRate(regularPrice, salePrice)}%
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex items-center space-x-1">
              <div className="!text-secondary-800 text-xs font-medium leading-loose">
                {level ?? ""}
              </div>
              <LevelIcon level={level} background="bg-secondary-800" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CourseCard;
