import axios from "axios";
import { useState } from "react";
import { api } from "../../utils/util";
import Loading from "../Loading";
import { getImgUrl } from "utils/imageUtil";

const AttachImages = ({ useImages }) => {
  const [images, setImages] = useImages;
  const [loading, setLoading] = useState(false);

  const attachImage = async (e) => {
    setLoading(true);
    try {
      const uploadedFileList = await Promise.all(
        Array.from(e.target.files).map(async (file) => {
          const res = await api.post("/util/presigned", { filename: file.name });

          const signedUrl = res.data;
          const url = new URL(signedUrl);
          url.search = "";
          await axios.put(signedUrl, file);
          return url.toString();
        }),
      );
      setImages([...images, ...uploadedFileList]);
    } catch (err) {
      console.log(err);
      alert("이미지 업로드에 실패했습니다.");
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <div className="photo-btn-box">
        <label className="photo-upload-btn">
          <i className="ic ic-photo"></i>
          <input
            type="file"
            onChange={attachImage}
            multiple
            accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
            style={{ display: "none" }}
          />
          <span className="txt">이미지 첨부</span>
        </label>
        <span className="info-txt">
          이미지 파일은 각각 최대 20MB, 총 50개까지 업로드 가능합니다.
        </span>
      </div>
      {images.length > 0 && (
        <ul className="preview-list">
          {images.map((image) => {
            return (
              <li key={image} className="preview-box">
                <img src={getImgUrl(image)} alt="이미지" />
                <button
                  className="remove-btn"
                  onClick={() => {
                    setImages(images.filter((item) => item !== image));
                  }}
                ></button>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default AttachImages;
