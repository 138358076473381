import { forwardRef } from "react";
import { Link } from "react-router-dom";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
interface Button extends ButtonProps {
  color?: string;
  to?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledButton = forwardRef(({ children, to, ...props }: Button, ref: any) => {
  if (to)
    return (
      <Link
        to={to}
        ref={ref}
        className="w-full h-[50px] border-[1px] border-solid border-primary-800 text-primary-800 text-base rounded flex justify-center items-center"
      >
        {children}
      </Link>
    );

  return (
    <button
      {...props}
      ref={ref}
      className="w-full h-[50px] border-[1px] border-solid border-primary-800 text-primary-800 text-base rounded flex justify-center items-center"
    >
      {children}
    </button>
  );
});

export default StyledButton;
