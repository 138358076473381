import { Link, useSearchParams } from "react-router-dom";
import { cls } from "utils/util";
import { highlightedText } from "utils/searchUtil";
import ShareButton from "component/shared/ShareButton";
import { useSearchSegemnet } from "hooks/useSearchSegment";
import { getResizedImgUrl, getImgUrl } from "utils/imageUtil";

const SearchItemContent = ({ bid, param, title, tag, thumbnail, published_at }) => {
  const { handleSearchItemClick } = useSearchSegemnet();
  const [searchParams] = useSearchParams();
  const params = {
    indexUid: searchParams.get("indexUid") || "",
    q: searchParams.get("q") || "",
  };
  const q = params.q;
  return (
    <div className={cls(published_at ? "" : "bg-gray-100 rounded-md", "w-full h-fit")} key={bid}>
      <Link
        className="w-full group"
        to={`/contents/article/${bid}?${param}`}
        onClick={() => handleSearchItemClick({ title: title, id: bid })}
      >
        <div className="lg:h-full rounded-md aspect-contents overflow-hidden bg-gray-100">
          {thumbnail && (
            <img
              className="w-full h-full group-hover:scale-105 transition-all duration-300"
              src={getResizedImgUrl(getImgUrl(thumbnail), 600)}
              alt=""
            />
          )}
        </div>
        <div className="text-xl my-2 group-hover:text-primary-800 font-semibold truncate">
          {highlightedText(title, q)}
        </div>
      </Link>
      <div className="flex justify-between items-center mt-3">
        <div className="flex">
          {tag
            ?.split(",")
            .slice(0, 3)
            .map((keyword) => {
              return (
                <Link
                  to={`/contents/search?tag=${keyword}`}
                  className="truncate lg:text-tiny bg-gray-100 !text-gray-600 rounded-sm p-1 px-2 mr-3 lg:mt-auto cursor-pointer hover:bg-primary-100 hover:!text-primary-800"
                  key={keyword}
                  onClick={() => handleSearchItemClick({ title: title, id: bid, keyword })}
                >
                  {keyword}
                </Link>
              );
            })}
        </div>
        <ShareButton title={title} text={`[모어덴 - 콘텐츠]\n${title}`} small={true} />
      </div>
    </div>
  );
};

export default SearchItemContent;
