import { outResource } from "../../../utils/util";
import { TeefitLogo } from "./TeefitLogo";
import { useState, useEffect, useRef } from "react";
import { getStaticImgUrl, getResizedImgUrl } from "utils/imageUtil";

const LabTeefit = () => {
  const [popup, setPopup] = useState("");
  const [callPopup, setCallPopup] = useState(false);
  const [scroll, setScroll] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (window.scrollY >= ref?.current?.scrollHeight) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="max-w-[1200px] mx-auto">
      {popup && (
        <div
          onClick={() => setPopup("")}
          className="fixed cursor-pointer top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[500] flex justify-center items-center"
        >
          <div className="p-3 lg:p-0 w-[620px] max-h-[80vh] overflow-scroll">
            <img className="w-full" src={getResizedImgUrl(getStaticImgUrl(popup), 900)} alt="" />
          </div>
        </div>
      )}
      {callPopup && <CallPopup setPopup={setCallPopup} />}
      <div
        ref={ref}
        className="w-full lg:h-[500px] px-[20px] py-[40px] lg:p-[70px] lg:pb-0 flex flex-col items-center lg:items-start lg:justify-center relative"
      >
        <div className="w-full flex">
          <TeefitLogo />
        </div>
        <div className="w-full text-left text-[30px] leading-[1.3] lg:text-[45px] font-extrabold mt-5">
          가성비와 퀄리티를 <br />
          모두 갖춘 티핏기공소
        </div>
        <div className="w-full text-left text-[16px] lg:text-[25px] text-gray-600 font-medium mt-5">
          가성비 있는 기공료, 예지성 있는 잘 맞는 보철물
          <br />
          티핏기공소와 거래가 정답입니다
        </div>
        <img
          className="lg:absolute lg:right-[70px] mt-5 w-[300px] lg:w-[350px]"
          src={getStaticImgUrl("teefit/image1.png")}
          alt=""
        />
        <div
          onClick={() => outResource("https://forms.gle/BHXe4igMA7RtGRDS9")}
          className="transition ease-in duration-300 hover:bg-[#0044A7] cursor-pointer text-white shadow-card bg-[#0067FF] text-[20px] font-medium p-2 px-10 mt-10 rounded-full"
        >
          보철물 무료 테스트 신청
        </div>
      </div>
      <div className="w-full px-[20px] py-[40px] lg:p-[70px] pb-0 flex flex-col items-center lg:items-start lg:justify-center relative">
        <div className="flex flex-wrap w-full justify-between mt-5">
          <div className="w-[155px] h-[214px] lg:w-[230px] lg:h-[270px] shadow-card rounded-md flex flex-col justify-center items-center mb-5">
            <span className="text-[#0067FF] text-[20px] font-medium">01</span>
            <img
              className="my-3 h-[60px] lg:h-[90px]"
              src={getStaticImgUrl("teefit/card1.png")}
              alt=""
            />
            <div className="text-[17px] lg:text-[25px] font-semibold">무료 샘플 테스트</div>
            <div className="text-[15px] lg:text-[20px] font-medium text-gray-600">
              (1unit 싱글기준)
            </div>
          </div>
          <div className="w-[155px] h-[214px] lg:w-[230px] lg:h-[270px] shadow-card rounded-md flex flex-col justify-center items-center mb-5">
            <span className="text-[#0067FF] text-[20px] font-medium">02</span>
            <img
              className="my-3 h-[60px] lg:h-[90px]"
              src={getStaticImgUrl("teefit/card2.png")}
              alt=""
            />
            <div className="text-[17px] lg:text-[25px] font-semibold">리메이크</div>
            <div className="text-[15px] lg:text-[20px] font-medium text-gray-600">(1년)</div>
          </div>
          <div className="w-[155px] h-[214px] lg:w-[230px] lg:h-[270px] shadow-card rounded-md flex flex-col justify-center items-center mb-5">
            <span className="text-[#0067FF] text-[20px] font-medium">03</span>
            <img
              className="my-3 h-[60px] lg:h-[90px]"
              src={getStaticImgUrl("teefit/card3.png")}
              alt=""
            />
            <div className="text-[17px] lg:text-[25px] font-semibold">카드결제</div>
            <div className="text-[15px] lg:text-[20px] font-medium text-gray-600">(결제선생)</div>
          </div>
          <div className="w-[155px] h-[214px] lg:w-[230px] lg:h-[270px] shadow-card rounded-md flex flex-col justify-center items-center mb-5">
            <span className="text-[#0067FF] text-[20px] font-medium">04</span>
            <img
              className="my-3 h-[60px] lg:h-[90px]"
              src={getStaticImgUrl("teefit/card4.png")}
              alt=""
            />
            <div className="text-[17px] lg:text-[25px] font-semibold">구강스캐너 방문교육</div>
            <div className="text-[15px] lg:text-[20px] font-medium text-gray-600">
              (3Shape, medit)
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:h-[500px] px-[20px] py-[40px] lg:p-[70px] flex flex-col items-center lg:items-start lg:justify-center relative">
        <div className="text-[18px] w-full text-left text-[#0067FF] lg:text-[25px] font-semibold mb-[20px]">
          가격이 비쌀까봐 고민되시나요?
        </div>
        <div className="w-full text-left text-[22px] leading-[1.3] lg:text-[35px] font-bold">
          기존 보철물과 마음껏 비교해 보세요
          <br />
          티핏의 퀄리티와 내구성,
          <br className="lg:hidden" /> 그리고 가격까지
        </div>
        <img
          className="lg:absolute lg:right-[70px] mt-10 w-[250px]"
          src={getStaticImgUrl("teefit/paper.png")}
          alt=""
        />
        <div
          onClick={() =>
            outResource(
              "https://docs.google.com/forms/d/e/1FAIpQLScija5oxZxaMpoMDkgwaTOq34TYy6ps-_8uUkJrUhcV7e2GtA/viewform",
            )
          }
          className="transition ease-in duration-300 hover:bg-[#000E2B] cursor-pointer text-white text-center shadow-card bg-[#1D2D4F] text-[20px] font-medium p-2 px-10 mt-10 rounded-full"
        >
          티핏 상세 수가표 받아보기
        </div>
      </div>
      <div className="w-full pl-[20px] py-[40px] lg:pl-[70px] lg:py-[70px] flex flex-col lg:flex-row justify-between relative">
        <div className="flex flex-col">
          <div className="text-[18px] text-[#0067FF] lg:text-[25px] font-semibold">
            거리가 멀어서 망설이시나요?
          </div>
          <br />
          <div className="text-[22px] leading-[1.3] lg:text-[35px] font-bold">
            전국 택배망과 연계하여
            <br className="lg:hidden" /> 급한 보철물도
            <br className="hidden lg:block" /> 제작 기한을
            <br className="lg:hidden" /> 맞춰드립니다
          </div>
          <br />
          <div className="text-[20px] lg:text-[26px] font-medium text-[#545454] lg:mt-[65px]">
            기공전문택배(EDS물류)
            <br className="lg:hidden" /> / 우체국택배 / 로젠택배
          </div>
        </div>
        <div className="flex flex-col justify-between items-end">
          <img
            className="relative right-0 w-[350px] lg:w-[500px] my-10"
            src={getStaticImgUrl("teefit/truck.png")}
            alt=""
          />
          <div className="text-[15px] lg:text-[20px] font-medium text-[#545454] mr-10">
            수도권 : 기공물전문택배 - 서울 2회, 경기 1회 픽업
            <br />
            수도권 외 : 우체국 / 로젠 - 요청 다음날 기사님 방문 픽업
          </div>
        </div>
      </div>

      <div className="w-full px-[20px] py-[40px] lg:p-[70px] pb-0 flex flex-col items-center lg:items-start lg:justify-center relative">
        <div className="text-[18px] w-full text-left text-[#0067FF] lg:text-[25px] font-semibold mb-[10px]">
          전치부 쉐이드 매칭 시스템
        </div>
        <div className="w-full text-left text-[22px] lg:text-[35px] font-bold">
          예지성 있는 심미보철 서비스
        </div>
        <div className="flex flex-wrap w-full justify-between mt-5">
          <div className="relative bg-[#EEF7FF] rounded-lg w-full lg:w-[48%] h-[238px] lg:h-[289px] px-[23px] py-[33px] mb-5">
            <span className="text-[#0067FF] text-[20px] font-medium">POINT1</span>
            <div className="text-[20px] lg:text-[25px] mb-3 font-bold">왜곡 현상 보정</div>
            <div className="text-[18px] font-normal">
              카메라 종류와 세팅 값,
              <br />
              주변 광원과 촬영 술자 등<br />
              다양한 변수에 의한
              <br />
              왜곡 현상을 최소화
            </div>
            <img
              className="absolute h-[200px] lg:h-[210px] right-0 lg:right-5 bottom-0"
              src={getStaticImgUrl("teefit/card5.png")}
              alt=""
            />
          </div>
          <div className="relative bg-[#EEF7FF] rounded-lg w-full lg:w-[48%] h-[238px] lg:h-[289px] px-[23px] py-[33px] mb-5">
            <span className="text-[#0067FF] text-[20px] font-medium">POINT2</span>
            <div className="text-[20px] lg:text-[25px] mb-3 font-bold">
              Shade 가이드와 함께 사용
            </div>
            <div className="text-[18px] font-normal">
              기존에 사용하던
              <br />
              Shade 가이드 탭에
              <br />
              끼워서 활용 가능
            </div>
            <br />
            <img
              className="absolute w-[148px] lg:w-[257px] right-5 bottom-0"
              src={getStaticImgUrl("teefit/card6.png")}
              alt=""
            />
          </div>
          <div className="w-full lg:w-[48%] px-[23px] py-[33px] mb-5">
            <div className="text-[20px] lg:text-[25px] mb-3 font-semibold">치과 포토 가이드</div>
            <img className="w-full mb-3" src={getStaticImgUrl("teefit/card7.png")} alt="" />
            <div className="text-[13px] lg:text-[16px] font-medium text-[#5B5B5B]">
              지대치에 그레이카드를 대고 쉐이드가이드와 함께 사진을 찍습니다.
              <br />
              *그레이카드는 별매, 구매문의 기공소.
            </div>
          </div>
          <div className="w-full lg:w-[48%] px-[23px] py-[33px] mb-5">
            <div className="text-[20px] lg:text-[25px] mb-3 font-semibold">기공소 제작</div>
            <img className="w-full mb-3" src={getStaticImgUrl("teefit/card8.png")} alt="" />
            <div className="text-[13px] lg:text-[16px] font-medium text-[#5B5B5B]">
              디지털트라이 인을 해서 쉐이드 매치를 합니다.
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-[20px] py-[40px] lg:p-[70px] pb-0 flex flex-col items-center lg:items-start lg:justify-center relative">
        <div className="text-[18px] text-[#0067FF] lg:text-[25px] font-semibold mb-[10px]">
          전직원 100% 치과기공사 + 다수의 세미나 수료
        </div>
        <div className="w-full text-left text-[22px] lg:text-[35px] font-bold">
          검증된 국산,외산 재료로
          <br className="lg:hidden" /> 잘 맞는 보철물을 제작합니다
        </div>
        <div className="flex flex-wrap w-full justify-between mt-5">
          <div className="w-full lg:w-[48%] mb-5">
            <img className="w-full mb-3" src={getStaticImgUrl("teefit/profile1.png")} alt="" />
            <div className="px-[23px] py-[33px]">
              <div className="text-[15px] font-semibold">소장</div>
              <div className="text-[20px] lg:text-[25px] mb-3 font-semibold tracking-[0.25em]">
                정영훈
              </div>
              <ul className="ml-3 list-disc text-[15px] font-light">
                <li className="list-disc">3shape Dental system</li>
                <li className="list-disc">3Shape Diploma ( CAD Design Advanced Course )</li>
                <li className="list-disc">
                  치예원 Trojans - Occlusion & Full mouth rehabilitation Course Certificate
                </li>
                <li className="list-disc">Digital All on X Master Course Certificate</li>
                <li className="list-disc">2th-sms Course Certificate</li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-[48%] mb-5">
            <img className="w-full mb-3" src={getStaticImgUrl("teefit/profile2.png")} alt="" />
            <div className="px-[23px] py-[33px]">
              <div className="text-[15px] font-semibold">부소장</div>
              <div className="text-[20px] lg:text-[25px] mb-3 font-semibold tracking-[0.25em]">
                박주형
              </div>
              <ul className="ml-3 list-disc text-[15px] font-light">
                <li className="list-disc">EXO CAD</li>
                <li className="list-disc">기공소 경력 20년</li>
                <li className="list-disc">경기도 치과기공사 협회 우수상</li>
                <li className="list-disc">Vintage halo & NCC System Course Certificate</li>
                <li className="list-disc">Vintage High Course Certificate</li>
                <li className="list-disc">Digital All on X Master Course Certificate</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-[20px] py-[30px] lg:p-[70px] lg:pb-[20px] text-left text-[22px] lg:text-[35px] font-bold">
        치과별 작업데이터는 <br className="lg:hidden" />
        수치화하여 저장합니다
      </div>
      <div className="relative lg:px-[60px]">
        <img
          className="hidden lg:block w-full rounded-lg"
          src={getStaticImgUrl("teefit/teeth.png")}
          alt=""
        />
        <img className="lg:hidden w-full" src={getStaticImgUrl("teefit/teeth_mo.png")} alt="" />
        <div className="absolute text-white text-[30px] lg:text-[50px] lg:top-[60px] lg:left-[110px] top-[40px] left-[20px] font-bold">
          ALL on X
        </div>
        <div
          onClick={() => setPopup("teefit/popup0.png")}
          className="max-w-[320px] w-[fit-content] transition py-2 px-10 ease-in duration-300 hover:bg-[#D8EDFF] cursor-pointer lg:absolute lg:bottom-[90px] lg:left-[110px] relative bottom-[100px] mx-auto text-[#0067FF] text-center shadow-card bg-white text-[20px] font-semibold rounded-full"
        >
          티핏의 특별한 ALL on X
        </div>
      </div>
      <div className="w-full px-[20px] py-[10px] lg:py-[30px] lg:px-0 text-[#0067FF] text-left lg:text-center text-[22px] lg:text-[28px] font-semibold">
        사진을 클릭하시면 <br className="lg:hidden" />
        자세히 보실 수 있어요!
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 mx-auto w-[90%] pb-[30px]">
        <img
          onClick={() => setPopup("teefit/popup1.png")}
          className="cursor-pointer"
          src={getStaticImgUrl("teefit/zoom1.png")}
          alt=""
        />
        <img
          onClick={() => setPopup("teefit/popup2.png")}
          className="cursor-pointer"
          src={getStaticImgUrl("teefit/zoom2.png")}
          alt=""
        />
        <img
          onClick={() => setPopup("teefit/popup3.png")}
          className="cursor-pointer"
          src={getStaticImgUrl("teefit/zoom3.png")}
          alt=""
        />
        <img
          onClick={() => setPopup("teefit/popup4.png")}
          className="cursor-pointer"
          src={getStaticImgUrl("teefit/zoom4.png")}
          alt=""
        />
        <img
          onClick={() => setPopup("teefit/popup5.png")}
          className="cursor-pointer"
          src={getStaticImgUrl("teefit/zoom5.png")}
          alt=""
        />
        <img
          onClick={() => setPopup("teefit/popup6.png")}
          className="cursor-pointer"
          src={getStaticImgUrl("teefit/zoom6.png")}
          alt=""
        />
      </div>
      <div className="h-[400px] bg-[#075EE0] lg:rounded-lg mb-[20px] text-white px-[20px] py-[40px] lg:p-[70px] lg:mx-[60px] flex flex-col relative">
        <div className="text-[22px] lg:text-[35px] font-bold mb-[40px]">
          무료로 샘플 테스트하시고
          <br /> 천천히 결정해 보세요!
        </div>
        <div
          onClick={() => outResource("https://forms.gle/BHXe4igMA7RtGRDS9")}
          className="max-w-[320px] w-[fit-content] transition px-10 mx-auto ease-in duration-300 hover:bg-[#D8EDFF] cursor-pointer lg:m-0 text-[#0067FF] text-center shadow-card bg-white text-[20px] font-semibold py-2 rounded-full"
        >
          보철물 무료 테스트 신청
        </div>
        <img
          className="absolute lg:right-[100px] right-0 bottom-0 w-[163px] lg:w-[250px]"
          src={getStaticImgUrl("teefit/hand.png")}
          alt=""
        />
      </div>
      <div className="bg-[#393939] lg:rounded-lg px-[20px] py-[40px] lg:px-[70px] lg:py-[70px] lg:mx-[60px] flex flex-col justify-between relative">
        <div className="text-[22px] text-white lg:text-[35px] font-semibold">
          티핏 문의 / 오시는 길
        </div>
        <div className="text-[15px] lg:text-[18px] text-white font-normal mt-10">
          <i className="ii ii-location-outline text-white text-[30px] mr-3"></i>서울 금천구
          가산디지털2로 101 세계물산 <br className="lg:hidden" />
          (주) 한라원앤원타워 B동 1403호
        </div>
        <div className="text-[15px] lg:text-[18px] text-white font-normal my-3">
          <i className="ii ii-phone-call-outline text-white text-[30px] mr-3"></i>H.P 010-6318-9421
          TEL 02-2101-2084
        </div>
        <div className="text-[15px] lg:text-[18px] text-white font-normal">
          <i className="ii ii-message-outline text-white text-[30px] mr-3"></i>cs@teefitlab.com
        </div>
        <div className="flex flex-col lg:flex-row mt-10 max-w-[724px]">
          <div
            onClick={() => setCallPopup(true)}
            className="h-[46px] w-[280px] mx-auto lg:mx-0 transition ease-in duration-300 hover:bg-[#D8EDFF] cursor-pointer bg-[#EEF7FF] flex items-center justify-between shadow-card mb-5 text-[#0067FF] text-[20px] lg:px-[60px] px-[50px] font-semibold rounded-full lg:mr-10"
          >
            <i className="ii ii-phone-call-outline text-[#0067FF] text-[25px] lg:text-[30px] mr-3"></i>
            티핏 전화 문의
          </div>
          <div
            onClick={() => outResource("https://www.teefitlab.com/")}
            className="h-[46px] w-[280px] mx-auto lg:mx-0  transition ease-in duration-300 hover:bg-[#ECECEC] cursor-pointer bg-white flex items-center justify-between shadow-card mb-[100px] text-[20px] lg:px-[60px] px-[50px] font-semibold rounded-full"
          >
            <i className="ii ii-home text-[20px] mr-3"></i>
            티핏 홈페이지
          </div>
        </div>
      </div>
      {scroll && (
        <div className="fixed bottom-0 mb-3 lg:mb-3 px-3 lg:px-0 max-w-[1200px] w-full z-[91]">
          <div
            onClick={() => outResource("https://forms.gle/BHXe4igMA7RtGRDS9")}
            className="bg-[#0067FF] cursor-pointer shadow-footer w-full rounded-lg text-white flex lg:flex-row flex-col justify-center items-center py-3 lg:py-5 font-semibold"
          >
            <span className="ml-3 text-[20px] lg:text-[30px]">보철물 무료 테스트 신청 →</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LabTeefit;

const CallPopup = ({ setPopup }) => {
  return (
    <>
      <div className="popup-overlay" onClick={() => setPopup(false)}></div>
      <div className="loan-popup-wrap max-w-[580px] w-[80%] pt-[60px] px-[20px] lg:px-[70px]">
        <button className="loan-popup-close" onClick={() => setPopup(false)} />
        <div className="flex items-center mb-5">
          <img src={getStaticImgUrl("teefit/logo.png")} className="w-[67px] h-[19px]" alt="" />
          <span className="ml-3 text-[20px] font-semibold">티핏치과기공소</span>
        </div>
        <table className="font-semibold">
          <tr>
            <td className="w-[100px] lg:w-[130px] font-normal">전화번호</td>
            <td>02 - 2101 - 2084</td>
          </tr>
          <tr>
            <td className="font-normal">소장 정영훈</td>
            <td>010 - 6318 - 9421</td>
          </tr>
          <tr>
            <td className="font-normal">부소장 박주형</td>
            <td>010 - 5630 - 6909</td>
          </tr>
        </table>
      </div>
    </>
  );
};
