import React, { useEffect } from "react";
import StyledCheckBox from "../shared/StyledCheckBox";
import { Agreement, Term } from "types/agreement";
import Loading from "component/Loading";
import { PrivacyPolicy, ServicePolicy } from "./SignupPolicy";

const SignupAgreement = ({
  agreements,
  setAgreements,
}: {
  agreements: Agreement;
  setAgreements: React.Dispatch<React.SetStateAction<Agreement>>;
}) => {
  const mapTermsToAgreements = (terms: Term[]): Agreement => {
    return terms.reduce((acc, term) => {
      acc[term.key] = {
        isRequired: term.isRequired,
        isAgreed: false,
      };
      return acc;
    }, {} as Agreement);
  };

  const toggleTerm = (key: string) => {
    setAgreements((currentAgreement: Agreement) => ({
      ...currentAgreement,
      [key]: {
        ...currentAgreement[key],
        isAgreed: !currentAgreement[key].isAgreed,
      },
    }));
  };

  const terms: Term[] = [
    {
      isRequired: true,
      key: "service",
      title: "서비스 이용약관",
      content: <ServicePolicy />,
    },
    {
      isRequired: true,
      key: "privacy",
      title: "개인정보 수집 및 이용 동의",
      content: <PrivacyPolicy />,
    },
    {
      isRequired: false,
      key: "marketing",
      title: "모어덴 맞춤 콘텐츠와 혜택 등 정보 수신에 동의합니다",
    },
  ];

  useEffect(() => {
    if (Object.keys(agreements).length === 0) {
      setAgreements(mapTermsToAgreements(terms));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(agreements).length === 0) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-2 my-2">
      <div className="py-1 border-y-[1px] border-gray-200">
        <StyledCheckBox
          checked={
            Object.keys(agreements).length > 0
              ? Object.values(agreements).every((term) => term.isAgreed)
              : false
          }
          onChange={() => {
            const allAgreed = Object.values(agreements).every((term) => term.isAgreed);
            setAgreements((currentAgreement: Agreement) => {
              const newAgreement = { ...currentAgreement };
              Object.keys(newAgreement).forEach((key) => {
                newAgreement[key].isAgreed = !allAgreed;
              });
              return newAgreement;
            });
          }}
        >
          <div className="font-semibold text-lg ml-2">전체 동의</div>
        </StyledCheckBox>
      </div>
      {terms.map((term, index) => {
        const key = term.key;
        const agreement = agreements[key];
        return (
          <div key={index} className="relative">
            <StyledCheckBox checked={agreement.isAgreed} onChange={() => toggleTerm(key)}>
              <div className="text-sm ml-2">
                {term.isRequired ? "[필수]" : "[선택]"} {term.title}
              </div>
            </StyledCheckBox>
            {term.content && (
              <details className="group">
                <summary className="before:content-['더보기'] group-open:before:content-['닫기'] list-none absolute -top-0.5 right-0 text-sm text-secondary-600"></summary>
                {term.content}
              </details>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SignupAgreement;
