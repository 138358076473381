import { useState, useEffect, useRef } from "react";
import Footer from "../../component/footer/Footer";
import { default as CreatableSelect, default as Select } from "react-select";
import { api, selectStyles } from "../../utils/util";
import EditorAdmin from "../../component/editor_admin";
import { useLocation, useNavigate } from "react-router-dom";
import AttachImage from "component/shared/AttachImage";
import ProsemirrorEditor from "../../component/prosemirror/ProsemirrorEditor";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { DOMSerializer } from "prosemirror-model";
import { getImgUrl } from "utils/imageUtil";

const ContentsWrite = () => {
  const token = useRecoilValue(tokenState);
  const location = useLocation();
  const navigate = useNavigate();
  const modify = location.state || null;
  const authOption = [
    { value: "all", label: "모두" },
    { value: "user", label: "회원" },
    { value: "doctor", label: "의사" },
  ];

  const [content, setContent] = useState(modify ? modify.article.content : "");
  const savedContentJson = modify?.article?.contentJson ?? "";
  const [thumbnail, setThumbnail] = useState(modify ? modify.article.thumbnail : null);
  const titleRef = useRef(null);
  const [tag, setTag] = useState({
    inputValue: "",
    value: modify
      ? modify.article.tag.split(",").map((tag) => ({ label: tag, value: tag }), [])
      : [],
  });
  const [pcCoverImg, setPcCoverImg] = useState(modify ? modify.article.header_pc : null);
  const [moCoverImg, setMoCoverImg] = useState(modify ? modify.article.header_mo : null);
  const [headerColor, setHeaderColor] = useState(modify ? modify.article.header_color : "black");
  const [auth, setAuth] = useState(
    modify ? authOption.find((item) => item.value === modify.article.display_auth) : authOption[0],
  );
  const [keywordList, setKeywordList] = useState([]);
  const param = new URLSearchParams(useLocation().search);
  const editor = param.get("editor");
  const editorStateGetterRef = useRef(null);

  useEffect(() => {
    api.get("/contents/keywordList").then((res) => setKeywordList(res.data.keywords));
  }, []);

  const handleChange = (value) => {
    setTag({ ...tag, value });
  };

  const handleInputChange = (inputValue, action) => {
    if (action.action === "input-blur" || action.action === "menu-close") return;
    setTag({ ...tag, inputValue });
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    const { inputValue, value } = tag;
    if (!inputValue) return;
    if (event.key === "Enter" || event.key === ",") {
      setTag({
        inputValue: "",
        value: [...value, createOption(inputValue)],
      });
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    const { inputValue, value } = tag;
    if (!inputValue) return;
    const parseInput = inputValue
      .split(",")
      .filter((item) => item)
      .map((item) => createOption(item));
    setTag({
      inputValue: "",
      value: [...value, ...parseInput],
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    const title = titleRef.current?.value;
    const pmEditorState = editorStateGetterRef.current?.getEditorState();

    if (title.length < 5) {
      alert("제목을 5글자 이상 입력해주세요");
      return;
    }
    if (!content && !pmEditorState) return;

    const pmHTMLContent =
      pmEditorState?.schema &&
      DOMSerializer.fromSchema(pmEditorState.schema).serializeFragment(
        pmEditorState.doc.content,
        { document },
        document.createElement("div"),
      );
    const pmTextContent = pmHTMLContent?.textContent || "";
    const pmContentJson = pmEditorState?.doc.toJSON();

    const data = pmEditorState
      ? {
          title: title,
          content: pmHTMLContent?.innerHTML || "",
          textContent: pmTextContent,
          contentJson: pmContentJson,
          header_color: headerColor,
          display_auth: auth.value,
          tag: tag.value.map((item) => item.value).join(),
          thumbnail: thumbnail
            ? thumbnail.replace("https://moreden-img.s3.ap-northeast-2.amazonaws.com/img/", "")
            : null,
          header_pc: pcCoverImg ?? null,
          header_mo: moCoverImg ?? null,
        }
      : {
          title: title,
          content: content,
          textContent: new DOMParser().parseFromString(content, "text/html").body.textContent,
          header_color: headerColor,
          display_auth: auth.value,
          tag: tag.value.map((item) => item.value).join(),
          thumbnail: thumbnail
            ? thumbnail.replace("https://moreden-img.s3.ap-northeast-2.amazonaws.com/img/", "")
            : null,
          header_pc: pcCoverImg ?? null,
          header_mo: moCoverImg ?? null,
        };

    try {
      if (!modify) {
        const res = await api.post("/contents/write", data);
        localStorage.removeItem("autosave:pm-article:");
        localStorage.removeItem("autosave:pm-article-length:");

        navigate(`/contents/article/${res.data.bid}`, { replace: true });
        alert(res.data.msg);
      } else {
        const res = await api.post(`/contents/modify/${modify.article.bid}`, data);
        localStorage.removeItem("autosave:pm-article:");
        localStorage.removeItem("autosave:pm-article-length:");

        navigate(`/contents/article/${modify.article.bid}`, { replace: true });
        alert(res.data.msg);
      }
    } catch (e) {
      alert(e.response.data.msg);
    }
  };

  return (
    <div>
      <div className={headerColor === "black" ? "" : "contents__white-header"}>
        <div className="curation-write">
          <div
            className="cover-img"
            style={
              pcCoverImg
                ? {
                    backgroundImage: `url(${getImgUrl(
                      encodeURI(pcCoverImg)
                        .replaceAll("%25", "%")
                        .replace(/\(/g, "%28")
                        .replace(/\)/g, "%29"),
                    )})`,
                  }
                : {}
            }
          ></div>
          <div className="wrapper">
            <section className="board__reg">
              <div className="inner">
                <article className="section__tit__box">
                  <div className="tit-box">
                    <span className="tit-txt">큐레이션 콘텐츠 작성</span>
                  </div>
                </article>

                <div className="reg__top__box">
                  <div className="tag-box mb70">
                    <div className="tag-select">
                      <CreatableSelect
                        value={tag.value}
                        inputValue={tag.inputValue}
                        styles={selectStyles}
                        placeholder="태그를 선택해주세요."
                        isClearable
                        isMulti
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        options={keywordList}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                      />
                    </div>
                    <div className="reg-menu">
                      <div className="auth-select">
                        <Select
                          onChange={(option) => setAuth(option)}
                          styles={selectStyles}
                          options={authOption}
                          isSearchable={false}
                          defaultValue={authOption[0]}
                        />
                      </div>
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setThumbnail(file)}
                          text="썸네일 첨부"
                        />
                      </div>
                      {thumbnail && (
                        <div className="preview-list">
                          <div className="preview-box">
                            <img src={getImgUrl(thumbnail)} alt="이미지" />
                          </div>
                        </div>
                      )}
                      <button
                        onClick={() =>
                          setHeaderColor((cur) => (cur === "black" ? "white" : "black"))
                        }
                        className="delete-cover"
                      >
                        색 반전
                      </button>
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setPcCoverImg(file)}
                          text="PC 커버이미지"
                        />
                      </div>
                      <div className="left-menu-box">
                        <AttachImage
                          changeImage={(file) => setMoCoverImg(file)}
                          text="mobile 커버이미지"
                        />
                      </div>
                      {moCoverImg && (
                        <div className="preview-list">
                          <div className="preview-box">
                            <img src={getImgUrl(moCoverImg)} alt="이미지" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tit-box">
                    <input
                      className="width-max"
                      ref={titleRef}
                      defaultValue={modify?.article?.title}
                      type="text"
                      placeholder="5글자 이상의 제목을 입력해주세요."
                    />
                  </div>
                </div>
                <div className="short-inner curation-box">
                  <div className="reg__mid__box curation-editor">
                    {editor === "froala" ? (
                      <EditorAdmin content={content} setContent={setContent} />
                    ) : (
                      <ProsemirrorEditor
                        savedContentJson={savedContentJson}
                        isAdmin={token?.user_id === "admin"}
                        ref={editorStateGetterRef}
                      />
                    )}
                  </div>

                  <div className="reg__menu__box">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/contents", { replace: true });
                      }}
                      className="menu-btn type1"
                      style={{ border: "none", outline: "none" }}
                    >
                      취소
                    </button>
                    <button
                      onClick={submit}
                      className="menu-btn type2"
                      style={{ border: "none", outline: "none" }}
                    >
                      {modify ? "수정" : "저장"}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ContentsWrite;
