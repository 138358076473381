import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import { cls, api, getMention, removeMention } from "../../utils/util";
import TextareaAutosize from "react-textarea-autosize";
import AttachImage from "component/shared/AttachImage";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getImgUrl } from "utils/imageUtil";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CommentEditor = ({ newComment, setNewComment, target, disabled = false, current }) => {
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();
  const bid = parseInt(useParams().bid || 0);

  const cachedMention = getMention(newComment.content || "");

  const commentWrite = useMutation((data) => api.post(`/${current}/write/comment/${bid}`, data), {
    onSuccess: () => queryClient.invalidateQueries(`${current}Article`),
    onError: (e) => alert(e?.response?.data?.msg),
  });

  const commentModify = useMutation(
    ({ data, uuid }) => api.post(`/${current}/comment/modify/${uuid}`, data),
    {
      onSuccess: () => queryClient.invalidateQueries(`${current}Article`),
      onError: (e) => alert(e?.response?.data?.msg),
    },
  );

  const submit = async (comment, setComment) => {
    if (!comment.content && !comment.image) {
      alert("댓글내용이 존재하지 않습니다");
      return;
    }

    const data = {
      cid: comment.cid ?? null,
      content: comment.content
        ? comment.target && comment.target !== comment.cid
          ? `<mark user-id="">@${comment.targetNick}</mark> ` + comment.content
          : comment.content
        : "",
      image: comment.image ?? null,
    };

    !comment.uuid ? commentWrite.mutate(data) : commentModify.mutate({ uuid: comment.uuid, data });

    // setComment 객체 내의 모든 값을 null 처리한다
    Object.keys(comment).forEach((key) => {
      setComment((prev) => ({ ...prev, [key]: null }));
    });
  };

  return (
    <article className="comment__form">
      <div className={cls(target ? "bg-gray-100" : "", "p-5")}>
        <div className="flex flex-col text-[14px] text-black rounded-md border-[2px] border-solid border-gray-200 bg-white py-2 px-5">
          <span className="font-semibold text-primary-800 mr-1 mb-1">
            {cachedMention ? cachedMention[1] : target ? `@${target}` : ""}
          </span>
          <TextareaAutosize
            onChange={(e) =>
              setNewComment({
                ...newComment,
                content: cachedMention ? cachedMention[0] + e.target.value : e.target.value,
              })
            }
            value={removeMention(newComment.content || "")}
            disabled={disabled}
            placeholder={
              target
                ? `${target}님에게 대댓글을 남겨보세요`
                : `✏️ ${token.user_nickname}님, 댓글을 남겨보세요`
            }
            minRows={3}
          ></TextareaAutosize>
          <div className="w-full flex justify-between items-center relative">
            <AttachImage
              changeImage={(file) =>
                setNewComment({
                  ...newComment,
                  image: file,
                })
              }
            />
            <button
              onClick={() => submit(newComment, setNewComment)}
              className="text-white bg-black rounded-sm text-[13px] h-[30px] px-5"
            >
              {newComment.uuid ? "수정" : "등록"}
            </button>
          </div>
        </div>
        {newComment.image && (
          <div className="relative flex mt-2">
            <img className="h-[92px]" src={getImgUrl(newComment.image)} alt="이미지" />
            <i
              className="ii ii-remove ii-weight-600 text-gray-600 text-[15px] w-[15px] h-[15px] cursor-pointer"
              onClick={() => {
                setNewComment({ ...newComment, image: null });
              }}
            ></i>
          </div>
        )}
      </div>
    </article>
  );
};

export default CommentEditor;
