import { useQuery, useMutation, useQueryClient } from "react-query";
import { api, uidEncode } from "../../utils/util";
import { useNavigate } from "react-router";
import { PuffLoader } from "react-spinners";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import Badges from "./badges";
import { getStaticImgUrl } from "utils/imageUtil";

const ProfileBox = ({ uid, isOwner }) => {
  const token = useRecoilValue(tokenState);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const {
    data: profile,
    isLoading: profileLoading,
    isError,
  } = useQuery(["users", uid], () => api.get(`/users/${uid}`).then((res) => res.data), {
    staleTime: 15 * 60 * 1000,
  });

  const onClickBlock = useMutation((uid) => api.post(`/blocks/${uid}`), {
    onSuccess: () => {
      alert("해당 유저가 차단되었습니다.");
      return queryClient.invalidateQueries("users");
    },
    onError: (err) => {
      err.response && alert(err.response.data.msg);
    },
  });

  const releaseBlock = useMutation((uid) => api.delete(`/blocks/${uid}`), {
    onSuccess: () => {
      alert("차단 해제되었습니다.");
      return queryClient.invalidateQueries("users");
    },
    onError: (err) => {
      err.response && alert(err.response.data.msg);
    },
  });

  if (isError) {
    return null;
  }

  if (profileLoading || !profile)
    return (
      <div className="profile-box">
        <div className="flexCenter center" style={{ alignItems: "center", height: "100%" }}>
          <PuffLoader color="#4a25aa" />
        </div>
      </div>
    );

  return (
    <div className="profile-box">
      <div className="flex center">
        <div className="nick">{profile && profile.nickname}</div>
        <Badges isOwner={isOwner} uid={uid} />
      </div>
      <div className="flex ranking">
        {profile.rankings?.qna && (
          <div className="flex center">
            {profile.rankings.qna < 20 && (
              <img
                className="rank_img"
                src={getStaticImgUrl(`rank/rank_${profile.rankings.qna}.svg`)}
                alt=""
              ></img>
            )}
            <span className="gray">Q&A</span> {profile && profile.rankings.qna}위
          </div>
        )}
        <div className="gubun">|</div>
        {profile.rankings?.forum && (
          <div className="flex center">
            {profile.rankings?.forum < 20 && (
              <img
                className="rank_img"
                src={getStaticImgUrl(`rank/rank_${profile.rankings.forum}.svg`)}
                alt=""
              ></img>
            )}
            <span className="gray">임상포럼</span> {profile && profile.rankings.forum}위
          </div>
        )}
      </div>
      <div className="flex">
        <button
          className="bg-primary-100 text-primary-800 p-1 px-2 rounded font-medium"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            navigate(`/profile/${uidEncode(uid, profile.nickname)}`);
          }}
        >
          프로필
        </button>
        {token.uid !== parseInt(uid) &&
          (profile.blocked ? (
            <button
              className="flex text-secondary-900 border-[1px] border-[#ccc] border-solid p-1 px-2 rounded font-medium"
              onClick={() => releaseBlock.mutate(uid)}
            >
              <i className="ii ii-ban mr-1"></i>차단 해제
            </button>
          ) : (
            <button
              className="flex text-secondary-900 border-[1px] border-[#ccc] border-solid p-1 px-2 rounded font-medium"
              onClick={() => onClickBlock.mutate(uid)}
            >
              <i className="ii ii-ban mr-1 text-[#AEAEAE]"></i>차단
            </button>
          ))}
      </div>
    </div>
  );
};

export default ProfileBox;
