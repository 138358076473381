import React from "react";
import { getStaticImgUrl } from "utils/imageUtil";

const TongtestAbout = () => {
  return (
    <div>
      <article style={{ marginTop: 100 }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">통치안내</span>
        </div>
      </article>
      <img
        className="tongtest_test"
        src={getStaticImgUrl("tongtest/tongtest_test.svg")}
        alt="이미지"
      />
      <div className="tongtest-about__container">
        <img src={getStaticImgUrl("tongtest/tongtest_about.svg")} alt="이미지" />
      </div>
    </div>
  );
};

export default TongtestAbout;
