import { Link } from "react-router-dom";
import { api, chunk } from "../../utils/util";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useHomeSegment } from "hooks/useHomeSegment";
import { getImgUrl, getResizedImgUrl } from "utils/imageUtil";

const HotSeminar = () => {
  const { sendTrackingEvent } = useHomeSegment();
  const { data: seminarHotState, isLoading: seminarHotStateLoading } = useQuery(
    "managementSeminarHot",
    () => api?.get("management/seminar/hot", { params: { take: 8 } }).then((res) => res.data),
  );

  return (
    <div className="col-span-2 min-h-[305px]">
      <span className="flex justify-between items-center">
        <b className="text-[20px]">모어덴 PICK 세미나</b>
        <Link
          to="/management/seminar"
          className="cursor-pointer text-[13px] text-gray-600 hover:underline"
        >
          + 더보기
        </Link>
      </span>
      <div onClick={() => sendTrackingEvent({ name: "PICK 세미나" })}>
        {!seminarHotStateLoading && seminarHotState && !!seminarHotState.length && (
          <Swiper
            modules={[Autoplay]}
            className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
            slidesPerView={2}
            spaceBetween={24}
            loop={true}
            parallax={true}
            slideToClickedSlide={true}
            autoplay={true}
          >
            {chunk(seminarHotState, 2).map((item, idx) => (
              <SwiperSlide key={idx} style={{ cursor: "pointer" }} className="swiper-slide">
                {item[0] && <SeminarItem item={item[0]} />}
                {item[1] && <SeminarItem item={item[1]} />}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default HotSeminar;

export const SeminarItem = ({ item }: { item: any }) => {
  return (
    <Link
      className="w-full flex py-3 hover:text-primary-800"
      key={item.title}
      to={`/management/seminar/${item.bid}`}
    >
      <div className="min-w-[78px] w-[78px] h-[115px] rounded-sm overflow-hidden bg-gray-100">
        {item.thumbnail && (
          <img
            className="w-[100%] h-[100%]"
            src={getResizedImgUrl(getImgUrl(item.thumbnail), 180)}
            alt="이미지"
          />
        )}
      </div>
      <div className="p-3 pl-6 text-[14px]">
        <div className="!text-gray-600">
          [{item.location1 ? `${item.location1} ${item.location2}` : "온라인"}]
        </div>
        <div className="text-[15px] h-[50px] font-semibold line-clamp-2">{item.title}</div>
        <div className="!text-gray-900">
          {dayjs(item.start_date).format("MM-DD")} ~ {dayjs(item.end_date).format("MM-DD")} |
          보수교육 점수
          <span className="!text-primary-800 ml-1">{item?.point ? `${item.point}점` : "없음"}</span>
        </div>
      </div>
    </Link>
  );
};
