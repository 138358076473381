interface LogoProps {
  color?: string;
  width?: number;
  className?: string;
}

const Logo = ({ color }: LogoProps) => {
  return (
    <svg width="138" height="24" viewBox="0 0 138 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2029_18543)">
        <path
          d="M29.0615 21.8495H26.6521V8.96493H29.0615V10.5794C29.8465 9.3956 31.1465 8.64246 32.796 8.64246C34.8265 8.64246 36.3421 9.60989 37.1271 11.2514C37.8304 9.74512 39.2644 8.64246 41.3493 8.64246C44.3532 8.64246 46.3021 10.6605 46.3021 13.9686V21.8495H43.8927V14.3451C43.8927 12.2209 42.7288 10.8748 40.8071 10.8748C38.8855 10.8748 37.6672 12.2188 37.6672 14.3451V21.8495H35.285V14.3451C35.285 12.2209 34.0939 10.8748 32.1722 10.8748C30.2505 10.8748 29.0594 12.2188 29.0594 14.3451V21.8495H29.0615Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M49.0088 15.3937C49.0088 11.655 51.9039 8.64246 55.6132 8.64246C59.3226 8.64246 62.2449 11.655 62.2449 15.3937C62.2449 19.1324 59.3498 22.172 55.6132 22.172C51.8766 22.172 49.0088 19.1324 49.0088 15.3937ZM59.8333 15.3937C59.8333 12.8659 58.075 10.8748 55.6383 10.8748C53.2017 10.8748 51.4161 12.8659 51.4161 15.3937C51.4161 17.9215 53.2017 19.9396 55.6383 19.9396C58.075 19.9396 59.8333 17.8945 59.8333 15.3937V15.3937Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M67.4802 21.8495H65.0708V8.96493H67.4802V10.5794C68.1836 9.3956 69.293 8.64246 70.727 8.64246C71.5392 8.64246 72.2425 8.83178 72.703 9.20627L71.7024 11.4387C71.2691 11.1432 70.6474 10.9269 70.0236 10.9269C68.4536 10.9269 67.4802 12.2709 67.4802 14.4242V21.8474V21.8495Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M73.2704 15.3937C73.2704 11.655 76.0043 8.64246 79.6843 8.64246C83.3644 8.64246 86.0711 11.5198 86.0711 15.1524C86.0711 15.4748 86.0438 15.8244 85.9894 16.0137H75.7049C75.975 18.3272 77.681 20.0478 80.1428 20.0478C81.6039 20.0478 82.9039 19.484 83.7684 18.6226L85.0411 20.2642C83.9861 21.3939 82.1461 22.1741 80.0611 22.1741C76.1089 22.1741 73.2683 19.1345 73.2683 15.3958L73.2704 15.3937ZM75.7594 14.4263H83.6072C83.2283 12.0316 81.74 10.6876 79.7095 10.6876C77.6789 10.6876 76.1089 12.275 75.7573 14.4263H75.7594Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M101.712 3.26225V21.8495H98.3839V20.4514C97.4377 21.5541 96.1106 22.2261 94.4317 22.2261C90.9945 22.2261 88.2062 19.4278 88.2062 15.4207C88.2062 11.4137 90.8312 8.58835 94.4317 8.58835C96.1378 8.58835 97.4628 9.20626 98.3839 10.2569V4.87464C98.3839 4.70404 98.476 4.548 98.6246 4.46478L101.239 2.98762C101.451 2.86904 101.714 3.02091 101.714 3.26225H101.712ZM98.3839 15.3937C98.3839 13.3215 96.95 11.6821 94.9739 11.6821C92.9978 11.6821 91.5094 13.3236 91.5094 15.3937C91.5094 17.4638 92.9706 19.1324 94.9739 19.1324C96.9772 19.1324 98.3839 17.4638 98.3839 15.3937Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M103.942 15.4207C103.942 11.655 106.757 8.58832 110.492 8.58832C114.226 8.58832 117.014 11.4407 117.014 15.1523C117.014 15.5559 116.987 16.0136 116.933 16.2279H107.324C107.649 18.0297 109.031 19.3217 111.032 19.3217C112.332 19.3217 113.441 18.7578 114.199 17.9776L115.903 20.21C114.93 21.3397 113.087 22.2281 110.896 22.2281C106.864 22.2281 103.94 19.1615 103.94 15.4228L103.942 15.4207ZM107.379 14.1558H113.632C113.253 12.327 112.089 11.3845 110.546 11.3845C108.894 11.3845 107.758 12.5142 107.379 14.1558Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M122.422 21.8495H119.12V8.96489H122.422V10.2298C123.234 9.18126 124.479 8.58832 126.074 8.58832C129.078 8.58832 131 10.6855 131 14.0496V21.8495H127.699V14.5323C127.699 12.7847 126.671 11.68 125.074 11.68C123.477 11.68 122.422 12.7826 122.422 14.5323V21.8495V21.8495Z"
          fill={color ? color : "#3A2D73"}
        />
        <path
          d="M17.5336 0.416282C17.5336 0.0979646 17.1861 -0.101764 16.9077 0.0563545L13.2695 2.09941L13.2905 6.73686C11.9633 5.95251 10.4142 5.50312 8.75841 5.51144C3.89982 5.53225 -0.0209718 9.4644 -3.86369e-05 14.2953C0.0229879 19.1242 3.97936 23.0231 8.83796 23.0002C13.6421 22.9794 17.5273 19.1325 17.5943 14.3786H17.5985L17.5357 0.416282H17.5336ZM8.81912 18.7539C6.3197 18.7643 4.2829 16.7607 4.27034 14.2745C4.25987 11.7904 6.27574 9.76608 8.77725 9.7536C11.2767 9.74319 13.3135 11.7467 13.326 14.2329C13.3365 16.7171 11.3206 18.7414 8.81912 18.7539V18.7539Z"
          fill={color ? color : "#4A25AA"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2029_18543">
          <rect width="131" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;

export const SimpleLogo = ({ color, width = 19, className }: LogoProps) => {
  const height = (width / 19) * 26;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 19 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4707 1.27377C18.4707 0.932524 18.1046 0.718405 17.8113 0.887916L13.9787 3.07817L14.0008 8.04973C12.6027 7.20887 10.9708 6.7271 9.22655 6.73602C4.10833 6.75833 -0.0219639 10.9738 8.78917e-05 16.1528C0.0243448 21.3295 4.19213 25.5093 9.31035 25.4848C14.3712 25.4625 18.464 21.3384 18.5346 16.242H18.539L18.4729 1.27377H18.4707ZM9.2905 20.9325C6.65752 20.9437 4.51188 18.7958 4.49865 16.1305C4.48762 13.4674 6.61121 11.2972 9.2464 11.2838C11.8794 11.2727 14.025 13.4205 14.0382 16.0859C14.0493 18.7489 11.9257 20.9191 9.2905 20.9325Z"
        fill={color ? color : "#4A25AA"}
      />
    </svg>
  );
};

export const ChicruitingLogo = ({ color, width = 121 }: LogoProps) => {
  const height = (width / 121) * 33;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 121 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.946 18.0651C118.029 18.0651 117.581 17.7237 117.581 16.9131V7.44106C117.581 6.65173 118.05 6.28906 118.946 6.28906C119.864 6.28906 120.312 6.65173 120.312 7.44106V16.9131C120.312 17.7237 119.885 18.0651 118.946 18.0651ZM115 16.2091C115 17.0411 114.68 17.4251 113.912 17.4251H103.757C102.904 17.4251 102.541 17.0197 102.541 16.2304V8.18773C102.541 7.3344 102.904 6.9504 103.757 6.9504H113.293C114.061 6.9504 114.381 7.3344 114.381 8.1664C114.381 8.9984 114.061 9.3824 113.293 9.3824H105.229V10.9397H112.952C113.72 10.9397 114.04 11.3237 114.04 12.1557C114.04 12.9451 113.72 13.3291 112.952 13.3291H105.229V14.9931H113.912C114.68 14.9931 115 15.3771 115 16.2091ZM108.344 18.5984H116.322C119.074 18.5984 120.76 19.9637 120.76 22.4597C120.76 24.9771 119.032 26.3424 116.28 26.3424H108.344C105.57 26.3424 103.885 24.9771 103.885 22.4597C103.885 19.9637 105.57 18.5984 108.344 18.5984ZM108.216 23.9317H116.429C117.602 23.9317 118.114 23.2917 118.114 22.4597C118.114 21.6277 117.602 21.0091 116.429 21.0091H108.216C107.021 21.0091 106.53 21.6491 106.53 22.4597C106.53 23.2704 107.021 23.9317 108.216 23.9317Z"
        fill={color ? color : "#2C2C34"}
      />
      <path
        d="M98.0186 17.2762H83.0639C82.1252 17.2762 81.7412 16.8922 81.7412 15.9535V12.2415C81.7412 11.3242 82.1252 10.9402 83.0639 10.9402H96.0986V9.38284H82.8506C82.0612 9.38284 81.7412 9.02017 81.7412 8.2095C81.7412 7.35617 82.0612 6.97217 82.8506 6.97217H97.3572C98.2746 6.97217 98.7226 7.3775 98.7226 8.31617V12.0708C98.7226 12.9242 98.3386 13.3082 97.4852 13.3082H84.3012V14.8655H98.0186C98.7866 14.8655 99.1279 15.2282 99.1279 16.0388C99.1279 16.8922 98.7866 17.2762 98.0186 17.2762ZM81.6132 18.9828H98.8506C99.6399 18.9828 99.9599 19.3882 99.9599 20.2415C99.9599 21.0948 99.6399 21.4788 98.8506 21.4788H91.5972V25.2335C91.5972 26.0442 91.1492 26.4068 90.2319 26.4068C89.3146 26.4068 88.8666 26.0442 88.8666 25.2335V21.4788H81.6132C80.8452 21.4788 80.5039 21.0948 80.5039 20.2415C80.5039 19.3882 80.8452 18.9828 81.6132 18.9828Z"
        fill={color ? color : "#2C2C34"}
      />
      <path
        d="M74.4659 9.4255H61.4099C60.6419 9.4255 60.3219 9.08417 60.3219 8.2095C60.3219 7.35617 60.6419 6.97217 61.4099 6.97217H75.7033C76.8979 6.97217 77.2819 7.4415 77.2819 8.57217L77.0473 19.6655C77.0473 20.3908 76.5566 20.6895 75.6179 20.6682C74.7219 20.6468 74.2953 20.1562 74.2953 19.4095L74.3379 16.2948H61.4526C60.6846 16.2948 60.3646 15.9108 60.3646 15.0362C60.3646 14.1828 60.6846 13.7775 61.4526 13.7775H74.4019L74.4659 9.4255ZM60.2793 23.1642H77.5166C78.3059 23.1642 78.6259 23.5695 78.6259 24.4228C78.6259 25.2762 78.3059 25.6602 77.5166 25.6602H60.2793C59.5113 25.6602 59.1699 25.2762 59.1699 24.4228C59.1699 23.5695 59.5113 23.1642 60.2793 23.1642Z"
        fill={color ? color : "#2C2C34"}
      />
      <path
        d="M45.7947 11.0253V18.4706L50.4241 23.5906C50.9147 24.1026 50.8294 24.6786 50.1681 25.2759C49.4001 25.9586 48.9734 25.8519 48.4614 25.2759L44.4507 20.7746L40.4187 25.2759C39.9067 25.8519 39.4801 25.9586 38.7121 25.2759C38.0507 24.6786 37.9654 24.1026 38.4561 23.5906L43.1067 18.4493V11.0253H39.6081C38.8187 11.0253 38.5414 10.6413 38.5414 9.78794C38.5414 8.95594 38.8187 8.57194 39.6081 8.57194H43.1067V7.12127C43.1067 6.31061 43.4907 5.90527 44.4507 5.90527C45.3894 5.90527 45.7947 6.31061 45.7947 7.12127V8.57194H49.2721C50.0614 8.57194 50.3387 8.95594 50.3387 9.78794C50.3387 10.6413 50.0614 11.0253 49.2721 11.0253H45.7947ZM54.7334 6.28927C55.6507 6.28927 56.0987 6.69461 56.0987 7.48394V25.0199C56.0987 25.8306 55.6507 26.2146 54.7334 26.2146C53.8161 26.2146 53.3681 25.8306 53.3681 25.0199V7.48394C53.3681 6.69461 53.8161 6.28927 54.7334 6.28927Z"
        fill={color ? color : "#2C2C34"}
      />
      <rect x="0.232422" y="0.15625" width="32" height="32" rx="16" fill={"#3872F0"} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2875 15.9174C12.2875 13.3324 14.3831 11.2368 16.9682 11.2368C18.1051 11.2368 19.1473 11.6421 19.9582 12.3162C20.3802 12.7292 20.9579 12.9838 21.5951 12.9838C22.8876 12.9838 23.9354 11.936 23.9354 10.6435C23.9354 9.85581 23.5462 9.15902 22.9497 8.73487C21.3275 7.38006 19.247 6.55615 16.9682 6.55615C11.7981 6.55615 7.6069 10.7473 7.6069 15.9174C7.6069 17.4085 7.95551 18.8181 8.57567 20.0693L7.17254 24.2787C7.02534 24.7203 7.44548 25.1405 7.8871 24.9932L11.7649 23.7006C13.2529 24.6974 15.0427 25.2787 16.9682 25.2787C19.2375 25.2787 21.3164 24.461 22.9366 23.1162C23.5405 22.693 23.9354 21.9918 23.9354 21.1983C23.9354 19.9058 22.8876 18.858 21.5951 18.858C20.9788 18.858 20.4181 19.0962 20.0001 19.4856L19.999 19.4844C19.1823 20.179 18.1242 20.5981 16.9682 20.5981C14.3831 20.5981 12.2875 18.5025 12.2875 15.9174Z"
        fill={"white"}
      />
    </svg>
  );
};
