import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../utils/util";
import { getImgUrl } from "utils/imageUtil";

const SeminarWeeklyPage = () => {
  const article = parseInt(useParams().article || 0);
  const [pageInfo, setPageInfo] = useState(null);

  useEffect(() => {
    api.get(`/management/seminar/weekly/article/${article}`).then((res) => {
      setPageInfo(res.data.article);
    });
  }, [article]);

  return (
    <div className="inner">
      <div className="wrapper">
        {pageInfo && (
          <>
            <img
              src={`${getImgUrl(pageInfo.link)}`}
              alt="주간 세미나"
              style={{ width: "1200px", marginTop: "0px" }}
            />
            {pageInfo.link1 && (
              <img
                src={`${getImgUrl(pageInfo.link1)}`}
                alt="주간 세미나"
                style={{ width: "1200px", marginTop: "84px" }}
              />
            )}
            {pageInfo.link2 && (
              <img
                src={`${getImgUrl(pageInfo.link2)}`}
                alt="주간 세미나"
                style={{ width: "1200px", marginTop: "84px" }}
              />
            )}
            {pageInfo.link3 && (
              <img
                src={`${getImgUrl(pageInfo.link3)}`}
                alt="주간 세미나"
                style={{ width: "1200px", marginTop: "84px" }}
              />
            )}
            {pageInfo.link4 && (
              <img
                src={`${getImgUrl(pageInfo.link4)}`}
                alt="주간 세미나"
                style={{ width: "1200px", marginTop: "84px" }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SeminarWeeklyPage;
