import { useEffect, useState, forwardRef } from "react";
import { cls } from "../../utils/util";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
interface Input extends InputProps {
  isInvalid?: boolean;
  invalidMsg?: string;
  checkIcon?: boolean;
  label?: string;
  value: string | number | readonly string[] | undefined; // 필수
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const StyledInput = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (
    { className, label, required, isInvalid, invalidMsg, value, checkIcon = true, ...props }: Input,
    ref: any,
  ) => {
    const [initialState, setInitialState] = useState<boolean>(true);
    const invalid = !initialState && isInvalid;

    useEffect(() => {
      if (!value || !initialState) return;
      setInitialState(false);
    }, [value, initialState]);

    return (
      <div className={cls(className)}>
        {label && (
          <label htmlFor={props.id} className="text-sm font-semibold tracking-tight mb-1">
            {label}
            {required && <span className="ml-1 text-primary">*</span>}
          </label>
        )}
        <div className="relative">
          <input
            {...props}
            ref={ref}
            value={value}
            className={cls(
              invalid ? "border-alert shadow-sm" : "border-gray-300",
              "border-[1px] border-solid focus:border-gray-900 focus:shadow-sm w-full h-full rounded-md pl-2 pr-7 py-2 placeholder-gray-300 peer placeholder:text-sm placeholder:font-medium",
            )}
          />
          {checkIcon && !initialState && (
            <i
              className={cls(
                invalid ? "text-gray-300" : "text-mint",
                "absolute right-2 top-[50%] translate-y-[-50%] text-xl ii ii-check ii-weight-600 peer-focus:text-gray-300",
              )}
            ></i>
          )}
        </div>
        {invalid && (
          <div className="peer-focus:hidden text-alert text-sm whitespace-nowrap ml-2 mt-0.5">
            {invalidMsg}
          </div>
        )}
      </div>
    );
  },
);

export default StyledInput;
