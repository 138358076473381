import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { api, dttm, htmlTextContent, watermark } from "../../utils/util";
import Loading from "../../component/Loading";
import { majorOptions, salaryOptions } from "../../constants";
import BoardReportPopup from "../../component/board/BoardReportPopup";
import BoardTrashPopup from "../../component/board/BoardTrashPopup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import DoctorContent from "../../component/recruit/doctorContent";
import KakaoMap from "component/shared/KakaoMap";
import { getImgUrl } from "utils/imageUtil";

function DoctorDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const bid = useParams().bid;
  const [content, setContent] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const q = urlParam.get("q") || "";
  const [reportPopup, setReportPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const token = useRecoilValue(tokenState);

  const filter = {
    major: urlParam.has("major") && { value: urlParam.get("major") },
    working_time: urlParam.has("working_time") && { value: urlParam.get("working_time") },
    loc1: urlParam.has("loc1") && urlParam.get("loc1"),
    loc2: urlParam.has("loc2") && urlParam.get("loc2").split(","),
    wage: urlParam.has("wage") && urlParam.get("wage"),
  };

  const recruitScroll = useRef();

  const modifyFunc = () => {
    navigate(`/recruit/doctor/modify/${bid}`, {
      state: { ...content },
    });
  };

  const directDeleteArticle = () => {
    api.delete(`/management/recruit/delete/${bid}`).then((res) => {
      navigate(`/recruit/doctor`, { replace: true });
    });
  };

  const scrapClicked = async () => {
    const data = {
      scraped: !content.scraped,
    };
    const res = await api.post(`/management/recruit/scrap/${bid}`, data).catch((err) => {
      alert(err.response.data.msg);
    });

    res &&
      setContent({
        ...content,
        scraped: res.data.scrap,
      });
  };

  useEffect(() => {
    api
      .get(`/management/recruit/article/${bid}`)
      .then((res) => {
        window.scrollTo(0, 0);
        setContent(res.data);
      })
      .catch((err) => {
        alert(err.response.data.msg);
        navigate(-1);
      });
  }, [navigate, bid]);

  useEffect(() => {
    // TODO paging 에 따라 로딩
    const data = new URLSearchParams();
    filter.major && data.append("major", filter.major.value);
    filter.working_time && data.append("working_time", filter.working_time.value);
    filter.loc1 && data.append("loc1", filter.loc1);
    filter.loc2 && filter.loc2.length > 0 && data.append("loc2", filter.loc2.join(","));
    filter.wage && data.append("netWage", filter.wage);
    page && data.append("page", page);
    q.length > 0 && data.append("q", q);

    api
      .get(`/management/recruit/list?`, {
        params: data,
      })
      .then((res) => {
        setPageInfo({ ...res.data });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, q]);

  if (!content) return <Loading current="management" />;

  return (
    <div>
      <div className="wrapper" style={{ background: watermark(token), backgroundRepeat: "space" }}>
        <section className="magazine__detail" style={{ marginTop: "54px" }}>
          <div className="inner">
            <article className="section__tit__box">
              <div className="tit-box">
                <span className="tit-txt">구인공고</span>
                {content.article && content.ismine ? (
                  <>
                    <div className="menu-box modify">
                      <div className="report-box">
                        <button onClick={modifyFunc} className="report-btn">
                          수정
                        </button>
                      </div>
                      <div className="report-box">
                        <button onClick={() => setDeletePopup(true)} className="report-btn">
                          삭제
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </article>
            {deletePopup && (
              <BoardTrashPopup setDeletePopup={setDeletePopup} deleteFunc={directDeleteArticle} />
            )}

            {content.article && (
              <div className="detail__group">
                <div className="tit-box" style={{ width: "100%" }}>
                  <div className="subject-box">{content.article.title}</div>
                  <div className="info-box">
                    <span className="txt">{content.article.hospital_name}</span>
                    <span className="txt">{dttm(content.article.reg_dttm)}</span>
                  </div>
                </div>
                <div className="etc-box">
                  <div className="row">
                    <div className="subject">위치:</div>
                    <div className="con">
                      {content.article.address} | {content.article.hospital_name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="subject">전공:</div>
                    <div className="con">
                      {content.article.major
                        .split("")
                        .map((key, i) => {
                          return (
                            <span key={i}>
                              {majorOptions.find((major) => major.value === key).label}
                            </span>
                          );
                        })
                        .reduce((prev, curr) => [prev, " / ", curr])}
                    </div>
                  </div>
                  <div className="row">
                    <div className="subject">근무형태:</div>
                    <div className="con">
                      {content.article.working_time === "whatever"
                        ? "협의"
                        : content.article.working_time === "part_time"
                        ? "파트타임"
                        : "풀타임"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="subject">급여:</div>
                    <div className="con">
                      {content.article.wage_type
                        ? salaryOptions.find((item) => item.value === content.article.wage_type)
                            .label
                        : "협의"}
                      {content.article.gross_wage !== null &&
                        ` ${(Number(content.article.gross_wage) / 10000).toLocaleString(
                          "ko-KR",
                        )}만원 (세전)`}
                      {content.article.net_wage !== null &&
                        ` ${(Number(content.article.net_wage) / 10000).toLocaleString(
                          "ko-KR",
                        )}만원 (세후)`}
                    </div>
                  </div>
                  <div className="row">
                    <div className="subject">전화번호:</div>
                    <div className="con">{content.article.call_number}</div>
                  </div>
                </div>

                <div className="con-box">
                  <div className="txt-box" style={{}}>
                    {htmlTextContent(content.article.content)}
                  </div>
                  <div className="img-box">
                    {content.images &&
                      content.images.map((item, i) => {
                        return <img key={i} src={getImgUrl(item.link)} alt="이미지" />;
                      })}
                  </div>
                  <div className="location-box" style={{}}>
                    <b>근무지 위치</b>
                    <div className="info-box" style={{}}>
                      {content.article.address}
                    </div>
                    <KakaoMap lati={content.article.lati} long={content.article.long} />
                  </div>
                </div>
                <div className="menu-box-list mt-3">
                  {content.article && !content.ismine && (
                    <>
                      <button
                        className={content.scraped ? "menu-box on" : "menu-box"}
                        onClick={scrapClicked}
                      >
                        <div className={content.scraped ? "scrap-btn-n on" : "scrap-btn-n"}></div>
                        <span className="menu-txt">스크랩</span>
                      </button>
                      <button className="menu-box" onClick={() => setReportPopup(true)}>
                        <div className="police-btn-n on"></div>
                        <span className="menu-txt">신고</span>
                      </button>
                    </>
                  )}
                  {navigator.share ? (
                    <button
                      className="menu-box"
                      onClick={() =>
                        navigator.share({
                          title: content.article.title,
                          text: `[모어덴 - 구인공고 게시판]\n${content.article.title}`,
                          url: location.pathname,
                        })
                      }
                    >
                      <div className="share-btn-n"></div>
                      <span className="menu-txt">공유</span>
                    </button>
                  ) : (
                    <CopyToClipboard
                      text={`https://www.moreden.co.kr${location.pathname}`}
                      onCopy={() => alert("링크를 복사하였습니다")}
                    >
                      <button className="menu-box">
                        <div className="share-btn-n"></div>
                        <span className="menu-txt">공유</span>
                      </button>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>

        <section
          className="magazine__list"
          style={{ marginBottom: "136.5px", marginTop: "50px" }}
          ref={recruitScroll}
        >
          {pageInfo && (
            <div className="inner">
              <DoctorContent pageInfo={pageInfo} pages={pageInfo.pages} state={filter} />
            </div>
          )}
        </section>
      </div>
      {reportPopup && (
        <BoardReportPopup category="management_recruit" setReportPopup={setReportPopup} bid={bid} />
      )}
    </div>
  );
}

export default DoctorDetail;
