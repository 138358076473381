import { useState, useRef } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import BoardListUI from "../../component/board/BoardListUI";
import Footer from "../../component/footer/Footer";
import Header from "../../component/header/Header";
import { tokenState } from "../../state";
import { api } from "../../utils/util";
import { getStaticImgUrl } from "utils/imageUtil";

const EventList = ({ event }) => {
  const { subject, school, img, content, start, end } = event;
  const [click, setClick] = useState(false);
  return (
    <section className="festival__hot__board">
      <div className="inner">
        <article className="festival__board">
          <div onClick={() => setClick(!click)} className="bsubject-box">
            {subject}
            <div className="ft-box">{start}</div>
          </div>
          <img src={getStaticImgUrl(`festival/${img}`)} alt="이미지" />
          <div className="subject-box">
            ⏰행사 시간: {end ? start + " - " + end : start}
            <br />
            <span
              onClick={() => window.open(`https://www.youtube.com/watch?v=aMedOBZmgWY`, "_blank")}
              className="link-youtube"
            >
              👉유튜브 바로가기
            </span>
          </div>
          {school && <div className="time-box">🎊{school}</div>}
          <div className="des-box">{content}</div>
        </article>
      </div>
    </section>
  );
};

const SchoolList = ({ school }) => {
  const { name, img, summary, year, event, content, tel } = school;
  return (
    <section className="festival__hot__board">
      <div className="inner">
        <div className="univ1">
          <article className="univ__board">
            <img className="univ" src={getStaticImgUrl(`logos/university/${img}`)} alt="이미지" />
            <div className="bsubject">
              <div className="bsubject-box">{name} 치과대학</div>
              <div className="bbsubject-box">{summary}</div>
            </div>
          </article>
          <article className="univdes__board">
            <div className="subject-box">
              🏫학교 창립일: {year}년
              <br />
              📞전화:
              <a href={`tel:${tel}`}>{tel}</a>
            </div>
            {event && <div className="time-box">{event}</div>}
            <div className="des-box">{content}</div>
          </article>
        </div>
      </div>
    </section>
  );
};

const Festival = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(0);
  const title = ["전치제 참가 학교", "전치제 게시판"];
  const schools = [
    {
      name: "강릉원주대학교",
      img: "univ_kw.png",
      summary: "환자의 건강을 우선하는 인재를 양성합니다",
      year: "1992",
      event: "",
      content:
        "강릉원주대학교 치과대학은 양질의 교육을 통해 우수한 진료능력, 올바른 직업윤리, 창의적 연구 잠재력 및 리더십을 갖춘 치과의사를 양성합니다. 대한민국 치과 의료의 패러다임을 선도하는 대학이 되겠습니다.",
      tel: "033-640-2444",
    },
    {
      name: "경북대학교",
      img: "univ_kb.png",
      summary: "미래 지식 사회를 선도하는 인재를 양성합니다.",
      year: "1973",
      event: "",
      content:
        "경북대학교 치과대학은 세계 치의학을 선도하는 치과대학으로 나아가기 위해 교직원, 학생, 동창회가 다함께 힘을 합쳐 노력하고 있습니다. 최고의 치의학 교육기관으로서 치의학 연구를 주도하는 국제 경쟁력을 갖춘 대학으로 나아갈 것입니다.",
      tel: "053-660-6804",
    },
    {
      name: "경희대학교",
      img: "univ_kh.png",
      summary: "건강과 삶의 질을 높이는 인재를 양성합니다",
      year: "1966",
      event: "",
      content:
        "경희대학교 치과대학은 치의학 교육, 임상, 연구에서의 수월성을 추구하며 이의 미래를 선도합니다. 서울 3대 치과대학으로서, 지금까지의 위상에 걸맞는 역할을 위해 부단히 노력할 것이며, 학생, 교수, 동문, 구성원 모두와 함께 전진할 것입니다.",
      tel: "02-961-0341~2",
    },
    {
      name: "단국대학교",
      img: "univ_dg.png",
      summary: "인류사회에 봉사하는 치과의사를 양성합니다",
      year: "1979",
      event: "",
      content:
        "단국대학교 치과대학은 단국대학교 창학이념인 구국, 자주, 자립을 바탕으로 치의학적 진리를 탐구하고 인류사회에 봉사하는 치과의사를 양성합니다. 세계로 도약하는 도전과 창조, 그리고 배려의 치과대학이 되도록 하겠습니다.",
      tel: "041-550-1910",
    },
    {
      name: "원광대학교",
      img: "univ_wg.jpg",
      summary: "국민을 위해 봉사하는 치의학의 선두두자를 양성합니다.",
      year: "1979",
      event: "",
      content:
        "원광대학교 치과대학은 국가와 지역사회의 요구에 부응할 수 있는 의료인으로서 의술 및 봉사정신을 갖춘 치과의사를 양성합니다. 지나온 40년을 바탕으로 새로운 40년을 향해 나아가며, 열정과 어울림의 소리가 넘쳐나는 학교를 만들겠습니다. ",
      tel: "063-850-6850",
    },
    {
      name: "연세대학교",
      img: "univ_ys.jpg",
      summary: "창의적인 글로벌 인재를 양성합니다.",
      year: "1967",
      event: "",
      content:
        "연세대학교 치과대학은 기독정신을 바탕으로 올바른 품성과 전문가로서의 역량을 갖춘 창의적인 치과의사를 양성해 인류의 구강보건에 기여합니다. 앞으로도 치의학계의 변화를 선도하는 글로벌 인재를 육성시켜 나가도록 하겠습니다.",
      tel: "02-362-8618",
    },
    {
      name: "조선대학교",
      img: "univ_js.jpg",
      summary: "구강보건 향상에 기여하는 치과의사를 양성합니다.",
      year: "1973",
      event: "",
      content:
        "조선대학교의 건학 이념에 따라 교육과 학술연구 및 사회봉사를 통해 구강보건 향상에 기여할 수 있는 치과의사를 양성합니다.  가슴이 따뜻한 치호인, 세계 속에 함께 달려가는 조선대학교 치과대학이 되겠습니다.",
      tel: "062-220-3800~3",
    },
    {
      name: "전북대학교",
      img: "univ_jb.jpg",
      summary: "인성과 지성을 겸비한 전문인력을 양성합니다.",
      year: "1978",
      event: "",
      content:
        "전북대학교 치과대학은 인성과 지성을 겸비한 세계적 수준의 치의학 전문인력을 양성합니다. 선도적이고 특성화된 연구를 통하여 치의학분야의 지식을 개발하고 보급하며, 인간에 대한 존중을 바탕으로 인류와 사회에 봉사하겠습니다.",
      tel: "82-63-270-4002",
    },
    {
      name: "부산대학교",
      img: "univ_bs.jpg",
      summary: "세계와 소통하는 직업전문인을 양성합니다.",
      year: "1979",
      event: "",
      content:
        "부산대학교 치의학전문대학원은 연구자, 학생, 교직원 모두의 꿈과 열정을 펼쳐보일 수 있는 우리 모두의 학교입니다. 자랑스러운 부산대학교 치의학전문대학원에서 여러분의 미래를 설계하고 꿈을 이루어 나가시길 기대합니다.",
      tel: "051-510-8206~9",
    },
    {
      name: "서울대학교",
      img: "univ_sw.png",
      summary: "인류의 건강과 행복에 기여하는 전문가를 양성합니다. ",
      year: "1946",
      event: "",
      content:
        "서울대학교 치의학대학원은 세계를 선도하는 대학으로 도약하기 위해 지속적으로 노력하고 있습니다. 사회와 국가의 긍정적인 변화와 발전을 이끌 수 있는 선한 인재를 양성하도록 혼신의 노력을 다하겠습니다.",
      tel: "02-740-8610",
    },
    {
      name: "전남대학교",
      img: "univ_jn.jpg",
      summary: "실무역량과 공감능력이 뛰어난 치과의사를 양성합니다.",
      year: "1981",
      event: "",
      content:
        "전남대학교 치의학전문대학은 국민의 구강건강을 책임지는 최고의 역량을 가진 전문의료인력을 양성합니다. 글로벌 치의학을 선도할 수 있는 뛰어난 인재들을 배출하기 위해 최선의 노력을 다하겠습니다. ",
      tel: "062-530-5808",
    },
  ];

  /* 게시판 */
  const board = 26;
  const boardScroll = useRef();
  const token = useRecoilValue(tokenState);
  const isLogin = token && token.user_type;
  const urlParam = new URLSearchParams(location.search);
  const page = parseInt(urlParam.get("page")) || 1;
  const q = urlParam.get("q");
  const [searchKeyword, setSearchKeyword] = useState(q);
  const type = urlParam.get("type");
  const params = { page, q };
  const pickParams = { type, page, q };

  const param = (() => {
    const p = new URLSearchParams();
    page && p.append("page", page);
    board && p.append("cate", board);
    q && p.append("q", q);
    return p.toString();
  })();

  const { data: pageInfo } = useQuery(
    ["boardListFestival", board, params, pickParams],
    () =>
      api
        .get(`/board/list/${board}`, {
          params: type === "pick" ? pickParams : params,
        })
        .then((res) => res.data),
    {
      enabled: !!isLogin && (token.user_type === "student" || token.user_type === "doctor"),
    },
  );

  const search = (e) => {
    e.preventDefault();
    const param = new URLSearchParams();
    param.append("page", 1);
    searchKeyword && param.append("q", searchKeyword);
    navigate(`/festival?${param}`);
  };

  const onPageClick = (data) => {
    const selected = data.selected + 1;
    const param = new URLSearchParams();
    const p = new URLSearchParams(location.search);
    const type = p.get("type");
    type && param.append("type", type);
    param.append("page", selected);
    q && param.append("q", q);
    navigate(`/festival?${param}`);
  };

  return (
    <section className="min-h-[100vh] relative">
      <Header current="festival" />
      <article className="pt-10 pb-64">
        <div className="wrapper">
          <section className="magazine__home" style={{ marginTop: "54" }}>
            <div className="inner">
              <article className="section__tit__box">
                <div className="tit-box">
                  <span className="tit-txt">전치제 X 모어덴</span>
                </div>
              </article>
              <div className="list__wrap">
                <ul>
                  <li>
                    <article className="item__festival__template1">
                      <a href="#none" className="box-wrap">
                        <div className="img-box">
                          <img
                            src={getStaticImgUrl("festival/2024전치제PC.png")}
                            alt="이미지"
                          />
                        </div>
                        <div className="info-box">
                          <div className="subject-box">ABOUT</div>
                          <div className="con-box">
                            <span>■ 행사명 : 전치제</span>
                            <br />
                            전치제는 전국 치과대학/치의학전문대학원 연합 축제의 약자입니다. 전치제는
                            전국에 펼쳐져 있는 11개의 모든 치과대학/치의학전문대학원 학우들이
                            한자리에 모이는 유일무이한 축제로서 재학생들이 자신들의 재능과 끼를
                            보여주는 축제 한마당입니다. 매년 5월 중의 금요일 하루 동안 시행되며 각
                            학교가 순차적으로 돌아가며 개최합니다.
                            <br />
                            <br />
                            <span>■ 행사 개요</span>
                            <br />
                            전치제는 전국 11개의 치과대학/치의학전문대학원 학생과 교수들이 직접
                            대면할 수 있는 유일한 행사이자, 매년 단 한번 뿐인 행사입니다. 다양한
                            행사를 통해 정보를 확인하고, 경험하고, 체험하면서 치과대학(원)생들 간
                            화합을 다지는 치의학계열 종사자라면 누구나 아는 전국적인 규모의
                            행사입니다.
                          </div>
                          <div className="label-box">주최: 전국 치과대학(원) 학생연합(KDA)</div>
                        </div>
                      </a>
                    </article>
                  </li>
                </ul>
              </div>
              <div className="festival_menu_parent">
                <div className="festival_menu">
                  <button className={menu === 0 ? "menu_selected" : ""} onClick={() => setMenu(0)}>
                    전치제 참가 학교
                  </button>
                </div>
                <div className="festival_menu">
                  <button
                    className={menu === 1 ? "menu_selected" : ""}
                    onClick={(e) => {
                      if (!isLogin) {
                        e.preventDefault();
                        alert("회원가입이 필요한 페이지 입니다");
                        return;
                      }
                      setMenu(1);
                    }}
                  >
                    전치제 게시판
                  </button>
                </div>
              </div>
              <article className="section__tit__box mt-[100px]">
                <div className="tit-box">
                  <span className="tit-txt">{title[menu]}</span>
                </div>
              </article>
              <div>
                {menu === 0 && (
                  <div className="festival_content_container">
                    {schools
                      .sort((a, b) => {
                        if (a.name > b.name) {
                          return 1;
                        } else {
                          return -1;
                        }
                      })
                      .map((item, index) => (
                        <SchoolList key={index} school={item} />
                      ))}
                  </div>
                )}
                {menu === 1 && (
                  <section className="magazine__list">
                    <article className="paging">
                      <Link to={`/post/write/${board}`} className="write-btn">
                        <i className="ic ic-write"></i>
                        <span className="txt">글쓰기</span>
                      </Link>
                    </article>
                    {pageInfo && (
                      <BoardListUI
                        pageInfo={pageInfo}
                        board={board}
                        param={param}
                        onPageClick={onPageClick}
                        scroll={boardScroll}
                      />
                    )}

                    <article className="list__search__form" style={{ marginTop: "47px" }}>
                      <form className="search__form__box">
                        <button
                          className="search-btn"
                          onClick={(e) => search(e)}
                          style={{
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                          }}
                        ></button>
                        <input
                          type="search"
                          placeholder="검색어를 입력해주세요."
                          onChange={(e) => setSearchKeyword(e.target.value)}
                        />
                      </form>
                      <button className="search-com-btn" onClick={(e) => search(e)}>
                        <span className="txt">검색하기</span>
                      </button>
                    </article>
                  </section>
                )}
              </div>
            </div>
          </section>
        </div>
      </article>
      <Footer />
    </section>
  );
};

export default Festival;
