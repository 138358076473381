import { listDttm, api } from "utils/util";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nickname from "../user/nickname";
import { useQuery } from "react-query";
import { getResizedImgUrl, getStaticImgUrl } from "utils/imageUtil";
import { SimpleLogo } from "component/Logo";

const ForumListTable = ({ articles, bid }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const { data: forumNotice, isLoading: forumNoticeLoading } = useQuery("forumNotice", () =>
    api.get("/forum?informType=notice").then((res) => res.data.forum),
  );

  return (
    <div className="list">
      <div className="list-head">
        <div className="w10p">이미지</div>
        <div className="w15p left">토픽</div>
        <div className="w45p left">제목</div>
        <div className="w15p left">글쓴이</div>
        <div className="w5p">작성일</div>
        <div className="w5p">조회</div>
        <div className="w5p">추천</div>
      </div>
      {!location.pathname.startsWith("/mypage") &&
        !location.pathname.startsWith("/profile") &&
        !forumNoticeLoading &&
        forumNotice &&
        forumNotice.map((noti, idx) => {
          return (
            <div
              className="notice"
              key={idx}
              onClick={() => navigate(`/forum/article/${noti.bid}`)}
            >
              <div className="w10p">
                <div className="img-box">
                  <div className="w-[67px] h-[67px] text-primary-800 bg-white flex flex-col justify-center items-center border-[1px] border-solid border-primary-800 rounded">
                    <div className="text-xs font-normal">공지</div>
                    <SimpleLogo />
                  </div>
                </div>
              </div>
              <div className="w15p left">공지사항</div>
              <div className="w45p left">
                <i className="ii ii-flag-outline text-primary-800 mr-1"></i>
                {noti.title}
              </div>
              <div className="w15p relative left">
                <SimpleLogo className="absolute -left-1.5" width={11} />
                모어덴
              </div>
            </div>
          );
        })}

      {articles && articles.length > 0 ? (
        articles.map((item) => (
          <Link
            className="width-max inline-block"
            key={item.bid}
            to={`/forum/article/${item.bid}?${urlParams}`}
          >
            <div
              className={
                item.bid === bid
                  ? "content current"
                  : item.report_count >= 10
                  ? "content reported"
                  : "content"
              }
            >
              <div className="w10p">
                <div className="img-box">
                  <img
                    src={
                      item.thumbnail
                        ? getResizedImgUrl(item.thumbnail, 120, 120)
                        : getStaticImgUrl(`category/forum/cate_${item.cate_cd}.jpg`)
                    }
                    alt="이미지"
                  />
                </div>
              </div>
              <div className="w15p left">
                <span>{item.cate_name}</span>
              </div>
              <div className="w45p relative left">
                {item.reported ? (
                  "다수의 신고로 블라인드 처리된 게시글입니다."
                ) : (
                  <>
                    {item.admin_selected_at !== null && <i className="ic ic-admin-selected" />}
                    {item.report_count >= 10 && <i className="ic ic-reported" />}
                    <div className="max-w-[70%] truncate mr-1">{item.title}</div>[{item.comment}]
                    {item.has_img && <i className="ic ic-albums"></i>}
                  </>
                )}
              </div>
              <div className="w15p relative left">
                <Nickname
                  uid={item.writerUid}
                  unick={item.unick}
                  level={item.level}
                  isOwner={item.is_owner}
                />
              </div>
              <div className="w5p">
                <span>{listDttm(item.reg_dttm)}</span>
              </div>
              <div className="w5p">
                <span>{item.view}</span>
              </div>
              <div className="w5p">
                <span className="accent">{item.like}</span>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <article className="no-data">
          <img
            src={`${import.meta.env.VITE_APP_PUBLIC_URL}img/img_no_history_data.svg`}
            alt="이미지"
          />
          <div className="txt">작성된 글이 없습니다.</div>
        </article>
      )}
    </div>
  );
};

export default ForumListTable;
