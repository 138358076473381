import axios from "axios";
import { useState } from "react";
import { api } from "../../utils/util";

import Loading from "../Loading";

const FileUpload = ({ useFiles }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useFiles;

  const attachFile = async (e) => {
    if (files.length + e.target.files.length > 1) {
      alert("파일은 총 1개까지 업로드 가능합니다.");
      return;
    }

    const attachFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 20 * 1024 * 1024) {
        alert("파일은 각각 최대 20MB까지 업로드 가능합니다.");
        return;
      }
      attachFiles.push(e.target.files[i]);
    }
    setLoading(true);
    try {
      const uploadedFileList = await Promise.all(
        attachFiles.map(async (file) => {
          const res = await api.post("/util/presigned", { filename: file.name });

          const signedUrl = res.data;
          const url = new URL(signedUrl);
          url.search = "";
          await axios.put(signedUrl, file);
          return url.toString();
        }),
      );
      setLoading(false);
      setFiles([...files, ...uploadedFileList]);
    } catch (err) {
      console.log(err);
      alert("파일 업로드에 실패했습니다.");
    }
  };

  const deleteFile = (file) => {
    setFiles((cur) => [...cur].filter((item) => item !== file));
  };

  return (
    <section className="file-upload">
      {loading && <Loading />}
      <div className="file-upload__box">
        <div className="file-upload__con">
          {!files || files.length < 1 ? (
            <div className="file"></div>
          ) : (
            <div className="file">
              {files.map((item, idx) => (
                <div className="flex justify-between items-center width-max" key={idx}>
                  <div className="left">
                    <div className="name" style={{ padding: 0, margin: 0 }}>
                      {item.split("/").slice(-1)[0]}
                    </div>
                  </div>
                  <div onClick={() => deleteFile(item)}>
                    <i className="ii ii-remove ii-weight-600"></i>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="upload">+ 파일추가</div>
          <input type="file" onChange={attachFile} value="" />
        </div>
        <div className="desc">정확한 정보 확인을 위해 사업자등록증을 첨부해주세요. (필수)</div>
      </div>
    </section>
  );
};

export default FileUpload;
