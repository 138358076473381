import React from "react";
import { getStaticImgUrl } from "utils/imageUtil";

const TongtestSchedule = () => {
  return (
    <div>
      <article style={{ marginTop: 100 }} className="section__tit__box">
        <div className="tit-box">
          <span className="tit-txt">시험 일정</span>
        </div>
      </article>
      <img
        className="tongtest_test"
        src={getStaticImgUrl("tongtest/tong_add_pc.png")}
        alt="이미지"
      />
      <img
        className="tongtest_content"
        src={getStaticImgUrl("tongtest/tongtest_content.png")}
        alt="이미지"
      />
      <br />
      <br />
      <br />
      <article style={{ paddingLeft: 390 }} className="section__tit__box" alt="이미지"></article>
      <a
        className="theater-publisher"
        href="https://kda-exam.or.kr/home/login/login.do"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="tongtest_content mt0"
          src={getStaticImgUrl("tongtest/tongtest_banner.png")}
          alt="이미지"
        />
      </a>
    </div>
  );
};

export default TongtestSchedule;
